import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { useState } from "react";
function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""
        } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}
const Faq = () => {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <div className="max-w-[1150px] mx-auto w-10/12">
      <h2 className="title !text-black sm:text-4xl text-3xl  sm:text-left text-center sm:mb-14 mb-8">
        Frequently Asked Questions
      </h2>
      <div className="w-full mx-auto flex flex-col gap-y-3 ">
        <Accordion
          open={open === 1}
          icon={<Icon id={1} open={open} />}
          className="border-black border rounded-lg overflow-hidden"
        >
          <AccordionHeader
            onClick={() => handleOpen(1)}
            className="font-archivo bg-gray-50 px-3  "
          >
            What is SAT MMT?
          </AccordionHeader>
          <AccordionBody className="bg-gray-50 px-3 border-t border-t-black">
            This website is designed to assist students in their SAT preparation online.
          </AccordionBody>
        </Accordion>
        <Accordion
          open={open === 2}
          icon={<Icon id={2} open={open} />}
          className="border-black border rounded-lg overflow-hidden"
        >
          <AccordionHeader
            onClick={() => handleOpen(2)}
            className="font-archivo bg-gray-50 px-3  "
          >
            Why SAT MMT?
          </AccordionHeader>
          <AccordionBody className="bg-gray-50 px-3 border-t border-t-black">
            Because students can go paperless with SAT testing, accessing and reviewing problems anytime, anywhere.
          </AccordionBody>
        </Accordion>
        <Accordion
          open={open === 3}
          icon={<Icon id={3} open={open} />}
          className="border-black border rounded-lg overflow-hidden"
        >
          <AccordionHeader
            onClick={() => handleOpen(3)}
            className="font-archivo bg-gray-50 px-3  "
          >
            What is the benefit?
          </AccordionHeader>
          <AccordionBody className="bg-gray-50 px-3 border-t border-t-black">
            We're crafting an AI algorithm to provide clear explanations for missed answers and offer precise study guidelines for students.
          </AccordionBody>
        </Accordion>
        <Accordion
          open={open === 4}
          icon={<Icon id={4} open={open} />}
          className="border-black border rounded-lg overflow-hidden"
        >
          <AccordionHeader
            onClick={() => handleOpen(4)}
            className="font-archivo bg-gray-50 px-3  "
          >
            What are effective ways for students to study?
          </AccordionHeader>
          <AccordionBody className="bg-gray-50 px-3 border-t border-t-black">
            We offer over 10,000 questions covering both English and Math areas, all generated by AI. This extensive pool provides ample opportunities for practicing and mastering problem-solving skills.
          </AccordionBody>
        </Accordion>
        <Accordion
          open={open === 5}
          icon={<Icon id={5} open={open} />}
          className="border-black border rounded-lg overflow-hidden"
        >
          <AccordionHeader
            onClick={() => handleOpen(5)}
            className="font-archivo bg-gray-50 px-3  "
          >
            How MMTPrep can help?
          </AccordionHeader>
          <AccordionBody className="bg-gray-50 px-3 border-t border-t-black">
            We have a team of dedicated math teachers ready to support students in their SAT studies. For more information, reach out to the MMT team.
          </AccordionBody>
        </Accordion>
      </div>
    </div>
  );
};

export default Faq;
