import { Link } from "react-router-dom";
import Select from 'react-select'
import { useEffect, useState, useContext } from "react";
import { BASELINE } from "../../util";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";

const data = [
  {
    topic: "Algebra",
    subtopics: [
      { name: "Simple Algebra", id: "A1" },
      { name: "Literal Equation", id: "A2" },
      { name: "Complex Fraction & Complex Algebra", id: "A3" }
    ]
  }
];

const FreeTopic = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [sections, setSections] = useState(["Math"]);

  const [error, setError] = useState("");
  const [modules, setModules] = useState([{ mmtmode: true, mmtdifficultys: [], topics: [], selectedSection: "", selectedSubject: "", subjects: [], selectedTopics: [], difficulty: "", numberOfQuestions: 0, selectedTopicTotal: 0 }]);

  const copyModule = (index) => {
    const moduleToCopy = { ...modules[index] };
    setModules([...modules, moduleToCopy]);
  };

  const updateTotalQuestions = (idx, topics, difficulty) => {
    let total = 0;
    if (modules[idx].mmtmode) {
      if (difficulty) {
        total = modules[idx].mmtdifficultys.find(d => d.difficultys === difficulty)?.total || 0;
      }
    } else {
      if (Array.isArray(topics.difficulties) && difficulty) {  // Check if topics is an array
        const difficultyItem = topics.difficulties.find(item => item.difficulty === difficulty);
        total = difficultyItem ? difficultyItem.total : 0;
      }
    }
    updateModule(idx, 'selectedTopicTotal', total);
  };

  const removeModule = (index) => {
    setModules(modules.filter((_, idx) => idx !== index));
  };

  const addModule = () => {
    setModules([...modules, { mmtmode: true, mmtdifficultys: [], topics: [], selectedSection: "", selectedSubject: "", selectedTopics: [], difficulty: "", numberOfQuestions: 0, selectedTopicTotal: 0 }]);
  };

  function updateModule(idx, key, value) {
    setModules(prevModules => {
      const newModules = [...prevModules];
      newModules[idx] = { ...newModules[idx], [key]: value || [] }; // Ensure value is never null or undefined
      return newModules;
    });
  }

  async function handleStartQuiz() {
    let hasError = false;
    for (let i = 0; i < modules.length; i++) {
      const module = modules[i];

      if (module.selectedSection.length < 1) {
        toast.error("Subject Not Section");
        hasError = true;
      }

      if (module.selectedSubject.length < 1) {
        toast.error("Subject Not Selected");
        hasError = true;
      }

      if (module.selectedTopics.length < 1) {
        toast.error("Topic Not Selected");
        hasError = true;
      }

      if (module.numberOfQuestions < 2 || module.numberOfQuestions > module.selectedTopicTotal) {
        toast.error("Please choose more than 2 or smaller than total questions");
        hasError = true;
      }
    }

    if (hasError) {
      return;
    }
    localStorage.clear();

    let modulesData = modules.map(module => ({
      test: "SAT",
      mmtmode: module.mmtmode,
      subject: module.selectedSubject,
      topics: module.selectedTopics.value,
      difficulty: module.difficulty,
      questionLimit: module.numberOfQuestions,
      selectedSection: module.selectedSection,
      free: true
    }));

    navigate("/sat/quiz", {
      state: {
        modules: modulesData
      },
    });

  }


  function groupBy(array, key) {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  }

  async function getMMTtotal(idx, topic) {
    let res = await axios.get(BASELINE + "sat/mmt/difficultys", { params: { topic: topic.value } });

    // Use updateModule function to update mmtdifficultys
    updateModule(idx, 'mmtdifficultys', res.data);
  }

  async function getTopics(subject, idx) {
    try {
      let mappedValues = [];

      if (modules[idx].mmtmode) {
        let selectedSubject = data.find((item) => item.topic === subject);
        mappedValues = selectedSubject ? selectedSubject.subtopics.map((subtopic) => {
          return {
            value: subtopic.id,
            label: subtopic.name
          };
        }) : [];
      } else {
        let res = await axios.get(BASELINE + "sat/topic", { params: { subject } });
        let groupedData = groupBy(res.data, 'skill_descs');
        mappedValues = Object.entries(groupedData).map(([skill_descs, data]) => {
          let difficultiesWithTotals = data.map(item => ({
            difficulty: item.difficultys,
            total: item.total
          }));
          return {
            value: skill_descs,
            label: skill_descs,
            difficulties: difficultiesWithTotals
          };
        });
      }

      updateModule(idx, 'topics', mappedValues);
      updateTotalQuestions(idx, mappedValues, modules[idx].difficulty); // Ensure topics is an array
    } catch (err) {
      setError(err.message);
      updateModule(idx, 'topics', []);
    }
  }

  async function mmtenable(idx) {
    let mappedValues;
    mappedValues = data.flatMap((subject) => {
      return {
        value: subject.topic,
        label: subject.topic
      }
    });

    updateModule(idx, 'subjects', mappedValues);
  }

  async function getSubjects(section, idx) {
    try {
      let mappedValues;

      let res = await axios.get(BASELINE + "sat/subject", { params: { section } });
      mappedValues = res.data.map((subject) => {
        return {
          value: subject.primary_class_cd,
          label: subject.primary_class_cd_descs
        }
      });

      updateModule(idx, 'subjects', mappedValues);
    } catch (err) {
      setError(err.message);
      updateModule(idx, 'subjects', []); // Set subjects to an empty array on error
    }
  }

  return (
    <section className="wrapper mt-[120px]">
      <div className="w-[90%] max-w-[1000px] 2xl:max-w-[1300px] flex justify-start items-center flex-col gap-5 pt-8 px-5 bg-white rounded-2xl">
        <h2 className="text-black text-[28px] sm:text-[40px] md:text-[48px] font-bold text-center md:text-left">
          Design Your Free Math Quiz Today!
        </h2>
        {modules.map((module, idx) => (
          <div key={idx} className="mb-6 p-2 rounded-xl shadow-xl md:w-[98%] w-[98%]">
            <div className="flex flex-col md:flex-row md:flex-wrap justify-center md:grid md:grid-cols-3 gap-2">

              <div className="flex flex-col gap-1 p-2 w-full lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Section</h4>
                <select
                  className="outline-none border-[1px] border-gray-300 p-1 rounded-md focus-within:border-blue-500 focus-within:border-[2px]"
                  value={module.selectedSection}
                  onChange={(e) => {
                    updateModule(idx, 'selectedSection', e.target.value);
                    mmtenable(idx);
                  }}
                >
                  <option>Select a section</option>
                  {sections.map((section, idx) => {
                    return <option key={'section-' + idx} value={section}>{section}</option>
                  })}
                </select>
              </div>

              <div className="flex flex-col gap-1 p-2 w-full lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Select Subject</h4>
                <select
                  className="outline-none border-[1px] border-gray-300 p-1 rounded-md focus-within:border-blue-500 focus-within:border-[2px]"
                  value={module.selectedSubject}
                  onChange={(e) => {
                    updateModule(idx, 'selectedSubject', e.target.value);
                    getTopics(e.target.value, idx);
                  }}
                >
                  <option>Select a subject</option>
                  {module.subjects && module.subjects.map((subject, idx) => {
                    return <option key={'subject-' + idx} value={subject.value}>{subject.label}</option>
                  })}
                </select>
              </div>

              <div className="flex flex-col p-2 w-full lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Select Topics</h4>
                <div>
                  <Select
                    value={module.selectedTopics}
                    onChange={(selectedTopics) => {
                      updateModule(idx, 'selectedTopics', selectedTopics || []); // Ensure selectedTopics is never null or undefined
                      getMMTtotal(idx, selectedTopics);
                      updateTotalQuestions(idx, selectedTopics || [], module.difficulty); // Ensure topics is an array
                    }}
                    name="topics"
                    options={module.topics}
                    className="basic-multi-select lg:min-w-[300px] lg:max-w-[300px]"
                    classNamePrefix="select"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-1 p-2 w-full lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Select Difficulty</h4>
                <select
                  onChange={(e) => {
                    updateModule(idx, 'difficulty', e.target.value);
                    updateTotalQuestions(idx, module.selectedTopics || [], e.target.value); // Ensure topics is an array
                  }}
                  value={module.difficulty}
                  className="outline-none border-[1px] border-gray-300 p-1 rounded-md focus-within:border-blue-500 focus-within:border-[2px]"
                  aria-label="Select Difficulty"
                  disabled={!module.selectedSubject || !module.selectedTopics || module.selectedTopics.length === 0}
                >
                  <option value="">Select one</option>
                  <option value="E">Easy</option>
                  <option value="M">Medium</option>
                  <option value="H">Hard</option>
                </select>
              </div>

              <div className="flex flex-col gap-1 p-2 w-full lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Select Number Of Questions</h4>
                <div>
                  <input
                    className="border-[1px] border-gray-300 outline-none p-1 rounded-md focus-within:border-blue-500 focus-within:border-[2px]"
                    type="number"
                    value={module.numberOfQuestions}
                    onChange={(e) => updateModule(idx, 'numberOfQuestions', e.target.value)}
                    min={2}
                  />
                  {module.mmtmode ? (
                    module.difficulty.length > 0 && (
                      <>
                        / {module.mmtdifficultys.find(d => d.difficultys === module.difficulty)?.total}
                      </>
                    )
                  ) : (
                    module.selectedTopics && module.difficulty && (
                      <> / {module.selectedTopics.difficulties.find(d => d.difficulty === module.difficulty).total} </>
                    )
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-1 p-2 w-full lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Copy Module</h4>
                <button
                  className="bg-slate-900 hover:bg-slate-800 text-white px-2 py-1 rounded-md"
                  onClick={() => copyModule(idx)}
                >
                  Copy
                </button>
              </div>

              {idx !== 0 && (
                <div className="flex flex-col gap-1 p-2 w-full lg:min-w-[300px] lg:max-w-[300px]">
                  <button
                    className="hover:bg-red-500 hover:text-white font-semibold text-black px-2 py-1 rounded-md"
                    onClick={() => removeModule(idx)}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}

        <div className="flex flex-col gap-1 p-2 lg:min-w-[300px] lg:max-w-[300px]">
          <h4 className="font-semibold">Add Module</h4>
          <button onClick={addModule} className="bg-slate-800 px-4 py-2 outline-none rounded-md text-white hover:bg-gray-600">+</button>
        </div>
        <div className="w-full flex flex-row justify-center py-4">
          <motion.button
            whileTap={{ scale: 0.97 }}
            onClick={() => handleStartQuiz()}
            className="bg-gray-500 px-4 py-2 outline-none rounded-md text-white hover:bg-gray-600 w-full glow-outline"
          >
            Start Quiz
          </motion.button>
        </div>


      </div>
    </section>
  );
};

export default FreeTopic;
