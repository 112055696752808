import React, { useState, useEffect, createContext } from 'react';
import { BASELINE } from '../util';
import axios from 'axios';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [err, setErr] = useState('');

  useEffect(() => {
    getUserLoginState();
  }, []);

  const getUserLoginState = async () => {
    let u_id = localStorage.getItem("quiz-loggedinuserid");
    if (u_id) {
      try {
        const res = await axios.post(BASELINE + 'user/info', { u_id });
        if (res.status === 200) {
          let fetchedUser = res.data;
          // Check if the user's premium end date has passed
          const currentDate = new Date();
          const endPremiumDate = new Date(fetchedUser.end_premium_date);
          if (fetchedUser.premium && endPremiumDate < currentDate) {
            // Set premium to false if the date has passed
            fetchedUser.premium = 0;
            setUser(fetchedUser);
            // Update server with new premium status
            await updatePremiumStatus(u_id, 0);
          } else {
            setUser(fetchedUser);
          }
        } else {
          setErr('Error connecting to server');
        }
      } catch (error) {
        setErr('Error fetching user info');
        console.error(error);
      }
    }
  };

  const updatePremiumStatus = async (u_id, newPremiumStatus) => {
    try {
      await axios.post(BASELINE + 'user/update-premium-status', {
        u_id,
        premium: newPremiumStatus,
      });
    } catch (error) {
      console.error('Error updating premium status:', error);
    }
  };

  const logoutUser = () => {
    localStorage.setItem("quiz-loggedinuserid", null);
    setUser(null);
  };

  const savelogin = (value) => {
    localStorage.setItem("quiz-loggedinuserid", value.u_id);
    setUser(value);
  };

  return (
    <UserContext.Provider value={{ user, logoutUser, savelogin }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
