import React, { useState, useEffect } from "react";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { ImSpellCheck } from "react-icons/im";
import { motion } from "framer-motion";
import axios from "axios";
import { BASELINE } from "../../util/index";

const DevQuestion = ({ q_id, id, handleAnswerValueChange, handleCrossOutOptions, handleSelectOption, handleBookMark, currentQuestion }) => {
    const [showCrossOutMenu, setShowCrossOutMenu] = useState(false);
    const [crossedOutOptions, setCrossedOutOptions] = useState([]);

    const [isEditing, setIsEditing] = useState(false);
    const [editedStimulus, setEditedStimulus] = useState(currentQuestion.stimulus);
    const [editedStem, setEditedStem] = useState(currentQuestion.stem);
    const [editprompt, seteditprompt] = useState(currentQuestion.prompt);

    const [options, setOptions] = useState(currentQuestion.options || []);

    const handleEdit = () => {
        setIsEditing(!isEditing);
    };

    async function handleSave() {
        currentQuestion.stimulus = editedStimulus;
        currentQuestion.stem = editedStem;
        currentQuestion.prompt = editprompt;

        setIsEditing(false);

        var data = {
            stimulus: currentQuestion.stimulus,
            stem: currentQuestion.stem,
            a: currentQuestion.a,
            b: currentQuestion.b,
            c: currentQuestion.c,
            d: currentQuestion.d,
            questionId: q_id,
            prompt: currentQuestion.prompt
        }
        try {
            let res = await axios.put(BASELINE + "questions/psat10update", [data]);
        //    console.log(res.data);
        } catch (err) {
        //    console.log(err);
        }
    };

    async function handleOptionChange(index, newValue) {
        if (index === 0) {
            currentQuestion.a = newValue;
        } else if (index === 1) {
            currentQuestion.b = newValue;
        } else if (index === 2) {
            currentQuestion.c = newValue;
        } else if (index === 3) {
            currentQuestion.d = newValue;
        }

        setOptions(options.map((option, i) => i === index ? newValue : option));

        var data = {
            stimulus: currentQuestion.stimulus,
            stem: currentQuestion.stem,
            a: currentQuestion.a,
            b: currentQuestion.b,
            c: currentQuestion.c,
            d: currentQuestion.d,
            questionId: q_id,
            prompt: currentQuestion.prompt
        }

        try {
            let res = await axios.put(BASELINE + "questions/psat10update", [data]);
        } catch (err) {
        //    console.log(err);
        }
    };

    return (
        <>
            <div className="min-w-[30rem] max-w-[30rem] z-[10]">
                {/* header starts */}
                <div className="flex flex-row justify-between bg-gray-100">
                    <div className="flex flex-row gap-2">
                        <div className=" flex flex-row items-center">
                            <p className="py-2 px-3 bg-black text-white">{id}</p>
                        </div>
                        <div className="flex flex-row items-center gap-1">
                            <motion.div whileTap={{ scale: 0.97 }} onClick={() => handleBookMark(currentQuestion.item_id)} className="m-1 cursor-pointer">

                                {currentQuestion.bookMarked ? <BsBookmarkFill color="red" size="1.2rem" /> : <BsBookmark size="1.2rem" />}
                            </motion.div>
                            <div>
                                <p>Mark For Review</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center">
                        <motion.div whileTap={{ scale: 0.98 }} className={`p-[5px] border-black border-[1px] rounded-md cursor-pointer ${showCrossOutMenu && "bg-blue-700 text-white"}`} onClick={() => setShowCrossOutMenu(pre => !pre)}>
                            <ImSpellCheck />
                        </motion.div>
                    </div>
                </div>
                {/* header ends */}
                {/* question starts */}
                <div >
                    <div className="flex items-start space-x-4">
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: currentQuestion.stimulus }} className="ml-[20px]" />
                            <div dangerouslySetInnerHTML={{ __html: currentQuestion.prompt }} className="ml-[20px]" />

                            <div dangerouslySetInnerHTML={{ __html: currentQuestion.stem }} className="mt-[20px]" />
                        </div>
                        <button onClick={handleEdit} className="mt-[10px] bg-gray-700 p-2 text-white rounded">Edit</button>
                    </div>
                </div>
                {/* question ends */}
                {/* answer options start */}
                <div className="my-2">
                    {currentQuestion.style === "Multiple Choice" ?
                        <>
                            <Option handleOptionChange={handleOptionChange} isEditing={isEditing} handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={0} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.a} />
                            <Option handleOptionChange={handleOptionChange} isEditing={isEditing} handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={1} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.b} />
                            <Option handleOptionChange={handleOptionChange} isEditing={isEditing} handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={2} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.c} />
                            <Option handleOptionChange={handleOptionChange} isEditing={isEditing} handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={3} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.d} />
                        </> : <input className="w-[90%] border-[1px] py-1 px-2 border-gray-300 outline-none rounded-md" type="text" value={currentQuestion.selectedOption || ""} onChange={(e) => handleAnswerValueChange(currentQuestion.item_id, e.target.value)} />
                    }

                </div>

                <div >
                    <div className="flex items-start space-x-4">
                        <div className="flex items-start space-x-4">
                            <h3>Answer Description: </h3>
                            <div dangerouslySetInnerHTML={{ __html: currentQuestion.rationale }} />
                        </div>
                    </div>
                </div>
                {/* answer options ends */}
            </div>

            {isEditing ? (
                <div className="ml-auto fixed right-0 top-[100px] bg-white z-[1000]">
                    <h2>Edited Stimulus</h2>
                    <textarea value={editedStimulus} rows={2} cols={40} onChange={(e) => setEditedStimulus(e.target.value)} className="ml-[20px] border-1 border-black" />
                    <h2>Edited Stem</h2>
                    <textarea value={editedStem} rows={2} cols={40} onChange={(e) => setEditedStem(e.target.value)} className="mt-[20px] border-1 border-black" />
                    <h2>Edited prompt</h2>
                    <textarea value={editprompt} rows={14} cols={40} onChange={(e) => seteditprompt(e.target.value)} className="mt-[20px] border-1 border-black" />
                    <button className="mt-[10px] bg-gray-700 p-2 text-white rounded" onClick={handleSave}>Save</button>
                </div>
            ) : null}
        </>
    )
}
export default DevQuestion;


const Option = ({ handleOptionChange, handleCrossOutOptions, currentQuestion, idx, handleSelectOption, showCrossOutMenu, crossedOutOptions, data, isEditing }) => {
    const [editedData, setEditedData] = useState(data);

    const handleChange = (e) => {
        setEditedData(e.target.value);
    };

    const handleSave = () => {
        handleOptionChange(idx, editedData);
    };

    return (
        <div className="flex flex-row gap-1">
            <motion.div whileTap={{ scale: 0.98 }} className={`flex flex-row w-full ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-[2px]" : "border-[1px]"} rounded-md ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-blue-700 bg-blue-100" : "border-black"} gap-2 p-1 m-3 cursor-pointer relative ${currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) && "before:absolute before:bg-black before:w-[103%] before:h-[2px] before:top-[50%] before:-left-[6px]"}`} onClick={() => handleSelectOption(currentQuestion.item_id, getOptionLetter(idx).toLowerCase())}>
                <div className="flex flex-row items-center">
                    <p className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
                        {getOptionLetter(idx)}
                    </p>
                </div>
                <div className="flex flex-row items-center p-[2px]" dangerouslySetInnerHTML={{ __html: data }}>
                </div>
            </motion.div>
            {showCrossOutMenu &&
                <motion.div className="flex flex-row items-center" whileTap={{ scale: 0.97 }}>
                    <div className="relative">
                        {currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) ?
                            (<p className="cursor-pointer underline" onClick={() => handleCrossOutOptions(currentQuestion.item_id, getOptionLetter(idx).toLowerCase())}>Undo</p>) :

                            <>
                                <p onClick={() => handleCrossOutOptions(currentQuestion.item_id, getOptionLetter(idx).toLowerCase())} className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full cursor-pointer">
                                    {getOptionLetter(idx)}
                                </p>
                                <div className="absolute w-6 h-[1.5px] bg-black top-3 cursor-pointer" onClick={() => handleCrossOutOptions(currentQuestion.item_id, getOptionLetter(idx).toLowerCase())}>
                                </div>
                            </>
                        }
                    </div>
                </motion.div>
            }

            {isEditing &&
                <div>
                    <textarea rows={5} value={editedData} onChange={handleChange} className="border-1 border-black" />
                    <button className="mt-[10px] bg-gray-700 p-2 text-white rounded" onClick={handleSave}>Save</button>
                </div>
            }
        </div>
    )
}

function getOptionLetter(idx) {
    switch (idx) {
        case 0:
            return 'A'
            break;
        case 1:
            return 'B'
            break;
        case 2:
            return 'C'
            break;
        case 3:
            return 'D'
            break;
        default:
            return "A"
    }
}