import { Link } from "react-router-dom";
import Select from 'react-select'
import { useEffect, useState, useContext } from "react";
import { BASELINE } from "../../util";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";

const Topic10 = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [sections, setSections] = useState(["English", "Math"]);

  const [numberOfQuestions, setNumberOfQuestions] = useState(10);
  const [error, setError] = useState("");
  const [modules, setModules] = useState([{ mmtdifficultys: [], topics: [], selectedSection: "", selectedSubject: "", subjects: [], selectedTopics: [], difficulty: "", numberOfQuestions: 10 }]);

  const removeModule = (index) => {
    setModules(modules.filter((_, idx) => idx !== index));
  };

  const addModule = () => {
    setModules([...modules, { mmtdifficultys: [], topics: [], selectedSection: "", selectedSubject: "", selectedTopics: [], difficulty: "", numberOfQuestions: 10 }]);
  };

  function updateModule(idx, key, value) {
    setModules(prevModules => {
      const newModules = [...prevModules];
      newModules[idx] = { ...newModules[idx], [key]: value || [] }; // Ensure value is never null or undefined
      return newModules;
    });
  }

  async function handleStartQuiz() {
    if (!user) {
      toast.error("Please login before starting the test");
      navigate("/login"); // Redirect user to the /login page
      return;
    }

    if (user.u_id) {
      for (let i = 0; i < modules.length; i++) {
        const module = modules[i];

        if (module.selectedSection.length < 1) {
          toast.error("Subject Not Section");
          return;
        }

        if (module.selectedSubject.length < 1) {
          toast.error("Subject Not Selected");
          return;
        }

        if (module.selectedTopics.length < 1) {
          toast.error("Topic Not Selected");
          return;
        }

        if (module.numberOfQuestions < 2 || module.numberOfQuestions > module.selectedTopicTotal) {
          toast.error("Please choose more than 2 or smaller than total questions");
          return;
        }

        let modulesData = modules.map(module => ({
          test: "SAT10",
          subject: module.selectedSubject,
          topics: module.selectedTopics.value,
          difficulty: module.difficulty,
          questionLimit: module.numberOfQuestions,
          selectedSection: module.selectedSection
        }));

        navigate("/sat/quiz", {
          state: {
            modules: modulesData
          },
        });

      }
    } else {
      toast.error("User not logged-in");
    }
  }

  function groupBy(array, key) {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  }

  async function getTopics(subject, idx) {
    try {
      let mappedValues;

      let res = await axios.get(BASELINE + "sat/topic10", { params: { subject } });
      let groupedData = groupBy(res.data, 'skill_descs'); // Group data by skill_descs
      mappedValues = Object.entries(groupedData).map(([skill_descs, data]) => {
        let difficultiesWithTotals = data.map(item => ({
          difficulty: item.difficultys,
          total: item.total
        }));
        return {
          value: skill_descs,
          label: skill_descs,
          difficulties: difficultiesWithTotals
        }
      });


      updateModule(idx, 'topics', mappedValues);
    } catch (err) {
      setError(err.message);
      updateModule(idx, 'topics', []); // Set topics to an empty array on error
    }
  }

  async function getSubjects(section, idx) {
    try {
      let mappedValues;

      let res = await axios.get(BASELINE + "sat/subject10", { params: { section } });
      mappedValues = res.data.map((subject) => {
        return {
          value: subject.primary_class_cd,
          label: subject.primary_class_cd_descs
        }
      });

      updateModule(idx, 'subjects', mappedValues);
    } catch (err) {
      setError(err.message);
      updateModule(idx, 'subjects', []); // Set subjects to an empty array on error
    }
  }

  return (
    <section className="wrapper mt-[120px]">
      <div className="w-[90%] max-w-[1000px] 2xl:max-w-[1300px] flex justify-start items-center flex-col gap-5 pt-8 px-5 bg-white rounded-2xl">
        <h2 className="text-black text-[28px] sm:text-[40px] md:text-[48px] font-bold">
          Choose Your Assessment for SAT 10
        </h2>
        {modules.map((module, idx) => (
          <div key={idx}>
            <div key={idx} className="flex flex-row flex-wrap justify-center grid grid-cols-3 gap-2">

              <div className="flex flex-col gap-1 p-2 lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Section</h4>
                <select
                  className="outline-none border-[1px] border-gray-300 p-1 rounded-md focus-within:border-blue-500 focus-within:border-[2px]"
                  value={module.selectedSection}
                  onChange={(e) => {
                    updateModule(idx, 'selectedSection', e.target.value);
                    getSubjects(e.target.value, idx);
                  }}
                >
                  <option>Select a section</option>
                  {sections.map((section, idx) => {
                    return <option key={'section-' + idx} value={section}>{section}</option>
                  })}
                </select>
              </div>

              <div className="flex flex-col gap-1 p-2 lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Select Subject</h4>
                <select
                  className="outline-none border-[1px] border-gray-300 p-1 rounded-md focus-within:border-blue-500 focus-within:border-[2px]"
                  value={module.selectedSubject}
                  onChange={(e) => {
                    updateModule(idx, 'selectedSubject', e.target.value);
                    getTopics(e.target.value, idx);
                  }}
                >
                  <option>Select a subject</option>
                  {module.subjects && module.subjects.map((subject, idx) => {
                    return <option key={'subject-' + idx} value={subject.value}>{subject.label}</option>
                  })}
                </select>
              </div>

              <div className="flex flex-col p-2">
                <h4 className="font-semibold">Select Topics</h4>
                <div>
                  <Select
                    value={module.selectedTopics}
                    onChange={(selectedTopics) => {
                      updateModule(idx, 'selectedTopics', selectedTopics);
                    }}
                    name="topics"
                    options={module.topics}
                    className="basic-multi-select lg:min-w-[300px] lg:max-w-[300px]"
                    classNamePrefix="select"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-1 p-2 lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Select Difficulty</h4>
                <select
                  onChange={(e) => updateModule(idx, 'difficulty', e.target.value)}
                  value={module.difficulty}
                  className="outline-none border-[1px] border-gray-300 p-1 rounded-md focus-within:border-blue-500 focus-within:border-[2px]"
                  aria-label="Select Difficulty"
                  disabled={!module.selectedSubject || !module.selectedTopics || module.selectedTopics.length === 0} // Disable if no subject or topics selected
                >
                  <option value="">Select one</option>
                  <option value="E">Easy</option>
                  <option value="M">Medium</option>
                  <option value="H">Hard</option>
                </select>
              </div>

              <div className="flex flex-col gap-1 p-2 lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Select Number Of Questions</h4>
                <div>
                  <input className="border-[1px] border-gray-300 outline-none p-1 rounded-md focus-within:border-blue-500 focus-within:border-[2px]" type="number" value={module.numberOfQuestions} onChange={(e) => updateModule(idx, 'numberOfQuestions', e.target.value)} min={2} />
                  {module.mmtmode ? (
                    module.difficulty.length > 0 && (
                      <>
                        / {module.mmtdifficultys.find(d => d.difficultys === module.difficulty)?.total}
                      </>
                    )
                  ) : (
                    module.selectedTopics && module.difficulty && (
                      <> / {module.selectedTopics.difficulties.find(d => d.difficulty === module.difficulty).total} </>
                    )
                  )}
                </div>
              </div>

              {idx !== 0 && (
                <div className="flex flex-col gap-1 p-2 lg:min-w-[300px] lg:max-w-[300px]">
                  <h4 className="font-semibold">Remove</h4>
                  <button
                    className="bg-red-500 text-white px-2 py-1 rounded-md"
                    onClick={() => removeModule(idx)}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>

          </div>))}
        <div className="flex flex-col gap-1 p-2 lg:min-w-[300px] lg:max-w-[300px]">
          <h4 className="font-semibold">Add Module</h4>
          <button onClick={addModule} className="bg-gray-500 px-4 py-2 outline-none rounded-md text-white hover:bg-gray-600">+</button>
        </div>
        <div className="w-full flex flex-row justify-center py-4">
          <motion.button whileTap={{ scale: 0.97 }} onClick={() => handleStartQuiz()} className="bg-gray-500 px-4 py-2 outline-none rounded-md text-white hover:bg-gray-600">Start Quiz</motion.button>
        </div>
      </div>
    </section>
  );
};

export default Topic10;
