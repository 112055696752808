import { useState } from "react";
import { motion } from "framer-motion";
import { fadeInFromLeft } from "../../util/FadeFromLeftAnimation";
import ImageSlider from "./imageslide";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASELINE } from "../../util";
import { toast } from "react-hot-toast";
const Why = () => {
  const [email, setEmail] = useState("");

  async function handleEmailSubmit(e) {
    e.preventDefault();

    try {
      let res = await axios.post(BASELINE + "email", [email]);

      if (res.status === 200) {
        // Show success message
        toast.success(res.data.message || "Thank you for subscribing!");
        setEmail(""); // Clear the input field after submission
      }

    } catch (err) {
      // If there's an error, handle different status codes or messages
      if (err.response) {
        // If the server responded with an error status
        if (err.response.status === 400 && err.response.data === "Email is already subscribed") {
          toast.error("This email is already subscribed.");
        } else if (err.response.status === 400) {
          toast.error("Email is required!");
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      } else {
        // If the error is related to network issues or no response
        toast.error("Network error. Please check your connection.");
      }
    }
  }

  const images = [
    './ad_img_1.png',
    './ad_img_2.png',
    './ad_img_3.png',
  ];

  const images2 = [
    './test.png',
    './test2.png',
    './test3.png',
    './test4.png',
  ];

  return (
    <section className="mt-10 md:mt-10">
      <div className="max-w-[1150px] w-10/12 mx-auto flex-col justify-start items-center text-center gap-7 md:gap-16">
        <div className="w-full mb-[50px]">
          <motion.h2 className="title !text-black mb-8 sm:text-4xl text-3xl">
            WHY SAT.MMT?
          </motion.h2>
          <motion.p className="text-subGray md:max-w-[750px] w-11/12 mx-auto text-base md:text-xl text-center font-normal">
            We pride ourselves on being a dependable company, offering top-notch, precise services at an affordable rate, ensuring our customers receive nothing but excellence.
          </motion.p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 w-full gap-5 sm:mt-6 mt-10">
          <WhyBox
            delay={0.6}
            img="/why1.png"
            text="Employing AI analytics, we pinpoint weaker areas to refine our services."
          />
          <WhyBox
            delay={0.8}
            img="/why2.png"
            text="Minimizing time and effort spent on identifying mistakes. Easy comprehension and analysis of your test performance."
          />
          <WhyBox
            delay={1}
            img="/why3.png"
            text="We boast an extensive collection of over 10,000 practice problems, meticulously curated to target and strengthen weak areas effectively."
          />
        </div>
      </div>

      {/** Email Subscription Section */}
      <div className="bg-gray-100 py-10 mt-10">
        <div className="text-center mb-6">
          <h3 className="text-2xl font-bold">Stay Updated with Our Latest News!</h3>
          <p className="text-gray-600">Enter your email to receive updates and special offers.</p>
        </div>
        <div className="flex justify-center">
          <form onSubmit={handleEmailSubmit} className="flex flex-col sm:flex-row gap-3">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="p-3 rounded-md border border-gray-300 w-72 sm:w-96"
              required
            />
            <button
              type="submit"
              className="p-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>

      <div className="bg-[#090c32] mt-[80px] pt-4 pb-4 pb-[80px]">
        <div className="text-center gradient-text text-[20px] font-bold mt-[60px]">
          Comprehensive Analysis
        </div>
        <div className="text-center text-white font-bold text-[40px] ml-[10px] mr-[10px]">
          Explore Deeper Insights with <br />
          AI-powered Analytics Reports
        </div>
        <div className="flex justify-center mb-[80px]">
          <Link to="/freetrial/mathquiz" className="mt-4 p-4 bg-blue-400 rounded-2xl text-white">
            Try our Sample!
          </Link>
        </div>
        <ImageSlider images={images} />

        <div className="text-center gradient-text text-[20px] font-bold mt-[60px]">
          Close-To-Real Test Settings
        </div>
        <div className="text-center text-white font-bold text-[40px]">
          Get a Feel for the Real Test Environment!
        </div>
        <div className="flex flex-wrap items-center justify-center gap-4 mb-[80px] sm:grid sm:grid-cols-2 sm:gap-4 lg:flex lg:flex-nowrap">
          <div className="border border-white p-4 rounded-xl text-white hover:bg-white hover:text-indigo-950 cursor-pointer">
            Section Info
          </div>
          <div className="border border-white p-4 rounded-xl text-white hover:bg-white hover:text-indigo-950 cursor-pointer">
            Timer
          </div>
          <div className="border border-white p-4 rounded-xl text-white hover:bg-white hover:text-indigo-950 cursor-pointer">
            Calculator
          </div>
          <div className="border border-white p-4 rounded-xl text-white hover:bg-white hover:text-indigo-950 cursor-pointer">
            Reference
          </div>
          <div className="border border-white p-4 rounded-xl text-white hover:bg-white hover:text-indigo-950 cursor-pointer">
            Question
          </div>
          <div className="border border-white p-4 rounded-xl text-white hover:bg-white hover:text-indigo-950 cursor-pointer">
            Question Navigator
          </div>
        </div>

        <ImageSlider images={images2} intervalTime={2000} />
      </div>
    </section>
  );
};

export default Why;

const WhyBox = ({ img, text, delay }) => {
  return (
    <motion.div
      className="flex justify-start border hover:shadow-2xl duration-300 cursor-pointer items-center flex-col gap-6 bg-white rounded-lg shadow-lg border-b-[3px] border-solid border-black px-5 2xl:px-10 py-8"
    >
      <img src={img} className="object-contain w-[88px]" alt="" />
      <p className="text-base font-light text-textGray">{text}</p>
    </motion.div>
  );
};
