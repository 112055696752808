import React, { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { UserContext } from '../context/UserContext';
import axios from 'axios';
import { BASELINE, AFTER_LOGIN } from "../util/index";
import { HiMenuAlt3 } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { motion } from "framer-motion";
import { fadeInFromLeft } from "../util/FadeFromLeftAnimation";
import { FaXTwitter } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";

const Header = () => {
  const { logoutUser, user } = useContext(UserContext);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [isTestDropdownOpen, setTestDropdownOpen] = useState(false);
  const [isPracticeDropdownOpen, setPracticeDropdownOpen] = useState(false);
  const [isPracticeDropdownOpen2, setPracticeDropdownOpen2] = useState(false);
  const [foldersname, setfoldersname] = useState([]);

  const fetchFolders2 = async () => {
    try {
      const response = await axios.get(`${BASELINE}digital/sat/folders`);
      setfoldersname(response.data);
    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  };

  useEffect(() => {
    fetchFolders2();

    const handleScroll = () => {
      const isTop = window.scrollY < 100; // Adjust the value as needed
      setIsScrolled(!isTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  }, [user]);

  function logout() {
    logoutUser();
    axios.post(BASELINE + 'user/logout', {})
      .then(function (response) {
        alert(response.data.message);
        window.location.replace(AFTER_LOGIN);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  return (
    <header className="  bg-white sticky top-0 left-0 z-20 sm:h-20 h-18 shadow-md pt-3 flex justify-center items-center">
      <div className=" justify-between items-center gap-4  flex sm:w-10/12 w-11/12  max-w-[1150px]">
        <motion.div {...fadeInFromLeft()} className="">
          <Link
            to="/"
            className=" font-inter font-bold text-[30px] hover:scale-105 duration-300"
          >
            SAT.MMT
          </Link>
        </motion.div>

        <div className="hidden justify-start items-center gap-7 md:flex">
          <motion.a
            {...fadeInFromLeft(0.2)}
            href="#"
            target="blank"
            className="hidden text-black text-2xl"
          >
            <FaXTwitter />
          </motion.a>

          <motion.div
            {...fadeInFromLeft(0.3)}
            className="space-x-4"
            onMouseEnter={() => setPracticeDropdownOpen2(true)}
            onMouseLeave={() => setPracticeDropdownOpen2(false)}
          >
            <h3>Book Answers</h3>
            {isPracticeDropdownOpen2 && (
              <div className="absolute bg-white rounded shadow p-2 flex flex-col translate-x-[-25px]">
                {foldersname.map((folder, index) => (
                  <Link
                    key={index}
                    to={`/digital/answer/${folder}`}
                    className=""
                  >
                    MMT SAT {folder.charAt(0).toUpperCase() + folder.slice(1)} Keys
                  </Link>
                ))}
              </div>
            )}
          </motion.div>

          {user ? (
            <>

              <motion.div
                {...fadeInFromLeft(0.3)}
                className="space-x-4"
                onMouseEnter={() => setTestDropdownOpen(true)}
                onMouseLeave={() => setTestDropdownOpen(false)}
              >
                <h3>Test</h3>
                {isTestDropdownOpen && (
                  <div className="absolute bg-white rounded shadow p-2 flex flex-col translate-x-[-35px]">
                    <Link to="/sat" className="">
                      Digital SAT
                    </Link>
                    <Link to="/psat89" className="hidden">
                      Digital PSAT 89
                    </Link>
                    <Link to="" className="">
                      🔒 Digital NMSQT
                    </Link>
                    <Link to="/act" className="">
                      Digital ACT
                    </Link>
                  </div>
                )}
              </motion.div>
              <motion.div
                {...fadeInFromLeft(0.3)}
                className="space-x-4"
                onMouseEnter={() => setPracticeDropdownOpen(true)}
                onMouseLeave={() => setPracticeDropdownOpen(false)}
              >
                <h3 className='hidden'>Practice</h3>
                {isPracticeDropdownOpen && (
                  <div className="absolute bg-white rounded shadow p-2 flex flex-col translate-x-[-25px]">
                    {user.quiz == 1 ? (<>
                      <Link to="/sat/topic" className="">
                        SAT Quiz
                      </Link>
                      <Link to="/psat89/topic" className="">
                        PSAT 89 Quiz
                      </Link>
                      <Link to="/psat10/topic" className="">
                        PSAT 10 Quiz
                      </Link>
                    </>) : (<>
                      <div className="flex items-center">
                        <FaLock className="mr-2" />
                        <span>SAT Quiz</span>
                      </div>
                      <div className="flex items-center">
                        <FaLock className="mr-2" /> PSAT 89 Quiz
                      </div>
                      <div className="flex items-center">
                        <FaLock className="mr-2" /> PSAT 10 Quiz
                      </div>
                    </>)}
                  </div>
                )}
              </motion.div>
            </>
          ) : (<>
            <motion.div
              {...fadeInFromLeft(0.3)}
              className="space-x-4"
              onMouseEnter={() => setPracticeDropdownOpen(true)}
              onMouseLeave={() => setPracticeDropdownOpen(false)}
            >
              <h3>Free Trial</h3>
              {isPracticeDropdownOpen && (
                <div className="absolute bg-white rounded shadow p-2 flex flex-col translate-x-[-25px]">
                  <Link to="/freetrial/sat" className="">
                    Free Digital SAT
                  </Link>

                  <Link to="/freetrial/mathquiz" className="">
                    Free Math Quiz
                  </Link>
                </div>
              )}
            </motion.div>
          </>)}

          {user ? (
            user.role === "teacher" ? (
              <motion.div {...fadeInFromLeft(0.3)}>
                <Link to="/dashboard/teacher" className="">
                  Dashboard
                </Link>
              </motion.div>
            ) : user.role === "parents" ? (
              <motion.div {...fadeInFromLeft(0.3)}>
                <Link to="/dashboard/parents" className="">
                  Parent Dashboard
                </Link>
              </motion.div>
            ) : (
              <motion.div {...fadeInFromLeft(0.3)}>
                <div className="flex items-center">
                  <Link to="/dashboard" className="">
                    My Home
                  </Link>
                </div>
              </motion.div>
            )
          ) : null}


          {!user && (
            <motion.div {...fadeInFromLeft(0.4)}>
              <Link to="/login"
                className=" border rounded-3xl border-black w-28 overflow-hidden h-10 hover:bg-black hover:text-white duration-500 flex justify-center items-center "
              >
                Login
              </Link>
            </motion.div>
          )}

        </div>
        <button
          className="text-2xl  md:hidden block "
          onClick={() => setShowNav(true)}
        >
          <HiMenuAlt3 />
        </button>
        {/* Navbar for mobile */}
        <div
          className={`${showNav ? "translate-x-0" : "-translate-x-full"
            } flex justify-center duration-500 items-center gap-7 fixed top-0 left-0 h-full w-full bg-black flex-col `}
        >
          <span
            className="absolute top-6 right-3 text-white text-2xl cursor-pointer"
            onClick={() => setShowNav(false)}
          >
            <IoClose />
          </span>

          {user && (
            <a
              onClick={() => { setShowNav(false); logout(); }}
              target="blank"
              className=" border text-white rounded-3xl border-white w-28 overflow-hidden h-10 hover:bg-white hover:text-black duration-500 flex justify-center items-center "
            >
              LogOut
            </a>
          )}


          {!user && (<>
            <div className=" text-white rounded shadow p-2 flex flex-col justify-center items-center ">
              <Link to="/freetrial/sat" className="" onClick={() => { setShowNav(false); }}>
                Free Digital SAT
              </Link>
              <Link to="/freetrial/mathquiz" className="" onClick={() => { setShowNav(false); }}>
                Free Math Quiz
              </Link>
            </div>
            <a
              onClick={() => { setShowNav(false); }}
              href="/login"
              target="blank"
              className=" border text-white rounded-3xl border-white w-28 overflow-hidden h-10 hover:bg-white hover:text-black duration-500 flex justify-center items-center "
            >
              Login
            </a>
          </>)}

          {user && user.role === "teacher" && (
            <Link to="/dashboard/teacher" className="text-white" onClick={() => setShowNav(false)}>
              Dashboard
            </Link>
          )}
          {user && (user.role === "student" || user.role === null) && (
            <Link to="/dashboard" className="text-white" onClick={() => setShowNav(false)}>
              My Home
            </Link>
          )}

          <a
            onClick={() => setShowNav(false)}
            href="#"
            target="blank"
            className="text-2xl text-white"
          >
            <FaXTwitter />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
