import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { fadeInFromLeft } from "../../util/FadeFromLeftAnimation";
import { UserContext } from "../../context/UserContext";
import { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Leaderboard from "./ranking";
import axios from "axios";
import { BASELINE } from "../../util";

const Hero = () => {
  const { logoutUser, user } = useContext(UserContext);
  const navigate = useNavigate();

  const random_sat = () => {
    // Prepare data for navigation
    const quizData = {
      questionIds: [
        "1e11190a",
        "433184f1",
        "541bef2f",
        "4d037075",
        "98d3393a",
        "6ab30ce3",
        "153ee763",
        "263f9937", 
        "95cad55f",
        "c178d4da",
        "25e1cfed",
        "4dd4efcf",
        "b0dc920d",
        "371cbf6b",
        "f89e1d6f",
        "66bce0c1",
        "ef926848",
        "52cb8ea4",
        "651d83bb",
        "e2e3942f"
      ],
      tutorModeActive: false,
      timedModeActive: false,
      timeLimit: null,
      tag: "Math",
      type: "SAT",
      rank: true,
    };

    // Navigate to quiz page with quiz data
    navigate(`/sat/quiz/`, { state: { data: quizData } });
  }

  return (
    <section className="w-full relative sm:min-h-[700px] min-h-[83vh]">
      <div className="flex justify-center items-center pt-10 sm:min-h-[600px] min-h-[85vh] ">
        <img
          src="/hero.png"
          className="w-full object-cover h-full absolute top-0 left-0"
          alt=""
          loading="lazy"
        />
        <div className="relative z-10 flex justify-center items-center flex-col gap-8 text-center w-full">
          <motion.h3
            {...fadeInFromLeft(0.1)}
            className="text-white font-archivo leading-[1.2] text-[30px] sm:text-[40px] md:text-[50px] lg:text-[60px] font-bold uppercase"
          >
            MASTER <span className="gradient-text">SAT & ACT</span> <br /> WITH CONFIDENCE
          </motion.h3>
          <motion.div {...fadeInFromLeft(0.2)} className="">
            <motion.div className="text-white font-archivo leading-[1.2] text-[20px] font-bold">
              Dive into our comprehensive problem bank designed to <br />
              challenge, teach, and prepare you for success.
            </motion.div>
          </motion.div>

          <div className="flex flex-col sm:flex-row gap-4">
          <ChooseYourTestCard title="Challenge for Rank" delay={0.1} onClick={random_sat} />

            {user ? (
              <>
              </>
            ) : (
              <>
                <ChooseYourTestCard title="Try Digital-SAT" delay={0.1} link="/freetrial/sat" />
                <ChooseYourTestCard title="Try Math Quiz" delay={0.1} link="/freetrial/mathquiz" />
              </>
            )}
          </div>

          <Leaderboard />

        </div>
      </div>
    </section>
  );
};

const ChooseYourTestCard = (props) => {
  return (
    <motion.div
      className="bg-white border flex justify-center items-center hover:scale-90 duration-300 cursor-pointer border-gray-300 shadow-md sm:h-20 h-20 w-[250px] rounded-lg"
      onClick={props.onClick}  // Apply onClick to the outermost motion.div
    >
      {props.link ? (
        <Link to={props.link}>
          <h3 className="font-archivo text-xl sm:text-2xl font-semibold ">{props.title}</h3>
        </Link>
      ) : (
        <h3 className="font-archivo text-xl sm:text-2xl font-semibold ">{props.title}</h3>
      )}
    </motion.div>
  );
};


export default Hero;
