import { useState, useEffect, useContext } from "react";
import { useStopwatch } from "react-timer-hook";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { BsThreeDotsVertical } from "react-icons/bs";
import DevQuestion from "./MathQuestion";
import axios from "axios";
import { motion, useMotionValue, useTransform } from "framer-motion";
import { UserContext } from "../../context/UserContext";
import { useLocation } from "react-router-dom";
import { BASELINE } from "../../util";
import Review from "./Review";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Select from 'react-select'
import Switch from '@material-ui/core/Switch';

const topics = {
  "Algebra": ["Simple Algebra", "Literal Equation", "Complex Fraction"],
  "Exponent & Radicals": ["Simplifying", "Solving", "Advanced"],
  "Linear Equation": ["Evaluating", "Intercepts", "Graphing", "Slopes", "Parallel / Perpendicular", "Interpreting Equation"],
  "Quadratic Equation": ["Evaluating", "Key Points", "Equivalent Expressions", "Solving", "Graphs"],
  "Functions": ["Evaluating", "Key Points", "Transformation", "Features"],
  "Unit Conversion, Rates and Proportion": ["Unit Conversion", "Variable Rates", "Ratios", "Proportions"],
  "Percents": ["Basics", "Percent Change", "Tables", "Expressions"],
  "Probability": ["Simple", "Tables", "Compound", "Conditional"],
  "Systems of Equation": ["Evaluating", "Graphing", "Interpreting Equations", "Interest Problems"],
  "Inequalities & Absolutes": ["Linear Inequalities", "Non-linear", "Systems", "Solve Absolute", "Graph Absolute"],
  "Exponential Equation": ["Basics", "Transformation of Exponential functions", "Constructing exponential equations"],
  "Polynomials & Rationals": ["Polynomial Division and the Remainder Theorem", "Simplifying Rational Expressions", "Graphing Polynomial and Rational Functions"],
  "Geometry": ["Angle", "Triangle", "Circle", "Area", "Volume", "Ratios and Similar Figures"],
  "Trigonometry": ["Special Right Triangle", "Problem Solving with Trigonometry", "Co-function", "Radians & Unit Circle"],
  "Working with Data": ["Statistics", "Reading Tables", "Reading Graphs", "Inference", "Observational studies and experiments"],
  "Word Problems": ["Word Translations", "Classic Word Problems", "Advanced/Combined mixed-topic problems"],
};


const DevQuiz = () => {
  const {
    seconds,
    minutes,
    start
  } = useStopwatch();

  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [showCalculator, setShowCalculator] = useState(false);
  const [questionOverViewOpen, setQuestionOverviewOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [bookmarkedQuestions, setBookMarkedQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [error, setError] = useState('');
  const [showDirectionModal, setShowDirectionModal] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [showDirectionModalRef, setShowDirectionRefModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [tnum, setTnum] = useState(null);
  const [q_id, setQ_id] = useState(null);
  const [note, setNote] = useState("");
  const [ans, setans] = useState(null);
  const [raion, setRaion] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedSkills, setSelectedSkills] = useState([]);

  const [selectopic, settopic] = useState('');
  const [selectskills, setskills] = useState([]);
  const [format, setformat] = useState(null);

  const [state, setState] = useState(true);
  const [sat, setsat] = useState(true);

  const [devmode, setdevmode] = useState(false);

  const handleSkillChange = (values) => {
    setSelectedSkills(values);
  };

  const handleBookMark = (itemId) => {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          if (question.bookMarked) {
            return { ...question, bookMarked: false };
          } else {
            return { ...question, bookMarked: true };
          }
        } else {
          return question;
        }
      })
    }))
  }

  async function getAllNotes(q_id) {
    try {
      let res = await axios.get(BASELINE + "questions/notes", {
        params: {
          q_id: q_id
        }
      });

      if (res.data.length < 1) {
        setStatus("Pass");
        return;
      } else {
        setStatus("Fail");
        setNote(res.data[0]);
      }
    } catch (err) {
    //  console.log(err);
    }
  }

  async function getAllQuestions() {
    try {
      let res = await axios.get(BASELINE + "questions/psatall");
      setTnum(res.data);
    } catch (err) {
    //  console.log(err);
    }
  }

  useEffect(() => {
    getAllQuestions()
  }, [])

  useEffect(() => {
    if (isNaN(id)) {
      setState(true);
      setStatus("Pass");
      setNote("");
      setans(null);
      setSelectedTopic("");
      setSelectedSkills([]);
      settopic("");
      setskills([]);
      getQuestionsbyid();
      setformat(null);
      setdevmode(true);
    } else {
      setState(true);
      setStatus("Pass");
      setNote("");
      setans(null);
      setSelectedTopic("");
      setSelectedSkills([]);
      settopic("");
      setskills([]);
      getQuestions();
      setformat(null);
      setdevmode(false);
    }
  }, [id])

  useEffect(() => {
    if (questions.length > 0 && currentQuestion) {
      let q = questions.find((ques => ques?.item_id === currentQuestion?.item_id));
      setQ_id(q.questionIds);
      setformat(q.stat[0]);
      if (q.a !== null) {
        q.style = "Multiple Choice";
      }
      setCurrentQuestion(q);

    }
  }, [questions])

  async function handleSelectOption(itemId, option) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          return { ...question, selectedOption: option };
        } else {
          return question;
        }
      })
    }))
  }
  async function handleCrossOutOptions(itemId, option) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          let crossedOutOptions = question.crossedOutOptions;
          if (crossedOutOptions.includes(option)) {
            crossedOutOptions = crossedOutOptions.filter((el) => el !== option);
          } else {
            crossedOutOptions.push(option);
          }
          return { ...question, crossedOutOptions: crossedOutOptions };
        } else {
          return question;
        }
      })
    }))
  }
  async function handleAnswerValueChange(itemId, value) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          return { ...question, selectedOption: value };
        } else {
          return question;
        }
      })
    }))
  }

  async function getQuestionsbyid() {
    setsat("Math")
    try {
      setLoadingQuestions(true);
      let res = await axios.get(BASELINE + "questions/psat/byqid", { params: { qid: id } });
      if (res.data.length < 1) {
        toast.error("Questions With these topics not found. Try other topics for now by going back")
      } else {
        start();
      }
      let arr = [res.data]
      let mappedQuestions = arr.map((question, idx) => {
        return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
      });


      setQuestions(mappedQuestions);
      setCurrentQuestion(mappedQuestions[0]);
      setLoadingQuestions(false);
      getAllNotes(arr[0].questionIds);
      setans(arr[0].correct_answer);
      setRaion(mappedQuestions[0].rationale);

      setState(arr[0].latex === "" ? true : arr[0].latex === 1);


      if (Array.isArray(arr) && arr.length > 0) {
        //console.log(arr);
        if (arr[0].practice_category_primary_section) {
          settopic(arr[0].practice_category_primary_section);
        }
        if (arr[0].skills) {
          setskills(arr[0].skills.split(","));
        }
      }
    } catch (err) {
      setLoadingQuestions(false);
      toast.error(err.message);
      setError(err.message)
    }
  }

  async function getQuestions() {

    if (id > 938) {
      setsat("Math")
    }
    else if (id <= 938) {
      setsat("English")
    }

    try {
      setLoadingQuestions(true);
      let res = await axios.get(BASELINE + "questions/psat", { params: { id: id } });
      if (res.data.length < 1) {
        toast.error("Questions With these topics not found. Try other topics for now by going back")
      } else {
        start();
      }
      let arr = [res.data]
      let mappedQuestions = arr.map((question, idx) => {
        return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
      });


      setQuestions(mappedQuestions);
      setCurrentQuestion(mappedQuestions[0]);
      setLoadingQuestions(false);
      getAllNotes(arr[0].questionIds);
      setans(arr[0].correct_answer);
      setRaion(mappedQuestions[0].rationale);

      if (Array.isArray(arr) && arr.length > 0) {
        if (arr[0].practice_category_primary_section) {
          settopic(arr[0].practice_category_primary_section);
        }
        if (arr[0].skills) {
          setskills(arr[0].skills.split(","));
        }
      }
    } catch (err) {
      setLoadingQuestions(false);
      toast.error(err.message);
      setError(err.message)
    }
  }

  const handleNext = () => {
    const nextId = Number(id) + 1;
    navigate(`/devpsat/${nextId}`);
  }

  const handleBack = () => {
    const nextId = Number(id) - 1;
    navigate(`/devpsat/${nextId}`);
  }

  const handleOptionClick = (option) => {
    if (option === "Pass") {
      const userConfirmed = window.confirm('Are you sure you want to remove this?');

      if (userConfirmed) {
        var data = {
          q_id: q_id,
        };

        axios.post(BASELINE + 'questions/remove/note/byqid', [data])
          .then((response) => {
            alert("Note Removed");
          })
          .catch((error) => {
          //  console.log(error);
          });
      }
    }
setStatus(option);
  };

const handleSubmit = async () => {
  try {
    const response = await axios.post(BASELINE + 'questions/tag', {
      topic: selectedTopic[0].value,
      skills: selectedSkills.map(skill => skill.value),
      q_id: q_id
    });

  } catch (error) {
    console.error('Failed to submit data:', error);
  }
};


return (
  <div className="flex flex-col bg-white p-1 relative pt-[120px]">
    {loadingQuestions ?
      <div className="flex flex-row justify-center items-center py-48">
        <div className="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-52 w-52"></div>
      </div>
      :
      <>
        <div className="fixed flex flex-row justify-between bg-white">
          <div>
            Saved Data:
            <p>Topic: {selectopic}</p>
            <p>Skills: {selectskills}</p>
            ----
            <div>
              Topic:

              <Select
                value={selectedTopic}
                isMulti
                onChange={(options) => setSelectedTopic(options)}
                options={Object.keys(topics).map((topic) => ({ value: topic, label: topic }))}
                className="basic-multi-select lg:min-w-[300px] lg:max-w-[300px]"
                classNamePrefix="select"
              />
            </div>

            <div>
              Skill:
              {
                selectedTopic && selectedTopic.length > 0 && (
                  <Select
                    value={selectedSkills}
                    onChange={handleSkillChange}
                    isMulti
                    name="skills"
                    options={selectedTopic.flatMap(topic =>
                      topics[topic.value].map(skill => ({ value: skill, label: skill }))
                    )}
                    className="basic-multi-select lg:min-w-[300px] lg:max-w-[300px]"
                    classNamePrefix="select"
                  />
                )
              }
            </div>

            <button
              onClick={handleSubmit}
              className="mb-[50px] bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Submit
            </button>

            <div>
              <h4>
                Question Ids: {q_id}
              </h4>
            </div>
            <div className="panel">
              {devmode == false && (
                <p>Question ID: {id} out of {tnum}</p>
              )}
              <div className="options">
                <button className={`px-4 py-2 rounded text-white ${status === 'Pass' ? 'bg-green-700' : 'bg-green-500 hover:bg-green-700'}`} onClick={() => handleOptionClick('Pass')}>Remove the Alert</button>
                <button
                  className={`hidden px-4 py-2 rounded ml-2 text-white ${status === 'Fail' ? 'bg-red-700' : 'bg-red-500 hover:bg-red-700'}`}
                  onClick={async () => {
                    handleOptionClick('Fail');
                    try {
                      const response = await fetch(BASELINE + "questions/notes", {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ note: 'Failed', q_id: q_id, id: id, sat: "sat" })
                      });
                      if (!response.ok) {
                        // handle error
                      }
                    } catch (err) {
                    //  console.log(err);
                    }
                  }}
                >
                  Fail
                </button>

              </div>
              {status === 'Fail' && (
                <>
                  <p>Note:</p>
                  <textarea
                    readOnly
                    className="mt-2 w-full p-2 border rounded"
                    placeholder="Please enter your note here..."
                    value={note.note.context}
                    onChange={async (e) => {
                      setNote(e.target.value);
                      const response = await fetch(BASELINE + "questions/notes", {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ note: e.target.value, q_id: q_id, id: id, sat: "sat" })
                      });
                      if (!response.ok) {
                        // handle error
                      }
                    }}
                  />
                </>
              )}
            </div>

          </div>

          <div className="flex flex-row gap-2">




            <div className="hidden flex flex-col cursor-pointer hover:text-blue-900">
              <div className="flex flex-row justify-center">
                <BsThreeDotsVertical size="1.5em" />
              </div>
              <div>
                <p className="text-sm">More</p>
              </div>
            </div>

          </div>
        </div>

        <div className="py-4">
          <motion.div drag style={{
            width: '600px',
            height: '400px',
            position: 'absolute',
            zIndex: 100,
            display: showCalculator ? "inline" : "none",
          }}
            dragConstraints={{
              top: -125,
              right: 500,
              bottom: 300,
              left: -125,
            }}
          >
            <div id="calculator" className="w-full h-full">

            </div>

          </motion.div>
          {/* question */}
          <div className="flex flex-col">
            <div className="flex flex-row justify-center items-center min-h-[38rem]">
              {currentQuestion ? <DevQuestion ans={ans} sat={sat} format={format} q_id={q_id} id={id} handleAnswerValueChange={handleAnswerValueChange} handleCrossOutOptions={handleCrossOutOptions} handleSelectOption={handleSelectOption} currentQuestion={currentQuestion} idx={1} bookmarkedQuestions={bookmarkedQuestions} setBookMarkedQuestions={setBookMarkedQuestions} handleBookMark={handleBookMark} /> : <Review currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} questions={questions} setQuestions={setQuestions} />}
            </div>
          </div>

        </div>
        <div className="flex flex-row justify-around  border-t-2 border-black pt-4 relative">
          {questionOverViewOpen &&
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                type: "spring",
              }} className="absolute z-[3000] rounded-md  flex flex-row justify-center p-1 bg-white -top-40 border-[1px] border-gray-300">
              <div className="min-h-[140px] max-h-[140px] min-w-[200px] max-w-[400px] flex flex-row flex-wrap rounded-md">
                {questions.map((ques, idx) => {
                  return <div key={'questions-' + idx} onClick={() => setCurrentQuestion(questions[idx])} className={`${ques.bookMarked && 'bg-red-500 border-none text-white'} m-1 rounded-full border-[1px] border-black w-[25px] h-[25px] flex flex-row justify-center items-center cursor-pointer ${currentQuestion?.item_id === ques?.item_id && "bg-black text-white"}`}>{idx + 1}</div>
                })}
              </div>
            </motion.div>
          }

          <div className="flex flex-row items-center">
            <p className="font-semibold text-lg">{(user?.first_name + " " + user?.last_name) || "John Doe"}</p>
          </div>

          {devmode == false && (
            <div className="flex flex-row items-center gap-2">
              <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800" onClick={handleBack}>
                Back
              </motion.button>
              {currentQuestion ?
                <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800" onClick={handleNext}>
                  Next
                </motion.button>
                :
                <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800">
                  Done
                </motion.button>
              }
            </div>
          )}

        </div>
      </>
    }
  </div>
);
};
export default DevQuiz;