import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { BASELINE } from "../../util";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";

const RandomACTReady = () => {
  const { user } = useContext(UserContext);
  const [selectedButton, setSelectedButton] = useState(null);
  const navigate = useNavigate();
  const [testname, setTestname] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 16; // 4x4 grid

  useEffect(() => {
    getACTquiz();
  }, []);

  async function getACTquiz() {
    try {
      let res = await axios.get(BASELINE + "get/act/test", {});
      setTestname(res.data);
    } catch (error) {
      console.error("Error fetching ACT tests:", error);
      toast.error("Failed to load ACT test names.");
    }
  }

  const startQuiz = async () => {
    if (selectedButton == null) {
      toast.error('Please select a SAT module before starting the quiz.');
    } else {
      if (user) {
        if (user.role === 'parents') {
          toast.error("Access denied: Parents are not allowed to start the test.");
        } else {
          navigate(`/act/preset/quiz/${selectedButton}`);
        }
      } else {
        toast.error('Please login to start the quiz.');
      }
    }
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = testname.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(testname.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <section className="wrapper mt-[50px]">
      <div className="w-[90%] max-w-[1000px] 2xl:max-w-[1300px] flex justify-start items-center flex-col gap-5 pt-8 px-5 bg-white rounded-2xl">
        <h2 className="text-black text-[28px] sm:text-[40px] md:text-[48px] font-bold">
          Pick The 2024 Digital ACT
        </h2>

        {/* Grid of ACT test buttons */}
        <div className="grid grid-cols-4 gap-4">
          {currentItems.map((test, index) => (
            <button
              key={index}
              onClick={() => setSelectedButton(test.exam_code)}
              className={`border border-black border-solid text-black font-bold py-2 px-4 rounded ${selectedButton === test.exam_code ? 'bg-gray-400' : 'bg-white hover:bg-gray-300'}`}
            >
              {`#${test.exam_code}`}
              <div className={`w-[150px] p-2 mt-[5px] rounded-xl text-[12px] font-normal ${selectedButton === test.exam_code ? 'bg-gray-400' : 'bg-gray-100'}`}>
                Total of 4 sections <br /> English, Mathematics <br /> Reading, Science
              </div>
            </button>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              className={`mx-1 px-3 py-1 rounded ${currentPage === i + 1 ? 'bg-gray-500 text-white' : 'bg-gray-300 text-black'}`}
            >
              {i + 1}
            </button>
          ))}
        </div>

        <div className="w-full flex flex-row justify-center py-4">
          <motion.button
            whileTap={{ scale: 0.97 }}
            className="bg-gray-500 px-4 py-2 outline-none rounded-md text-white hover:bg-gray-600 w-full glow-outline"
            onClick={() => {
              if (selectedButton === null) {
                toast.error('Please select an ACT module before starting the quiz.');
              } else {
                startQuiz();
              }
            }}
          >
            Start Quiz
          </motion.button>
        </div>
      </div>
    </section>
  );
};

export default RandomACTReady;
