import { motion } from "framer-motion";
import { fadeInFromLeft } from "../../util/FadeFromLeftAnimation";
import { Link } from "react-router-dom";

const ChooseYourTest = () => {
  return (
    <div className="max-w-[1150px] w-10/12 mx-auto pt-14 mb-[60px]">
      <motion.h2
        {...fadeInFromLeft(0.2)}
        className="title !text-black sm:text-4xl text-3xl text-center "
      >
        Choose Your Test!
      </motion.h2>
      <div className="pt-8 flex justify-evenly gap-4 flex-wrap">
        <ChooseYourTestCard title="Digital SAT" delay={0.2} link="/sat" />
        <ChooseYourTestCard title="NMSQT" delay={0.4} link="" />
        {/* <ChooseYourTestCard title="NMSQT" delay={0.4} link="/psat89" /> */}
        <ChooseYourTestCard title="ACT" delay={0.6} link="/act" />
        <ChooseYourTestCard title="AP Test" delay={0.8} link="" />
      </div>
    </div>
  );
};

const ChooseYourTestCard = (props) => {
  if (props.title === "AP Test" || props.title === "NMSQT") {
    return (
      <motion.div
        {...fadeInFromLeft(props.delay)}
        className="border flex justify-center items-center hover:scale-90 duration-300 cursor-not-allowed border-gray-300 shadow-md sm:h-24 h-20 w-[250px] rounded-lg bg-gray-300"
      >
        <h3 className="font-archivo text-2xl font-semibold ">{props.title}</h3>
      </motion.div>
    );
  }

  return (
    <motion.div
      {...fadeInFromLeft(props.delay)}
      className="border flex justify-center items-center hover:scale-90 duration-300 cursor-pointer border-gray-300 shadow-md sm:h-24 h-20 w-[250px] rounded-lg"
    >
      <Link to={props.link}>
        <h3 className="font-archivo text-2xl font-semibold ">{props.title}</h3>
      </Link>
    </motion.div>
  );
};

export default ChooseYourTest;
