import React, { useState, useEffect } from 'react';

const ImageSlider = ({ images, intervalTime = 5000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    // Automatically slide every 5 or 10 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            goToNext();
        }, intervalTime); // intervalTime in milliseconds (default is 5000ms = 5 seconds)

        return () => clearInterval(interval); // Clean up interval on component unmount
    }, [currentIndex, intervalTime]);

    return (
        <div className="m-4 mt-[20px] relative w-full max-w-3xl mx-auto overflow-hidden">
            <div
                className="flex transition-transform duration-500 ease-in-out"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {images.map((image, index) => (
                    <div key={index} className="min-w-full">
                        <img src={image} alt={`slide ${index}`} className="w-full h-[6s00px] object-cover rounded-2xl" />
                    </div>
                ))}

            </div>

            {/* 슬라이드 인디케이터 */}
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {images.map((_, index) => (
                    <div
                        key={index}
                        className={`w-3 h-3 rounded-full ${currentIndex === index ? 'bg-white' : 'bg-gray-400'}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default ImageSlider;
