import React, { useState } from 'react';
import Latex from 'react-latex';

const LatexRender = () => {
    const [latex, setLatex] = useState('$$\\frac{a}{b} = \\frac{c}{d}$$');

    const handleInputChange = (event) => {
        setLatex(event.target.value);
    };

    return (
        <div className='mt-[100px]' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '50%' }}>
                <textarea 
                    style={{ width: '100%', height: '100vh' }} 
                    value={latex} 
                    onChange={handleInputChange} 
                />
            </div>
            <div style={{ width: '50%' }}>
                <Latex>{latex}</Latex>
            </div>
        </div>
    );
};

export default LatexRender;