import React, { useState, useEffect } from 'react';
import axios from "axios";
import { BASELINE } from "../../util/index";
import { FaFilePdf } from "react-icons/fa";
import { useParams } from 'react-router-dom';

const SatAnswers = () => {
    const { folderType } = useParams();
    const [pdfFiles, setPdfFiles] = useState([]);
    const [selectedPdf, setSelectedPdf] = useState(null);

    useEffect(() => {
        // Dynamically load the data based on the folderType parameter
        axios.get(`${BASELINE}digital/sat/answers/${folderType}`)
            .then(response => {
                setPdfFiles(response.data[folderType]); // Assume API returns object with keys as folder names
            })
            .catch(error => console.error("Error fetching PDF files:", error));
    }, [folderType]); // Dependency on folderType to refetch when it changes

    const openModal = (filename) => {
        axios.get(`${BASELINE}digital/sat/answer/${folderType}/${filename}`)
            .then(response => {
                setSelectedPdf(response.data);
            })
            .catch(error => {
                console.error("Error fetching PDF:", error);
            });
    };

    const closeModal = () => {
        setSelectedPdf(null);
    };

    return (
        <div className="p-8">
            <h1 className="text-3xl font-bold text-center mb-10">MMT Digital SAT {folderType.charAt(0).toUpperCase() + folderType.slice(1)} Keys</h1>
            <div className="grid grid-cols-3 gap-4">
                {pdfFiles.map((file, index) => (
                    <div key={index} className="bg-gray-100 rounded-lg shadow p-4 cursor-pointer hover:bg-gray-200 transition duration-300 ease-in-out"
                        onClick={() => openModal(file.title)}>
                        <div className="flex items-center space-x-3">
                            <FaFilePdf className='w-6 h-6 text-red-500' />
                            <p className="text-gray-900 truncate">{file.title}</p>
                        </div>
                    </div>
                ))}
            </div>

            {selectedPdf && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
                    <div className="mt-[80px] relative top-20 mx-auto p-5 border w-[90%] shadow-lg rounded-md bg-white">
                        <div className="mt-3 text-center">
                            <h3 className="text-sm text-gray-500">{selectedPdf.filename}</h3>
                            <iframe src={`data:application/pdf;base64,${selectedPdf.content}`} className="mx-auto w-[87%] h-[650px]" title="PDF Preview"></iframe>
                            <div className="items-center px-4 py-3">
                                <button onClick={closeModal} className="px-4 py-2 bg-gray-800 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SatAnswers;
