import React, { useState, useEffect, useRef } from "react";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { ImSpellCheck } from "react-icons/im";
import { motion } from "framer-motion";
import 'katex/dist/katex.min.css';
import Latex from "react-latex-next";
import { IoExpand } from "react-icons/io5";

const Question = ({ time, sat, handleAnswerValueChange, handleCrossOutOptions, handleSelectOption, handleBookMark, currentQuestion, result }) => {
    const boxItemLeftRef = useRef();
    const [showCrossOutMenu, setShowCrossOutMenu] = useState(false);
    const [crossedOutOptions, setCrossedOutOptions] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isKatexRendered, setIsKatexRendered] = useState(false);

    // This effect runs whenever 'result' changes
    useEffect(() => {
        if (result !== undefined) {
            // Perform any additional actions needed when result changes
        }
    }, [result]);  // Only re-run the effect if result changes

    useEffect(() => {
        const script2 = document.createElement('script');
        script2.src = "https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js";
        script2.integrity = "sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05";
        script2.crossOrigin = "anonymous";
        script2.defer = true;

        // Handle script load
        script2.onload = () => {
            if (boxItemLeftRef.current) {
                if (window.renderMathInElement) {
                    const boxItemLeft = document.getElementById("box-item-left");
                    window.renderMathInElement(boxItemLeft, {
                        delimiters: [
                            { left: '$$', right: '$$', display: false },
                            { left: "$", right: "$", display: false },
                            { left: '\\(', right: '\\)', display: false },
                            { left: '\\[', right: '\\]', display: true },
                            { left: '\\begin{array}', right: '\\end{array}', display: true }
                        ],
                        throwOnError: false,
                    });
                }
                setIsKatexRendered(true);
            }
        };
        document.body.appendChild(script2);

        // Cleanup: remove the script when component unmounts
        return () => {
            document.body.removeChild(script2);
        };
    }, [currentQuestion]);

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (<>
        {sat == "Math" && (<>
            <div className="max-h-[500px] mt-[-100px] min-w-[100%] md:min-w-[35rem] max-w-[100%] md:max-w-[35rem] z-[10] overflow-auto">
                <div className="flex flex-row justify-between bg-gray-100">
                    <div className="flex flex-row gap-2">
                        <div className=" flex flex-row items-center">
                            <p className="py-2 px-3 bg-black text-white">{currentQuestion.index + 1}</p>
                        </div>
                        <div className="flex flex-row items-center gap-1">
                            <motion.div whileTap={{ scale: 0.97 }} onClick={() => handleBookMark(currentQuestion.questionIds)} className="m-1 cursor-pointer">

                                {currentQuestion.bookMarked ? <BsBookmarkFill color="red" size="1.2rem" /> : <BsBookmark size="1.2rem" />}
                            </motion.div>
                            <div>
                                <p>Mark For Review</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center">
                        <motion.div whileTap={{ scale: 0.98 }} className={`p-[5px] border-black border-[1px] rounded-md cursor-pointer ${showCrossOutMenu && "bg-blue-700 text-white"}`} onClick={() => setShowCrossOutMenu(pre => !pre)}>
                            <ImSpellCheck />
                        </motion.div>
                    </div>
                </div>

                <div id="box-item-left" >
                    <div className="mt-[20px]">

                        {currentQuestion.stem && (

                            <div className="ml-[20px]" dangerouslySetInnerHTML={{ __html: currentQuestion.stem }} ref={boxItemLeftRef} />

                        )}
                        {result === true ? (<>
                            <div className="text-black text-l mb-[5px] mt-[10px]">⏲️Time Spent: {time}sec</div>
                        </>) :
                            result === false ? (<>
                                <div className="text-black text-l mb-[5px] mt-[10px]">⏲️Time Spent: {time}sec</div>
                            </>) :
                                (<span></span>)}
                    </div>
                    <div>
                        <div className="my-2">
                            {currentQuestion.a != null ? (
                                <div className={`${result === true ? 'bg-[#daffb2]' : result === false ? 'bg-red-400' : 'bg-white'}`}>
                                    <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={0} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.a} />
                                    <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={1} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.b} />
                                    <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={2} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.c} />
                                    <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={3} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.d} />
                                    <div className={`w-[550px] p-4 rounded-xl z-[99999] ${result !== null ? '' : 'hidden'} ${result === true ? 'bg-[#daffb2]' : result === false ? 'bg-red-400' : 'bg-white'}`}>
                                        {result === true ? (
                                            <>
                                                <div className="w-[200px] text-black font-bold text-2xl border-2 p-2 rounded-xl border-black">👊 Correct!</div>
                                            </>
                                        ) : result === false ? (
                                            <>
                                                <span className="border-2 p-2 rounded-xl text-white font-bold text-2xl">🚨 Wrong!</span>
                                            </>
                                        ) : (
                                            <span></span>
                                        )}
                                        <div className={`mt-[15px] ${result === false ? '' : 'hidden'}`} dangerouslySetInnerHTML={{ __html: currentQuestion.rationale }} ref={boxItemLeftRef} />
                                    </div>

                                </div>
                            ) : (
                                <div className={`${result === true ? 'bg-[#daffb2]' : result === false ? 'bg-red-400' : 'bg-white'}`}>
                                    <input
                                        className="w-[90%] border-[1px] py-1 px-2 border-gray-300 outline-none rounded-md"
                                        type="text"
                                        value={currentQuestion.selectedOption || ""}
                                        onChange={(e) => handleAnswerValueChange(currentQuestion.questionIds, e.target.value)}
                                    />
                                    <div className={`ml-[15px] w-[550px] p-4 rounded-xl z-[99999] ${result === true ? 'bg-[#daffb2]' : result === false ? 'bg-red-400' : 'bg-white'}`}>
                                        {result === true ? (<>
                                            <div className="w-[200px] text-black font-bold text-2xl border-2 p-2 rounded-xl border-black">👊 Correct!</div>
                                            <div className="mt-[15px]" dangerouslySetInnerHTML={{ __html: currentQuestion.rationale }} />
                                        </>) :
                                            result === false ? (<>
                                                <span className="border-2 p-2 rounded-xl text-white font-bold text-2xl">🚨 Wrong!</span>
                                                <div className="mt-[15px]" dangerouslySetInnerHTML={{ __html: currentQuestion.rationale }} />
                                            </>) :
                                                (<span></span>)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div >

        </>)}

        {sat == "English" ? (
            <div className="mb-[160px] mt-[3%] z-[10]">
                <div className={`flex flex-col justify-start ${isExpanded ? 'max-w-[900px]' : 'max-w-[480px]'}`}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IoExpand size="1.5em" onClick={handleExpand} />
                    </div>
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: currentQuestion.stimulus }} className="min-h-[40%] overflow-y-auto overflow-x-hidden my-list-style" />
                    </div>
                </div>
            </div>
        ) : null}

        {sat == "English" ? (
            <div className=" mt-[-20px] mr-[50px] ml-[50px]">

                <div className="flex flex-row justify-between bg-gray-100">
                    <div className="flex flex-row gap-2">
                        <div className=" flex flex-row items-center">
                            <p className="py-2 px-3 bg-black text-white">{currentQuestion.index + 1}</p>
                        </div>
                        <div className="flex flex-row items-center gap-1">
                            <motion.div whileTap={{ scale: 0.97 }} onClick={() => handleBookMark(currentQuestion.questionIds)} className="m-1 cursor-pointer">
                                {currentQuestion.bookMarked ? <BsBookmarkFill color="red" size="1.2rem" /> : <BsBookmark size="1.2rem" />}
                            </motion.div>
                            <div>
                                <p>Mark For Review</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center">
                        <motion.div whileTap={{ scale: 0.98 }} className={`p-[5px] border-black border-[1px] rounded-md cursor-pointer ${showCrossOutMenu && "bg-blue-700 text-white"}`} onClick={() => setShowCrossOutMenu(pre => !pre)}>
                            <ImSpellCheck />
                        </motion.div>
                    </div>
                </div>

                <div className="my-2 min-w-[100px] max-w-[600px] max-h-[480px] overflow-y-auto">

                    {result === true ? (<>
                        <div className="text-black text-l mb-[5px]">⏲️Time Spent: {time}sec</div>
                    </>) :
                        result === false ? (<>
                            <div className="text-black text-l mb-[5px]">⏲️Time Spent: {time}sec</div>
                        </>) :
                            (<span></span>)}

                    <div dangerouslySetInnerHTML={{ __html: currentQuestion.stem }} className={`p-2 ${result === true ? 'bg-[#daffb2]' : result === false ? 'bg-red-400' : 'bg-white'}`} />

                    <div className={`${result === true ? 'bg-[#daffb2]' : result === false ? 'bg-red-400' : 'bg-white'}`}>
                        <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={0} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.a} />
                        <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={1} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.b} />
                        <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={2} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.c} />
                        <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={3} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.d} />
                        <div className={`ml-[15px] w-[550px] p-4 rounded-xl z-[99999] ${result === true ? 'bg-[#daffb2]' : result === false ? 'bg-red-400' : 'bg-white'}`}>
                            {result === true ? (<>
                                <div className="w-[200px] text-black font-bold text-2xl border-2 p-2 rounded-xl border-black">👊 Correct!</div>
                                <div className="mt-[15px]" dangerouslySetInnerHTML={{ __html: currentQuestion.rationale }} />
                            </>) :
                                result === false ? (<>
                                    <span className="border-2 p-2 rounded-xl text-white font-bold text-2xl">🚨 Wrong!</span>
                                    <div className="mt-[15px]" dangerouslySetInnerHTML={{ __html: currentQuestion.rationale }} />
                                </>) :
                                    (<span></span>)}
                        </div>

                    </div>
                </div>

            </div>
        ) : null
        }
    </>)
}
export default Question;


const Option = ({ handleCrossOutOptions, currentQuestion, idx, handleSelectOption, showCrossOutMenu, crossedOutOptions, data }) => {
    return (
        <div className="flex flex-row gap-1">
            <motion.div whileTap={{ scale: 0.98 }} className={`flex flex-row w-full ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-[2px]" : "border-[1px]"} rounded-md ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-blue-700 bg-blue-100" : "border-black"} gap-2 p-1 m-3 cursor-pointer relative ${currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) && "before:absolute before:bg-black before:w-[103%] before:h-[2px] before:top-[50%] before:-left-[6px]"}`} onClick={() => handleSelectOption(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>
                <div className="flex flex-row items-center">
                    <p className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
                        {getOptionLetter(idx)}
                    </p>
                </div>
                <div className="flex flex-row items-center p-[2px]" dangerouslySetInnerHTML={{ __html: data }}>
                </div>
            </motion.div>
            {showCrossOutMenu &&
                <motion.div className="flex flex-row items-center" whileTap={{ scale: 0.97 }}>
                    <div className="relative">
                        {currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) ?
                            (<p className="cursor-pointer underline" onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>Undo</p>) :

                            <>
                                <p onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())} className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full cursor-pointer">
                                    {getOptionLetter(idx)}
                                </p>
                                <div className="absolute w-6 h-[1.5px] bg-black top-3 cursor-pointer" onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>
                                </div>
                            </>

                        }


                    </div>

                </motion.div>
            }
        </div>
    )
}

function getOptionLetter(idx) {
    switch (idx) {
        case 0:
            return 'A'
            break;
        case 1:
            return 'B'
            break;
        case 2:
            return 'C'
            break;
        case 3:
            return 'D'
            break;
        default:
            return "A"
    }
}