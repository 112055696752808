import { FaStar } from "react-icons/fa";
const TestimonialCard = ({ img, desc, role, name }) => {
  return (
    <>
      <div className="sm:min-h-[450px]  py-2 min-h-[460px] w-full  max-[540px]:w-11/12 max-[540px]:mx-auto  relative rounded-2xl flex items-end duration-500  cursor-pointer group">
        <img
          loading="lazy"
          src={img}
          alt=""
          className="absolute top-0 left-1/2 -translate-x-1/2"
        />
        <div className="flex flex-col items-center  pt-16 sm:bg-white sm:shadow-md shadow-lg bg-slate-50 min-h-[390px] pb-4 rounded-2xl group-hover:bg-black group-hover:text-white duration-500">
          <p className="w-10/12 text-center text-[#4b4b4b] leading-relaxed mt-2 group-hover:text-white duration-500">
            {desc}
          </p>
          <h4 className="text-[#2a2a2a] mt-6 font-semibold group-hover:text-white duration-500">
            {name}
          </h4>
          <p className="text-[#7a7a7a] group-hover:text-white duration-500">
            {role}
          </p>
          <p className="text-primary_dark_green group-hover:text-white duration-500 flex gap-x-1 mt-3">
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
          </p>
        </div>
      </div>
    </>
  );
};

export default TestimonialCard;
