import React, { useState, useEffect, useRef } from "react";
import { ResizableBox } from "react-resizable";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { ImSpellCheck } from "react-icons/im";
import { motion } from "framer-motion";
import 'katex/dist/katex.min.css';
import { IoExpand } from "react-icons/io5";

const Question = ({ sat, handleAnswerValueChange, handleCrossOutOptions, handleSelectOption, handleBookMark, currentQuestion }) => {
    const boxItemLeftRef = useRef();
    const [showCrossOutMenu, setShowCrossOutMenu] = useState(false);
    const [crossedOutOptions, setCrossedOutOptions] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isKatexRendered, setIsKatexRendered] = useState(false);
    const [width, setWidth] = useState(480);

    useEffect(() => {
        const script2 = document.createElement('script');
        script2.src = 'https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js';
        script2.integrity = 'sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05';
        script2.crossOrigin = 'anonymous';

        script2.onload = () => {
            if (boxItemLeftRef.current) {
                const options = {
                    delimiters: [
                        { left: '$$', right: '$$', display: false },
                        { left: "$", right: "$", display: false },
                        { left: '\\(', right: '\\)', display: false },
                        { left: '\\[', right: '\\]', display: true },
                        { left: '\\begin{array}', right: '\\end{array}', display: true }
                    ],
                    throwOnError: false,
                };
                window.renderMathInElement(boxItemLeftRef.current, options);
                setIsKatexRendered(true);
            }
        };
        document.body.appendChild(script2);
        return () => {
            document.body.removeChild(script2);
        };
    }, [currentQuestion]);

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    //console.log("=currentQuestion", currentQuestion);
    return (<>
        {sat == "Math" && (
            <>
                <div className="mt-[3%] max-w-[100%] md:min-w-[35rem] md:max-w-[35rem] z-[10] overflow-auto lg:max-h-[500px] lg:overflow-y-auto"> {/* Set overflow only when screen is larger than 850px */}
                    <div className="flex flex-row justify-between bg-gray-100">
                        <div className="flex flex-row gap-2">
                            <div className="flex flex-row items-center">
                                <p className="py-2 px-3 bg-black text-white">{currentQuestion.index + 1}</p>
                            </div>
                            <div className="flex flex-row items-center gap-1">
                                <motion.div whileTap={{ scale: 0.97 }} onClick={() => handleBookMark(currentQuestion.questionIds)} className="m-1 cursor-pointer">
                                    {currentQuestion.bookMarked ? <BsBookmarkFill color="red" size="1.2rem" /> : <BsBookmark size="1.2rem" />}
                                </motion.div>
                                <div>
                                    <p>Mark For Review</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center">
                            <motion.div whileTap={{ scale: 0.98 }} className={`p-[5px] border-black border-[1px] rounded-md cursor-pointer ${showCrossOutMenu && "bg-blue-700 text-white"}`} onClick={() => setShowCrossOutMenu((pre) => !pre)}>
                                <ImSpellCheck />
                            </motion.div>
                        </div>
                    </div>

                    <div id="box-item-left" ref={boxItemLeftRef} className="max-h-[800px] overflow-y-auto">
                        <div>
                            {currentQuestion.stem && <div className="mt-[10px] ml-[20px]" dangerouslySetInnerHTML={{ __html: currentQuestion.stem }} />}
                        </div>
                        <div>
                            <div className="my-2">
                                {currentQuestion.a != null ? (
                                    <>
                                        <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={0} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.a} />
                                        <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={1} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.b} />
                                        <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={2} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.c} />
                                        <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={3} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.d} />
                                    </>
                                ) : (
                                    <input
                                        className="w-[90%] border-[1px] py-1 px-2 border-gray-300 outline-none rounded-md"
                                        type="text"
                                        value={currentQuestion.selectedOption || ""}
                                        onChange={(e) => handleAnswerValueChange(currentQuestion.questionIds, e.target.value)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )}

        {sat == "English" ? (
            <div className="mb-[10%] md:mb-[160px] mt-[3%] z-[10]">
                <div className={`flex flex-col justify-start ${isExpanded ? 'max-w-[900px]' : 'max-w-[480px]'}`}>
                    <div className="hidden md:flex justify-end">
                        <IoExpand size="1.5em" onClick={handleExpand} />
                    </div>
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: currentQuestion.stimulus }} className="pl-[3%] pr-[3%] min-h-[40%] overflow-y-auto overflow-x-hidden my-list-style" />
                    </div>
                </div>
            </div>
        ) : null}


        {sat == "English" ? (
            <div className={`mb-[160px] mt-[3%] mr-[4%] ml-[4%] md:mr-[50px] md:ml-[50px] ${isExpanded ? 'min-w-[500px]' : ''}`}>

                <div className="flex flex-row justify-between bg-gray-100">
                    <div className="flex flex-row gap-2">
                        <div className=" flex flex-row items-center">
                            <p className="py-2 px-3 bg-black text-white">{currentQuestion.index + 1}</p>
                        </div>
                        <div className="flex flex-row items-center gap-1">
                            <motion.div whileTap={{ scale: 0.97 }} onClick={() => handleBookMark(currentQuestion.questionIds)} className="m-1 cursor-pointer">

                                {currentQuestion.bookMarked ? <BsBookmarkFill color="red" size="1.2rem" /> : <BsBookmark size="1.2rem" />}
                            </motion.div>
                            <div>
                                <p>Mark For Review</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center">
                        <motion.div whileTap={{ scale: 0.98 }} className={`p-[5px] border-black border-[1px] rounded-md cursor-pointer ${showCrossOutMenu && "bg-blue-700 text-white"}`} onClick={() => setShowCrossOutMenu(pre => !pre)}>
                            <ImSpellCheck />
                        </motion.div>
                    </div>
                </div>

                <div className="my-2 max-w-[600px] max-h-[500px] overflow-y-auto">
                    <div dangerouslySetInnerHTML={{ __html: currentQuestion.stem }} className="ml-[20px]" />

                    <>
                        <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={0} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.a} />
                        <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={1} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.b} />
                        <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={2} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.c} />
                        <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={3} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.d} />
                    </>
                </div>

            </div>
        ) : null
        }
    </>)
}
export default Question;


const Option = ({ handleCrossOutOptions, currentQuestion, idx, handleSelectOption, showCrossOutMenu, crossedOutOptions, data }) => {
    return (
        <div className="flex flex-row gap-1">
            <motion.div whileTap={{ scale: 0.98 }}
                className={`flex flex-row w-full hover:bg-gray-200 ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-[2px]" : "border-[1px]"} rounded-md ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-blue-700 bg-blue-100" : "border-black"} gap-2 p-1 m-3 cursor-pointer relative ${currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) && "before:absolute before:bg-black before:w-[103%] before:h-[2px] before:top-[50%] before:-left-[6px]"}`}
                onClick={() => handleSelectOption(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>
                <div className="flex flex-row items-center">
                    <p className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
                        {getOptionLetter(idx)}
                    </p>
                </div>
                <div dangerouslySetInnerHTML={{ __html: data }} />

            </motion.div>
            {showCrossOutMenu &&
                <motion.div className="flex flex-row items-center" whileTap={{ scale: 0.97 }}>
                    <div className="relative">
                        {currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) ?
                            (<p className="cursor-pointer underline" onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>Undo</p>) :

                            <>
                                <p onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())} className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full cursor-pointer">
                                    {getOptionLetter(idx)}
                                </p>
                                <div className="absolute w-6 h-[1.5px] bg-black top-3 cursor-pointer" onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>
                                </div>
                            </>

                        }


                    </div>

                </motion.div>
            }
        </div>
    )
}

function getOptionLetter(idx) {
    switch (idx) {
        case 0:
            return 'A'
            break;
        case 1:
            return 'B'
            break;
        case 2:
            return 'C'
            break;
        case 3:
            return 'D'
            break;
        default:
            return "A"
    }
}