import React, { useState } from 'react';

const InfoIconWithTooltip = ({ tooltipText }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseMove = (e) => {
    setTooltipPosition({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <div className="relative inline-block">
      <span
        className="ml-2 text-gray-400 cursor-pointer"
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        ℹ
      </span>
      {isTooltipVisible && (
        <div
          className="fixed bg-gray-700 text-white text-sm rounded p-2 transition-opacity duration-300 opacity-50 hover:opacity-100"
          style={{
            top: `${tooltipPosition.y + 10}px`, // Offset to position below the cursor
            left: `${tooltipPosition.x + 10}px`,
            transform: 'translate(-50%, -100%)', // Adjust position relative to the cursor
            whiteSpace: 'nowrap',
            zIndex: 1000,
          }}
        >
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default InfoIconWithTooltip;
