import React, { useState, useContext } from 'react';
import axios from 'axios';
import { BASELINE, MMTLOGIN } from "../util/index";
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';

const SignInForm = ({ onLogin }) => {
    const { savelogin, user } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        axios.post(BASELINE + 'user/login', formData).then(function (response) {
            if (response.data.u_id !== '') {
                savelogin(response.data);
                setFormData({
                    email: '',
                    password: '',
                });
                onLogin();
                if (location.state?.from?.pathname) {
                    navigate(location.state.from.pathname);
                } else {
                    navigate("/");
                }
            } else {
                window.alert("Please double check the login information.");
            }
        })
            .catch(function (error) {
                window.alert("Please double check the login information.");
            });
    };

    const mmt_login = () => {
        axios.post(MMTLOGIN + 'mmtlogin', formData).then(function (response) {
            if (response.data.hasOwnProperty('uid') && response.data.uid !== '' && response.data.status !== "Registered") {
                savelogin(response.data);
                setFormData({
                    email: '',
                    password: '',
                });
                onLogin();
                navigate("/");
            } else {
                window.alert("Please double check the login information. or Contact to the administer to activate the account.");
            }
        })
            .catch(function (error) {
                window.alert("Please double check the login information. or Contact to the administer to activate the account.");
            });
    };

    return (
        <div className="pt-[120px] sm:pl-[38%] sm:pr-[38%] items-center h-screen px-4 sm:px-0">
            <div className="rounded text-[#232323]">
                <h2 className="text-2xl font-bold">✋Welcome to SAT.MMT!</h2>
                <p className='mb-4'>Please sign-in to your account and start the adventure</p>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2" htmlFor="email">
                            EMAIL
                        </label>
                        <input
                            className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 bg-white text-[#232323]"
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="">
                        <label className="block text-sm font-bold mb-2" htmlFor="password">
                            PASSWORD
                        </label>
                        <div className="relative">
                            <input
                                className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 bg-white text-[#232323]"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                required
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="text-gray-700 absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 w-8"
                            >
                                {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                            </button>
                        </div>
                    </div>
                    <Link to="/signup" className="mb-4 text-gray-400 underline">Sign Up</Link>
                    <p className='hidden mb-4 text-gray-400 underline'>Click here to reset the Password!</p>
                    <div className="mt-2 flex flex-col space-y-2">
                        <button
                            className="w-full bg-[#001F3F] text-white border border-white hover:shadow-outline-white font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline"
                            type="submit"
                        >
                            Sign In
                        </button>

                        <button
                            className="w-full bg-gray-700 text-white border border-white hover:shadow-outline-white font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={mmt_login}
                        >
                            Sign In with MMTPrep
                        </button>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default SignInForm;
