const DirectionModal = ({ onClose, mode }) => {
  return (
    <div className="fixed top-4 left-0 right-0 bottom-4 flex items-center justify-center z-50 max-h-screen">
      <div className="bg-white rounded-md p-4 max-w-md shadow-lg h-full">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="p-2 h-full">
          <h2 className="text-lg font-semibold">Tips for SAT {mode}</h2>
          <ul className="list-disc list-inside text-gray-600 overflow-y-auto h-[93%]">
            {mode == "English" && (
              <>
                <li>The questions in this section address a number of important reading and writing skills. Each question includes one or more passages, which may include a table or graph. Read each passage and question carefully, and then choose the best answer to the question based on the passage(s).</li>
                <li>All questions in this section are multiple-choice with four answer choices. Each question has a single best answer.</li>
              </>
            )}

            {mode == "Math" && (
              <>
                <li>The questions in this section address a number of important math skills.</li>
                <li>Use of a calculator is permitted for all questions. A reference sheet, calculator, and these directions can be accessed throughout the test.</li>
                <div className="mt-[15px]">Unless otherwise indicated:</div>
                <li>All variables and expressions represent real numbers.</li>
                <li>Figures provided are drawn to scale.</li>
                <li>All figures lie in a plane.</li>
                <li>The domain of a given function 𝑓 is the set of all real numbers 𝑥 for which 𝑓(𝑥) is a real number.</li>
                <div className="mt-[15px]">For multiple-choice questions, solve each problem and choose the correct answer from the choices provided. Each multiple-choice question has a single correct answer.</div>
                <div className="mt-[15px]">For student-produced response questions, solve each problem and enter your answer as described below.</div>
                <img src="/math.png" alt="Reference" />
              </>
            )}


          </ul>
        </div>

      </div>
    </div>
  );
};

export default DirectionModal;
