import { useEffect, useState, useContext, useRef } from "react";
import { Doughnut, getElementAtEvent } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { BASELINE } from "../../util";
import { motion } from "framer-motion";
import ModalComponent2 from "./pick_modal2";

ChartJS.register(ArcElement, Tooltip, Legend);

const OverallACTAnal = () => {
  const boxItemLeftRef = useRef();

  const { user } = useContext(UserContext);
  const [selected, setSelected] = useState('english');  // Default selection\
  const [selected2, setSelected2] = useState('english');  // Default selection\

  const [eproblems, seteproblems] = useState([]);
  const [mproblems, setmproblems] = useState([]);
  const [rproblems, setrproblems] = useState([]);
  const [sproblems, setsproblems] = useState([]);

  const [eper, seteper] = useState([]);
  const [mper, setmper] = useState([]);
  const [rper, setrper] = useState([]);
  const [sper, setsper] = useState([]);

  const [report_eng, set_report_eng] = useState([]);
  const [report_math, set_report_math] = useState([]);
  const [report_reading, set_report_reading] = useState([]);
  const [report_science, set_report_science] = useState([]);

  const [selectedTopic, setSelectedTopic] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const [tag, settag] = useState('');
  const [hideExplanation, setHideExplanation] = useState(false);
  const [topic, settopic] = useState(false);
  const [section, setsection] = useState("");

  const [topics, settopics] = useState([
    { topic: "POW", full: "Production of Wirting" },
    { topic: "KLA", full: "knowledge of Language" },
    { topic: "CSE", full: "Conventions of Standard English" },

    { topic: "IES", full: "Intergrating Essential Skills" },
    { topic: "S", full: "Statistics and Probability" },
    { topic: "A", full: "Algebra" },
    { topic: "G", full: "Geometry" },
    { topic: "F", full: "Functions" },
    { topic: "N", full: "Number and Quantity" },
    { topic: "T", full: "Trigonometry" },

    { topic: "CS", full: "Craft and Structure" },
    { topic: "KID", full: "Key Ideas and Details" },
    { topic: "IKI", full: "Integration of Knowledge and Ideas" },

    { topic: "IOD", full: "Interpretation of Data" },
    { topic: "SIN", full: "Scientific Investigation" },
    { topic: "EMI", full: "Evaluation Models, Inferences, and Exprimental Results" }
  ]);

  const preprocessOptions = (optionsString) => {
    try {
      // First, replace problematic backslashes (assuming they are not needed for JSON format)
      let formattedOptions = optionsString.replace(/\\(?![/uU])/g, '');

      // Escape double quotes inside HTML attributes
      formattedOptions = formattedOptions.replace(/<img([^>]+)>/gi, (match) => {
        return match.replace(/"/g, '\\"');
      });

      // Now try to parse the JSON
      return JSON.parse(optionsString);
    } catch (e) {
      console.error("Failed to parse options:", e);
      return []; // Fallback to an empty array if parsing fails
    }
  };

  const RenderOptions = () => {
    try {
      let options = preprocessOptions(selectedTopic.options); // Process options

      if (typeof options === "string") {
        try {
          options = JSON.parse(options); // Attempt to parse if it's still a string
        } catch (e) {
          console.error("Failed to parse options:", e);
          console.error("Problematic JSON:", options);
          options = []; // Fallback to an empty array if parsing fails
        }
      }

      // If options is an array, map over it
      return options.length > 0 ? (
        options.map((option, idx) => (
          <Option
            sat={selectedTopic.subject}
            key={idx}
            currentQuestion={selectedTopic}
            option={option}
          />
        ))
      ) : (
        <p>No options available</p> // Fallback if the options array is empty
      );
    } catch (error) {
      console.error("Error parsing options:", error);
      return null; // Return nothing if there is an error
    }
  };

  const cleanSubjectName = (name) => {
    // Check if the name starts and ends with brackets, e.g., ["T"]
    if (name.startsWith('[') && name.endsWith(']')) {
      // Remove the first two and last two characters (which includes the double quotes and brackets)
      return name.slice(2, -2);
    }
    return name;
  };

  const replaceTopicNames = (subjectsArray, topicsArray) => {
    return subjectsArray.map(subject => {
      // Clean the subject name if needed
      const cleanedName = cleanSubjectName(subject.name);

      // Find the corresponding topic in the topicsArray
      const matchingTopic = topicsArray.find(topic => topic.topic === cleanedName);

      // If a matching topic is found, replace 'name' with 'full', otherwise keep it as is
      return {
        ...subject,
        name: matchingTopic ? matchingTopic.full : cleanedName
      };
    });
  };

  const handleChange2 = () => {
    setHideExplanation(!hideExplanation);
  };

  useEffect(() => {
    getting_problems();
    //getallsat();
    //getusersat();
  }, []);

  useEffect(() => {
    // Dynamically load the KaTeX script
    const script2 = document.createElement('script');
    script2.src = 'https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js';
    script2.integrity = 'sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05';
    script2.crossOrigin = 'anonymous';

    // Handle script load
    script2.onload = () => {
      if (boxItemLeftRef.current) {
        const options = {
          delimiters: [
            { left: '$$', right: '$$', display: false },
            { left: "$", right: "$", display: false },
            { left: '\\(', right: '\\)', display: false },
            { left: '\\[', right: '\\]', display: true },
            { left: '\\begin{array}', right: '\\end{array}', display: true }
          ],
          throwOnError: false,
        };

        window.renderMathInElement(boxItemLeftRef.current, options);
      }
    };
    document.body.appendChild(script2);

    // Cleanup: remove the script when component unmounts
    return () => {
      document.body.removeChild(script2);
    };
  }, [selectedTopic, hideExplanation]);

  const getsatquestion = (questionId, tag) => {
    settag(tag);
    axios.get(BASELINE + "questions/act", { // Ensure the endpoint is correctly specified
      params: {
        id: questionId  // Changed from questionId to qid to match the server expectation
      }
    })
      .then(response => {
        setSelectedTopic(response.data[0]);
        setIsModalOpen(true);
      })
      .catch(err => {
        console.error("Error fetching SAT question data:", err);
      });
  }


  const updateScoresWithApiData = (apiData) => {
    setScores(prevScores => {
      // Create a map from the API data for quick lookup
      const apiScoresMap = apiData.reduce((acc, item) => {
        acc[item.test_name] = item.total_score;
        return acc;
      }, {});

      // Map over the existing scores and update 'myScore' if there's a matching entry in the API data
      return prevScores.map(score => ({
        ...score,
        myScore: apiScoresMap[score.name] || score.myScore // Use API score if available, otherwise keep the existing score
      }));
    });
  }

  const getusersat = () => {
    axios.get(`${BASELINE}get/user/sat`, {
      params: {
        user_id: user.u_id  // assuming 'user_id' is the correct parameter name expected by your server
      }
    })
      .then(response => {
        //    console.log("Data received:", response.data);
        updateScoresWithApiData(response.data);
      })
      .catch(err => {
        console.error("Error fetching user SAT data:", err);
      });
  }

  const getallsat = () => {
    axios.get(`${BASELINE}get/all/act`)
      .then(response => {
        const data = response.data;
        // Aggregate scores by test_name
        const scoresMap = data.reduce((acc, item) => {
          if (!acc[item.test_name]) {
            acc[item.test_name] = { total: 0, count: 0 };
          }
          acc[item.test_name].total += item.total_score;
          acc[item.test_name].count += 1;
          return acc;
        }, {});

        // Calculate averages and round them
        const averages = Object.keys(scoresMap).map(test => ({
          test_name: test,
          avgScore: Math.round(scoresMap[test].total / scoresMap[test].count) // Rounds to the nearest integer
        }));

        // Update the scores array
        const updatedScores = scores.map(score => {
          const averageData = averages.find(avg => avg.test_name === score.name);
          return {
            ...score,
            avgScore: averageData ? averageData.avgScore : score.avgScore // Uses the rounded average
          };
        });

        setScores(updatedScores);
        // Now you can set this updatedScores to your state or use it as needed
      })
      .catch(err => console.error("Error fetching SAT scores:", err));
  }

  const getting_problems = () => {
    axios.get(`${BASELINE}tests/byuser/act/${user.u_id}`)
      .then(response => {
        const allIncorrectIds = response.data.reduce((acc, test) => {
          if (test.Incorrect_questionIds) {
            const incorrectIds = JSON.parse(test.Incorrect_questionIds);
            acc = acc.concat(incorrectIds);
          }
          return acc;
        }, []);

        const allcorrectIds = response.data.reduce((acc, test) => {
          if (test.Correct_questionIds) {
            const correctIds = JSON.parse(test.Correct_questionIds);
            acc = acc.concat(correctIds);
          }
          return acc;
        }, []);

        // Splitting the incorrect questions by tag
        const allQuestions = [...allIncorrectIds, ...allcorrectIds];

        const engQuestions = allQuestions.filter(question => question.tag === 'english');
        const mathQuestions = allQuestions.filter(question => question.tag === 'math');
        const scienceQuestions = allQuestions.filter(question => question.tag === 'science');
        const readingQuestions = allQuestions.filter(question => question.tag === 'reading');

        // Further split by topics
        const groupedByEngTopic = groupByTopic(engQuestions);
        const groupedByMathTopic = groupByTopic(mathQuestions);
        const groupedByScienceTopic = groupByTopic(scienceQuestions);
        const groupedByReadingTopic = groupByTopic(readingQuestions);

        const topEngQuestions = getTopQuestions(engQuestions);
        const topMathQuestions = getTopQuestions(mathQuestions);
        const topScienceQuestions = getTopQuestions(scienceQuestions);
        const topReadingQuestions = getTopQuestions(readingQuestions);

        const updatedtopEngQuestions = replaceTopicNames(topEngQuestions, topics);
        const updatedtopMathQuestions = replaceTopicNames(topMathQuestions, topics);
        const updatedtopScienceQuestions = replaceTopicNames(topScienceQuestions, topics);
        const updatedtopReadingQuestions = replaceTopicNames(topReadingQuestions, topics);

        seteproblems(updatedtopEngQuestions);
        setmproblems(updatedtopMathQuestions);
        setrproblems(updatedtopReadingQuestions);
        setsproblems(updatedtopScienceQuestions);

        const epercentages = calculatePercentages(engQuestions);
        const mpercentages = calculatePercentages(mathQuestions);
        const rpercentages = calculatePercentages(readingQuestions);
        const spercentages = calculatePercentages(scienceQuestions);

        updateChartData(epercentages.incorrectPercentage, epercentages.correctPercentage, epercentages.omittedPercentage, 'e');
        seteper(epercentages);
        updateChartData(mpercentages.incorrectPercentage, mpercentages.correctPercentage, mpercentages.omittedPercentage, 'm');
        setmper(mpercentages);
        updateChartData(rpercentages.incorrectPercentage, rpercentages.correctPercentage, rpercentages.omittedPercentage, 'r');
        setrper(rpercentages);
        updateChartData(spercentages.incorrectPercentage, spercentages.correctPercentage, spercentages.omittedPercentage, 's');
        setsper(spercentages);

        const engSubjects = transformData(groupedByEngTopic);
        const mathSubjects = transformData(groupedByMathTopic);
        const readingSubjects = transformData(groupedByReadingTopic);
        const scienceSubjects = transformData(groupedByScienceTopic);

        const updatedengSubjects = replaceTopicNames(engSubjects, topics);
        const updatedmathSubjects = replaceTopicNames(mathSubjects, topics);
        const updatedreadingSubjects = replaceTopicNames(readingSubjects, topics);
        const updaatedscienceSubjects = replaceTopicNames(scienceSubjects, topics);


        set_report_eng(updatedengSubjects);
        set_report_math(updatedmathSubjects);
        set_report_reading(updatedreadingSubjects);
        set_report_science(updaatedscienceSubjects);
      })
      .catch(error => {
        console.error("Error fetching user tests:", error);
      });
  }

  const calculatePercentages = (questions) => {
    const totalQuestions = questions.length;
    let correctCount = 0;
    let incorrectCount = 0;
    let omittedCount = 0;

    questions.forEach((question) => {
      if (question.selectedOption === null) {
        // Omitted questions
        omittedCount++;
      } else if (question.correct) {
        // Correctly answered questions
        correctCount++;
      } else {
        // Incorrectly answered questions
        incorrectCount++;
      }
    });

    const correctPercentage = ((correctCount / totalQuestions) * 100).toFixed(1);
    const incorrectPercentage = ((incorrectCount / totalQuestions) * 100).toFixed(1);
    const omittedPercentage = ((omittedCount / totalQuestions) * 100).toFixed(1);

    return {
      correctPercentage,
      incorrectPercentage,
      omittedPercentage,
    };
  };

  const updateChartData = (incorrectPercentage, correctPercentage, omittedPercentage, letter) => {
    if (letter === 'e') {
      seteDoughnutData({
        datasets: [{
          data: [incorrectPercentage, correctPercentage, omittedPercentage],
          backgroundColor: ['#FF6384', '#4BC0C0', '#E7E9ED'],
          hoverBackgroundColor: ['#FF6384', '#4BC0C0', '#E7E9ED'],
          borderWidth: 0,
        }],
        labels: ['Incorrect', 'Correct', 'Omitted'],
      });
    }
    else if (letter === 'm') {
      setmDoughnutData({
        datasets: [{
          data: [incorrectPercentage, correctPercentage, omittedPercentage],
          backgroundColor: ['#FF6384', '#4BC0C0', '#E7E9ED'],
          hoverBackgroundColor: ['#FF6384', '#4BC0C0', '#E7E9ED'],
          borderWidth: 0,
        }],
        labels: ['Incorrect', 'Correct', 'Omitted'],
      });
    } else if (letter === 'r') {
      setrDoughnutData({
        datasets: [{
          data: [incorrectPercentage, correctPercentage, omittedPercentage],
          backgroundColor: ['#FF6384', '#4BC0C0', '#E7E9ED'],
          hoverBackgroundColor: ['#FF6384', '#4BC0C0', '#E7E9ED'],
          borderWidth: 0,
        }],
        labels: ['Incorrect', 'Correct', 'Omitted'],
      });
    } else if (letter === 's') {
      setsDoughnutData({
        datasets: [{
          data: [incorrectPercentage, correctPercentage, omittedPercentage],
          backgroundColor: ['#FF6384', '#4BC0C0', '#E7E9ED'],
          hoverBackgroundColor: ['#FF6384', '#4BC0C0', '#E7E9ED'],
          borderWidth: 0,
        }],
        labels: ['Incorrect', 'Correct', 'Omitted'],
      });
    }
  };

  const [edoughnutData, seteDoughnutData] = useState({
    datasets: [{
      data: [7, 93], // Default values
      backgroundColor: ['#FF6384', '#E7E9ED'],
      hoverBackgroundColor: ['#FF6384', '#E7E9ED'],
      borderWidth: 0,
    }],
    labels: ['Incorrect', 'Correct', 'Omitted'],
  });

  const [mdoughnutData, setmDoughnutData] = useState({
    datasets: [{
      data: [7, 93], // Default values
      backgroundColor: ['#FF6384', '#E7E9ED'],
      hoverBackgroundColor: ['#FF6384', '#E7E9ED'],
      borderWidth: 0,
    }],
    labels: ['Incorrect', 'Correct', 'Omitted'],
  });

  const [rdoughnutData, setrDoughnutData] = useState({
    datasets: [{
      data: [7, 93], // Default values
      backgroundColor: ['#FF6384', '#E7E9ED'],
      hoverBackgroundColor: ['#FF6384', '#E7E9ED'],
      borderWidth: 0,
    }],
    labels: ['Incorrect', 'Correct', 'Omitted'],
  });

  const [sdoughnutData, setsDoughnutData] = useState({
    datasets: [{
      data: [7, 93], // Default values
      backgroundColor: ['#FF6384', '#E7E9ED'],
      hoverBackgroundColor: ['#FF6384', '#E7E9ED'],
      borderWidth: 0,
    }],
    labels: ['Incorrect', 'Correct', 'Omitted'],
  });

  const groupByTopic = (questions) => {
    return questions.reduce((acc, question) => {
      const topic = question.category;
      if (!acc[topic]) {
        acc[topic] = { correct: 0, incorrect: 0, omitted: 0, total: 0 };
      }
      if (question.selectedOption === null) {
        acc[topic].omitted++;
      } else if (question.correct) {
        acc[topic].correct++;
      } else {
        acc[topic].incorrect++;
      }
      acc[topic].total++;
      return acc;
    }, {});
  };

  const calculatePercentage = (count, total) => {
    return `${((count / total) * 100).toFixed(1)}%`; // Ensure percentages are in string format with a '%'
  };

  const transformData = (groupedData) => {
    return Object.entries(groupedData).map(([topic, counts]) => ({
      name: topic,
      correct: calculatePercentage(counts.correct, counts.total),
      incorrect: calculatePercentage(counts.incorrect, counts.total),
      omitted: calculatePercentage(counts.omitted, counts.total),
    }));
  };

  const getTopQuestions = (questions) => {
    const frequencyMap = questions.reduce((acc, question) => {
      if (!acc[question.qid]) {
        acc[question.qid] = { count: 0, topic: question.category };
      }
      acc[question.qid].count += 1;
      return acc;
    }, {});

    return Object.entries(frequencyMap)
      .sort((a, b) => b[1].count - a[1].count) // Sort by frequency
      .slice(0, 10) // Get top 10
      .map(([id, { topic }]) => ({
        id: id,
        name: topic
      }));
  }

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const handleChange3 = (event) => {
    setSelected2(event.target.value);
  };

  const handleRowClick = (subjectName, section) => {
    setsection(section);
    settopic(subjectName);
    setIsModalOpen2(false);
    // You can perform any actions here, such as navigation or displaying details
  };

  const [scores, setScores] = useState([
    { name: "SAT 1", myScore: 0, avgScore: 400 },
    { name: "SAT 2", myScore: 0, avgScore: 410 },
    { name: "SAT 3", myScore: 0, avgScore: 420 },
    { name: "SAT 4", myScore: 0, avgScore: 400 },
    { name: "SAT 5", myScore: 0, avgScore: 410 },
    { name: "SAT 6", myScore: 0, avgScore: 420 },
  ]);

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    cutout: '80%',
  };

  return (<div className="bg-white">
    <ModalComponent2 topic={topic} isOpen={isModalOpen2} onClose={setIsModalOpen2} section={section} />
    <div className="bg-white pl-6 pt-6 text-gray-500 text-sm">
      <span className="hover:underline cursor-pointer">My Home</span> &gt; <span className="font-semibold text-black">ACT Performance</span>
    </div>

    <div className="mt-[20px] text-gray-800 text-lg font-semibold">Dashboard</div>
    <hr />
    <div className="flex space-x-4 p-4 mr-4">
      <div className="relative bg-white p-4 rounded" style={{ flex: '0 0 50%' }}>
        <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
          <div className="h-full w-full bg-white rounded-xl"></div>
        </div>
        <div className="relative">
          <h2 className="text-lg font-semibold mb-2">📖 Top 10 English Missing Problems</h2>
          <div style={{ height: '230px', overflowY: 'auto' }}> {/* Enable vertical scrolling */}
            <table className="min-w-full" style={{ borderCollapse: 'collapse', border: 'none' }}>
              <thead className="border-b">
                <tr style={{ border: 'none' }}>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Topic</th>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Action</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                {eproblems.map((problem) => (
                  <tr key={problem.qid} style={{ border: 'none' }}>
                    <td className="px-4 py-2" style={{ border: 'none' }}>{problem.name}</td>
                    <td className="px-4 py-2" style={{ border: 'none' }}>
                      <button className="bg-slate-600 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded" onClick={() => getsatquestion(problem.id, 'english')}>
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>
      </div>

      <div className="relative flex-3 bg-white p-4 rounded" style={{ flex: '0 0 50%' }}>
        <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
          <div className="h-full w-full bg-white rounded-xl"></div>
        </div>
        <div className="relative">
          <h2 className="text-lg font-semibold mb-2">🧮 Top 10 Math Missing Problems</h2>
          <div style={{ height: '230px', overflowY: 'auto' }}> {/* Enable vertical scrolling */}
            <table className="min-w-full" style={{ borderCollapse: 'collapse', border: 'none' }}>
              <thead className="border-b">
                <tr style={{ border: 'none' }}>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Topic</th>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Action</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                {mproblems.map((problem) => (
                  <tr key={problem.qid} style={{ border: 'none' }}>
                    <td className="px-4 py-2" style={{ border: 'none' }}>{problem.name}</td>
                    <td className="px-4 py-2" style={{ border: 'none' }}>
                      <button className="bg-slate-600 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded" onClick={() => getsatquestion(problem.id, 'math')}>
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>

    <div className="flex space-x-4 p-4 mr-4">
      <div className="relative bg-white p-4 rounded" style={{ flex: '0 0 50%' }}>
        <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
          <div className="h-full w-full bg-white rounded-xl"></div>
        </div>
        <div className="relative">
          <h2 className="text-lg font-semibold mb-2">💌 Top 10 Reading Missing Problems</h2>
          <div style={{ height: '230px', overflowY: 'auto' }}> {/* Enable vertical scrolling */}
            <table className="min-w-full" style={{ borderCollapse: 'collapse', border: 'none' }}>
              <thead className="border-b">
                <tr style={{ border: 'none' }}>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Topic</th>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Action</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                {rproblems.map((problem) => (
                  <tr key={problem.qid} style={{ border: 'none' }}>
                    <td className="px-4 py-2" style={{ border: 'none' }}>{problem.name}</td>
                    <td className="px-4 py-2" style={{ border: 'none' }}>
                      <button className="bg-slate-600 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded" onClick={() => getsatquestion(problem.id, 'reading')}>
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>
      </div>

      <div className="relative flex-3 bg-white p-4 rounded" style={{ flex: '0 0 50%' }}>
        <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
          <div className="h-full w-full bg-white rounded-xl"></div>
        </div>
        <div className="relative">
          <h2 className="text-lg font-semibold mb-2">⚗️ Top 10 Science Missing Problems</h2>
          <div style={{ height: '230px', overflowY: 'auto' }}> {/* Enable vertical scrolling */}
            <table className="min-w-full" style={{ borderCollapse: 'collapse', border: 'none' }}>
              <thead className="border-b">
                <tr style={{ border: 'none' }}>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Topic</th>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Action</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                {sproblems.map((problem) => (
                  <tr key={problem.qid} style={{ border: 'none' }}>
                    <td className="px-4 py-2" style={{ border: 'none' }}>{problem.name}</td>
                    <td className="px-4 py-2" style={{ border: 'none' }}>
                      <button className="bg-slate-600 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded" onClick={() => getsatquestion(problem.id, 'science')}>
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>

    <div className="hidden flex space-x-4 p-4">
      <div className="relative flex-3 bg-white p-4 rounded" style={{ flex: '0 0 100%' }}>
        <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
          <div className="h-full w-full bg-white rounded-xl"></div>
        </div>
        <div className="relative">
          <h2 className="text-lg font-semibold mb-2">🥇 Overall Average SAT Score</h2>
          <div style={{ height: '180px', overflowY: 'auto' }}>
            <table className="min-w-full text-sm" style={{ borderCollapse: 'collapse', border: 'none' }}>
              <thead className="border-b">
                <tr>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Test #</th>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Score</th>
                  <th className="px-4 py-2 text-left" style={{ border: 'none' }}>Avg Score</th>
                </tr>
              </thead>
              <tbody>
                {scores.map((score, index) => (
                  <tr key={index} style={{ border: 'none' }}>
                    <td className="px-4 py-2" style={{ border: 'none' }}>{score.name}</td>
                    <td className="px-4 py-2" style={{ border: 'none' }}>{score.myScore}</td>
                    <td className="px-4 py-2" style={{ border: 'none' }}>{score.avgScore}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div className="text-gray-800 text-lg font-semibold">Statistics</div>
    <hr />
    <div className="ml-[5px] mt-[10px] flex items-center space-x-2">
      <label htmlFor="category-select" className="text-sm font-medium">Shows:</label>
      <select
        id="category-select"
        value={selected2}
        onChange={handleChange3}
        className="bg-transparent border-none border-b border-gray-200 text-gray-700 py-1 focus:ring-0 focus:border-blue-500"
      >
        <option value="english">English</option>
        <option value="math">Math</option>
        <option value="reading">Reading</option>
        <option value="science">Science</option>
      </select>
    </div>
    {selected2 === 'english' && (
      <div className="ml-[15px] bg-white p-4 rounded-lg flex justify-center items-center mb-[10px]">
        <div className="flex w-full">
          {/* Doughnut Chart */}
          <div className="flex">
            <div className="relative w-48 h-48 mr-[150px]"> {/* Ensure this div is relatively positioned */}
              <Doughnut data={edoughnutData} options={doughnutOptions} />
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <p className="text-sm font-semibold"></p> {/* This will center the text in the Doughnut */}
              </div>
            </div>
          </div>

          {/* Score Table */}
          <div className="w-4/6 flex flex-col justify-center">
            <div className="text-lg font-semibold mb-4">Total English Solved Score</div>
            <div className="space-y-2">
              <div className="flex justify-between">
                <div>Total Correct:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{eper.correctPercentage} %</div>
              </div>
              <hr />
              <div className="flex justify-between">
                <div>Total Incorrect:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{eper.incorrectPercentage} %</div>
              </div>
              <hr />
              <div className="flex justify-between">
                <div>Total Omitted:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{eper.omittedPercentage} %</div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    )}

    {selected2 === 'math' && (
      <div className="ml-[15px] bg-white p-4 rounded-lg flex justify-center items-center mb-[10px]">
        <div className="flex w-full">
          {/* Doughnut Chart */}
          <div className="flex">
            <div className="relative w-48 h-48 mr-[150px]"> {/* Ensure this div is relatively positioned */}
              <Doughnut data={mdoughnutData} options={doughnutOptions} />
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <p className="text-sm font-semibold"></p> {/* This will center the text in the Doughnut */}
              </div>
            </div>
          </div>

          {/* Score Table */}
          <div className="w-4/6 flex flex-col justify-center">
            <div className="text-lg font-semibold mb-4">Total Math Solved Score</div>
            <div className="space-y-2">
              <div className="flex justify-between">
                <div>Total Correct:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{mper.correctPercentage} %</div>
              </div>
              <hr />
              <div className="flex justify-between">
                <div>Total Incorrect:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{mper.incorrectPercentage} %</div>
              </div>
              <hr />
              <div className="flex justify-between">
                <div>Total Omitted:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{mper.omittedPercentage} %</div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    )}

    {selected2 === 'reading' && (
      <div className="ml-[15px] bg-white p-4 rounded-lg flex justify-center items-center mb-[10px]">
        <div className="flex w-full">
          {/* Doughnut Chart */}
          <div className="flex">
            <div className="relative w-48 h-48 mr-[150px]"> {/* Ensure this div is relatively positioned */}
              <Doughnut data={rdoughnutData} options={doughnutOptions} />
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <p className="text-sm font-semibold"></p> {/* This will center the text in the Doughnut */}
              </div>
            </div>
          </div>

          {/* Score Table */}
          <div className="w-4/6 flex flex-col justify-center">
            <div className="text-lg font-semibold mb-4">Total Reading Solved Score</div>
            <div className="space-y-2">
              <div className="flex justify-between">
                <div>Total Correct:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{rper.correctPercentage} %</div>
              </div>
              <hr />
              <div className="flex justify-between">
                <div>Total Incorrect:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{rper.incorrectPercentage} %</div>
              </div>
              <hr />
              <div className="flex justify-between">
                <div>Total Omitted:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{rper.omittedPercentage} %</div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    )}

    {selected2 === 'science' && (
      <div className="ml-[15px] bg-white p-4 rounded-lg flex justify-center items-center mb-[10px]">
        <div className="flex w-full">
          {/* Doughnut Chart */}
          <div className="flex">
            <div className="relative w-48 h-48 mr-[150px]"> {/* Ensure this div is relatively positioned */}
              <Doughnut data={sdoughnutData} options={doughnutOptions} />
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <p className="text-sm font-semibold"></p> {/* This will center the text in the Doughnut */}
              </div>
            </div>
          </div>

          {/* Score Table */}
          <div className="w-4/6 flex flex-col justify-center">
            <div className="text-lg font-semibold mb-4">Total Science Solved Score</div>
            <div className="space-y-2">
              <div className="flex justify-between">
                <div>Total Correct:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{sper.correctPercentage} %</div>
              </div>
              <hr />
              <div className="flex justify-between">
                <div>Total Incorrect:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{sper.incorrectPercentage} %</div>
              </div>
              <hr />
              <div className="flex justify-between">
                <div>Total Omitted:</div>
                <div className='bg-gray-200 p-2 rounded-2xl'>{sper.omittedPercentage} %</div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    )}

    <div className="mt-[20px] text-gray-800 text-lg font-semibold">Reports</div>
    <hr />
    <div className="ml-[5px] mt-[10px] flex items-center space-x-2">
      <label htmlFor="category-select" className="text-sm font-medium">Shows:</label>
      <select
        id="category-select"
        value={selected}
        onChange={handleChange}
        className="bg-transparent border-none border-b border-gray-200 text-gray-700 py-1 focus:ring-0 focus:border-blue-500"
      >
        <option value="english">English</option>
        <option value="math">Math</option>
        <option value="reading">Reading</option>
        <option value="science">Science</option>
      </select>
    </div>

    <div className="bg-white w-full">
      <table className="w-full border-none mb-[120px]">
        <thead className="border-b">
          <tr>
            <th className="border-none px-6 py-3 text-left text-gray-500">NAME</th>
            <th className="border-none px-6 py-3 text-left text-gray-500">CORRECT Q</th>
            <th className="border-none px-6 py-3 text-left text-gray-500">INCORRECT Q</th>
            <th className="border-none px-6 py-3 text-left text-gray-500">OMITTED Q</th>
          </tr>
        </thead>
        <tbody>
          {selected === 'english' && report_eng.map((item) => (
            <tr key={item.name} className="border-none cursor-pointer hover:bg-gray-200" onClick={() => handleRowClick(item.name, "english")}>
              <td className="border-none px-6 py-4">
                {item.name}
                <div className="relative w-full bg-gray-200 rounded-full mt-2">
                  <div className="absolute bg-blue-600 h-2 rounded-full" style={{ width: `${item.correct}`, left: 0 }}></div>
                  <div className="absolute bg-red-600 h-2 rounded-full" style={{ width: `${item.incorrect}`, left: `${item.correct}` }}></div>
                  <div className="absolute bg-gray-400 h-2 rounded-full" style={{ width: `${item.omitted}`, left: `calc(${item.correct} + ${item.incorrect})` }}></div>
                </div>
              </td>
              <td className="border-none px-6 py-4">{item.correct}</td>
              <td className="border-none px-6 py-4">{item.incorrect}</td>
              <td className="border-none px-6 py-4">{item.omitted}</td>
            </tr>
          ))}

          {selected === 'math' && report_math.map((item) => (
            <tr key={item.name} className="border-none cursor-pointer hover:bg-gray-200" onClick={() => handleRowClick(item.name, "math")}>
              <td className="border-none px-6 py-4">
                {item.name}
                <div className="relative w-full bg-gray-200 rounded-full mt-2">
                  <div className="absolute bg-blue-600 h-2 rounded-full" style={{ width: `${item.correct}`, left: 0 }}></div>
                  <div className="absolute bg-red-600 h-2 rounded-full" style={{ width: `${item.incorrect}`, left: `${item.correct}` }}></div>
                  <div className="absolute bg-gray-400 h-2 rounded-full" style={{ width: `${item.omitted}`, left: `calc(${item.correct} + ${item.incorrect})` }}></div>
                </div>
              </td>
              <td className="border-none px-6 py-4">{item.correct}</td>
              <td className="border-none px-6 py-4">{item.incorrect}</td>
              <td className="border-none px-6 py-4">{item.omitted}</td>
            </tr>
          ))}

          {selected === 'reading' && report_reading.map((item) => (
            <tr key={item.name} className="border-none cursor-pointer hover:bg-gray-200" onClick={() => handleRowClick(item.name, "reading")}>
              <td className="border-none px-6 py-4">
                {item.name}
                <div className="relative w-full bg-gray-200 rounded-full mt-2">
                  <div className="absolute bg-blue-600 h-2 rounded-full" style={{ width: `${item.correct}`, left: 0 }}></div>
                  <div className="absolute bg-red-600 h-2 rounded-full" style={{ width: `${item.incorrect}`, left: `${item.correct}` }}></div>
                  <div className="absolute bg-gray-400 h-2 rounded-full" style={{ width: `${item.omitted}`, left: `calc(${item.correct} + ${item.incorrect})` }}></div>
                </div>
              </td>
              <td className="border-none px-6 py-4">{item.correct}</td>
              <td className="border-none px-6 py-4">{item.incorrect}</td>
              <td className="border-none px-6 py-4">{item.omitted}</td>
            </tr>
          ))}

          {selected === 'science' && report_science.map((item) => (
            <tr key={item.name} className="border-none cursor-pointer hover:bg-gray-200" onClick={() => handleRowClick(item.name, "science")}>
              <td className="border-none px-6 py-4">
                {item.name}
                <div className="relative w-full bg-gray-200 rounded-full mt-2">
                  <div className="absolute bg-blue-600 h-2 rounded-full" style={{ width: `${item.correct}`, left: 0 }}></div>
                  <div className="absolute bg-red-600 h-2 rounded-full" style={{ width: `${item.incorrect}`, left: `${item.correct}` }}></div>
                  <div className="absolute bg-gray-400 h-2 rounded-full" style={{ width: `${item.omitted}`, left: `calc(${item.correct} + ${item.incorrect})` }}></div>
                </div>
              </td>
              <td className="border-none px-6 py-4">{item.correct}</td>
              <td className="border-none px-6 py-4">{item.incorrect}</td>
              <td className="border-none px-6 py-4">{item.omitted}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>


    <div className={`fixed inset-0 flex items-center justify-center z-50 ${isModalOpen ? '' : 'hidden'}`}>
      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
        <div className="absolute inset-0 bg-gray-500 opacity-85"></div>
      </div>
      {(tag === 'english' || tag === 'reading' || tag === 'science') && (
        <div className="p-2 relative bg-white w-[100%] h-[95%] overflow-auto rounded-xl">
          <button className="absolute top-0 right-0 p-2" onClick={() => setIsModalOpen(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-600">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="text-[32px] font-bold pt-[5px] pl-[20px] pt-[20px]">
            {selectedTopic.subject}
          </div>

          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2 w-full mt-[20px] border-b-[1px] md:border-b-0 md:border-r-[1px] border-gray-300">
              <div>
                <div dangerouslySetInnerHTML={{ __html: selectedTopic.content }} className="max-h-[500px] overflow-y-auto overflow-x-hidden ml-[20px] mt-[10px]" />
              </div>

              <label className="ml-[20px] mt-[20px] md:mt-[100px] flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={hideExplanation}
                  onChange={handleChange2}
                  className="form-checkbox h-5 w-5 text-gray-600"
                />
                <span>Hide correct answer and explanation</span>
              </label>
            </div>
            <div className="md:w-1/2 w-full md:ml-[20px] md:mr-[20px]">
              <div className="flex flex-row justify-between bg-gray-100">
                <div className="flex flex-row gap-2">
                  <div className="ml-[10px] p-2 flex flex-row items-center gap-1">
                    <div>
                      <p>Question Review</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-2 max-w-[600px] max-h-[480px] overflow-y-auto">
                <div dangerouslySetInnerHTML={{ __html: selectedTopic.prompt }} className="ml-[20px]" />
                <RenderOptions />
              </div>

              {!hideExplanation && (
                <div className="mt-[20px]">
                  <div dangerouslySetInnerHTML={{ __html: selectedTopic.expl }} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {tag === 'math' && (
        <div className="relative bg-white w-[100%] h-[95%] overflow-auto rounded-xl">
          <button className="absolute top-0 right-0 p-2" onClick={() => setIsModalOpen(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-600">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="ml-[20px] text-[32px] font-bold pt-[5px] pl-[5px]">
            Math
          </div>
          <div className="flex" ref={boxItemLeftRef}>
            <div className="w-1/2 mt-[20px] border-r-[1px] border-gray-300">
              <div>
                <div dangerouslySetInnerHTML={{ __html: selectedTopic.prompt }} className="ml-[20px] max-h-[500px] overflow-y-auto overflow-x-hidden ml-[20px] mt-[10px]" />
              </div>

              <label className="ml-[20px] mt-[100px] flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={hideExplanation}
                  onChange={handleChange2}
                  className="form-checkbox h-5 w-5 text-gray-600"
                />
                <span>Hide correct answer and explanation</span>
              </label>
            </div>
            <div className="w-1/2 ml-[20px] mr-[20px]">
              <div className="flex flex-row justify-between bg-gray-100">
                <div className="flex flex-row gap-2">
                  <div className="ml-[20px] p-2 flex flex-row items-center gap-1">
                    <div>
                      <p>Question Review</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-2 max-w-[600px] max-h-[480px] overflow-y-auto">
                <RenderOptions />

              </div>

              {!hideExplanation && (
                <div className="mt-[20px]">
                  <div dangerouslySetInnerHTML={{ __html: selectedTopic.expl }} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  </div>);
};

export default OverallACTAnal;

const Option = ({ sat, currentQuestion, option }) => {
  const optionLetter = option.charAt(0); // Extract the first character as the option letter
  const optionText = option.slice(2).trim(); // Extract the text part after the letter and period

  return (
    <div className="flex flex-row gap-1">
      <motion.div whileTap={{ scale: 0.98 }}
        className={`flex flex-row w-full ${currentQuestion.selectedOption === optionLetter.toLowerCase() ? "border-[2px]" : "border-[1px]"} rounded-md ${currentQuestion.selectedOption === optionLetter.toLowerCase() ? "border-blue-700 bg-blue-100" : "border-black"} gap-2 p-1 m-3 cursor-pointer relative"}`}
      >
        <div className="flex flex-row items-center">
          <p className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
            {optionLetter}
          </p>
        </div>
        <div className="flex flex-row items-center p-[2px]">
          {sat === "math" ? (<>
            <div dangerouslySetInnerHTML={{ __html: optionText }} />
          </>
          ) : (
            <p>{optionText}</p>
          )}
        </div>
      </motion.div>
    </div>
  );
}