import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { BASELINE } from "../../util";
import axios from "axios";

const PaginatedTable = ({ folders, setFolders, fetchPdfs }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [selectedPdfContent, setSelectedPdfContent] = useState('');
    const [isAddPdfModalOpen, setIsAddPdfModalOpen] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState('');
    const [newFolderName, setNewFolderName] = useState('');
    const [file, setFile] = useState(null);
    const [foldersname, setfoldersname] = useState([]);

    const itemsPerPage = 11;

    const allPdfs = Object.keys(folders).reduce((acc, folder) => {
        return [...acc, ...folders[folder]];
    }, []);

    useEffect(() => {
        fetchFolders2();
    }, []);

    const handleDeleteFolder = async (folderName) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete the folder "${folderName}"? This action will remove all files inside the folder.`);

        if (confirmDelete) {
            try {
                await axios.delete(`${BASELINE}digital/sat/folder/${folderName}`);
                // Remove the folder from the state
                const updatedFolders = foldersname.filter(folder => folder !== folderName);
                setfoldersname(updatedFolders);
                fetchPdfs();
                fetchFolders2();
                alert('Folder and its contents were successfully deleted.');
            } catch (error) {
                console.error("Error deleting folder:", error);
                alert('An error occurred while trying to delete the folder.');
            }
        }
    };

    const fetchFolders2 = async () => {
        try {
            const response = await axios.get(`${BASELINE}digital/sat/folders`);
            setfoldersname(response.data);
        } catch (error) {
            console.error("Error fetching folders:", error);
        }
    };

    const handleViewPdf = async (folder, pdf) => {
        try {
            const response = await axios.get(`${BASELINE}digital/sat/answer/${folder}/${pdf.title}`);
            setSelectedPdf(pdf.title);
            setSelectedPdfContent(response.data.content);
            setIsModalOpen(true);
        } catch (error) {
            console.error("Error fetching PDF content:", error);
        }
    };

    const handleDeletePdf = async (folder, pdf) => {
        if (window.confirm('Are you sure you want to delete this PDF file?')) {
            try {
                await axios.delete(`${BASELINE}digital/sat/answer/${folder}/${pdf.title}`);
                const updatedFolders = { ...folders };
                updatedFolders[folder] = updatedFolders[folder].filter(p => p.title !== pdf.title);
                setFolders(updatedFolders);
                fetchPdfs();
            } catch (error) {
                console.error("Error deleting PDF file:", error);
            }
        }
    };

    const handleCreateFolder = async () => {
        if (newFolderName) {
            try {
                const response = await axios.post(`${BASELINE}digital/sat/folder/create`, { folderName: newFolderName });
                if (response.status === 200) {
                    setFolders({ ...folders, [newFolderName]: [] });
                    setNewFolderName('');
                    fetchFolders2();
                    alert("Folder creation completed successfully!");
                }
            } catch (error) {
                console.error("Error creating folder:", error);
            }
        }
    };

    const handleFileUpload = async () => {
        if (file && selectedFolder) {
            const reader = new FileReader();

            reader.onloadend = async () => {
                const base64File = reader.result.split(',')[1]; // Extract base64 data

                const payload = {
                    fileName: file.name,
                    fileContent: base64File,
                    folder: selectedFolder
                };

                try {
                    const response = await axios.post(`${BASELINE}digital/sat/upload`, payload, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    // Reload the folder data after upload
                    const updatedFolders = { ...folders };
                    updatedFolders[selectedFolder] = [...updatedFolders[selectedFolder], { title: file.name, folder: selectedFolder }];
                    setFolders(updatedFolders);
                    setFile(null);
                    setIsAddPdfModalOpen(false);
                    fetchPdfs();
                } catch (error) {
                    if (error.response && error.response.status === 409) {
                        // Handle the conflict error
                        alert(error.response.data.error);

                        // Optional: Automatically retry with suggested name
                        const retry = window.confirm(`Would you like to upload the file with the suggested name: ${error.response.data.suggestedName}?`);
                        if (retry) {
                            payload.fileName = error.response.data.suggestedName;
                            await axios.post(`${BASELINE}digital/sat/upload`, payload, {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });
                        }
                    } else {
                        console.error("Error uploading file:", error);
                    }
                }
            };

            reader.readAsDataURL(file);
        } else {
            console.error("File or folder not selected");
        }
    };


    const filteredPdfs = allPdfs.filter(pdf =>
        pdf.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredPdfs.length / itemsPerPage);

    const paginatedPdfs = filteredPdfs.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedPdfContent('');
    };

    return (
        <div>
            <div className="flex items-center justify-between p-2 border-b">
                <h3 className="text-2xl font-bold text-slate-900">Digital SAT Key Management</h3>
                <button
                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
                    onClick={() => setIsAddPdfModalOpen(true)}
                >
                    Add PDF
                </button>
            </div>

            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Search PDF by name..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="p-2 border border-gray-300 rounded w-full"
                />
            </div>

            <table className="min-w-full bg-white">
                <thead>
                    <tr>
                        <th className="py-2">PDF Name</th>
                        <th className="py-2">Folder Name</th>
                        <th className="py-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedPdfs.map((pdf, index) => (
                        <tr key={index} className="border-b">
                            <td className="py-2">{pdf.title}</td>
                            <td className="py-2">{pdf.folder}</td>
                            <td className="py-2">
                                <motion.button
                                    whileTap={{ scale: 0.98 }}
                                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded mr-2"
                                    onClick={() => handleViewPdf(pdf.folder, pdf)}
                                >
                                    View
                                </motion.button>
                                <motion.button
                                    whileTap={{ scale: 0.98 }}
                                    className="bg-red-500 text-white font-bold py-2 px-4 rounded"
                                    onClick={() => handleDeletePdf(pdf.folder, pdf)}
                                >
                                    Delete
                                </motion.button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="flex justify-center mt-4">
                <button
                    className={`mx-1 px-3 py-1 rounded ${currentPage === 1 ? 'bg-gray-300 text-gray-800' : 'bg-blue-500 text-white'}`}
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                {Array.from({ length: totalPages }, (_, i) => (
                    <button
                        key={i}
                        onClick={() => handlePageChange(i + 1)}
                        className={`mx-1 px-3 py-1 rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}
                    >
                        {i + 1}
                    </button>
                ))}
                <button
                    className={`mx-1 px-3 py-1 rounded ${currentPage === totalPages ? 'bg-gray-300 text-gray-800' : 'bg-blue-500 text-white'}`}
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>

            {/* Modal for Viewing PDF */}
            {isModalOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
                        <div className="w-[90%] bg-white rounded-lg overflow-hidden shadow-xl transform transition-all p-6 z-20">
                            <div className="flex justify-between items-center">
                                <h2 className="text-xl font-bold">{selectedPdf}</h2>
                                <button
                                    className="text-red-500 font-bold text-xl"
                                    onClick={handleCloseModal}
                                >
                                    X
                                </button>
                            </div>
                            <div className="mt-4">
                                <embed
                                    src={`data:application/pdf;base64,${selectedPdfContent}`}
                                    type="application/pdf"
                                    width="100%"
                                    height="600px"
                                />
                            </div>
                            <div className="mt-6 text-right">
                                <button
                                    className="bg-blue-500 text-white py-2 px-4 rounded"
                                    onClick={handleCloseModal}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal for Adding PDF */}
            {isAddPdfModalOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
                        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-lg w-full p-6 z-20">
                            <div className="flex justify-between items-center">
                                <h2 className="text-xl font-bold">Add PDF</h2>
                                <button
                                    className="text-red-500 font-bold text-xl"
                                    onClick={() => setIsAddPdfModalOpen(false)}
                                >
                                    X
                                </button>
                            </div>

                            <div className="mt-4">
                                <div className="mb-4">
                                    <input
                                        type="text"
                                        placeholder="New Folder Name"
                                        value={newFolderName}
                                        onChange={(e) => setNewFolderName(e.target.value)}
                                        className="p-2 border border-gray-300 rounded w-full"
                                    />
                                    <button
                                        className="bg-green-500 text-white font-bold py-2 px-4 rounded mt-2"
                                        onClick={handleCreateFolder}
                                    >
                                        Create Folder
                                    </button>
                                </div>

                                <div className="mb-4">
                                    <div className="flex items-center justify-between mb-2">
                                        <h3 className="text-lg font-bold">Select Folder</h3>
                                        <button
                                            className="bg-red-500 text-white py-1 px-3 rounded"
                                            onClick={() => handleDeleteFolder(selectedFolder)}
                                            disabled={!selectedFolder} // Disable the button if no folder is selected
                                        >
                                            Remove
                                        </button>
                                    </div>


                                    <div className="flex flex-wrap">
                                        {foldersname.map((folder, index) => (
                                            <button
                                                key={index}
                                                className={`m-2 py-2 px-4 rounded ${selectedFolder === folder ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}
                                                onClick={() => setSelectedFolder(folder)}
                                            >
                                                {folder}
                                            </button>
                                        ))}
                                    </div>

                                </div>

                                <div className="mb-4">
                                    <input
                                        type="file"
                                        onChange={(e) => setFile(e.target.files[0])}
                                        className="p-2 border border-gray-300 rounded w-full"
                                    />
                                </div>

                                <div className="text-right">
                                    <button
                                        className="bg-blue-500 text-white py-2 px-4 rounded"
                                        onClick={handleFileUpload}
                                    >
                                        Upload PDF
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PaginatedTable;
