import { useEffect, useState, useContext } from "react";
import { BASELINE } from "../../util";
import axios from "axios";
import { Doughnut } from 'react-chartjs-2';
import { UserContext } from "../../context/UserContext";
import { motion } from "framer-motion";
import { useParams, useLocation, useNavigate } from "react-router-dom";

const ACTScoreboard = () => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const [testData, setTestData] = useState({});
    const [error, setError] = useState("");
    const [scores, setScores] = useState({});
    const [missingTopics, setmissingTopics] = useState({});

    const [topics, settopics] = useState([
        { topic: "POW", full: "Production of Wirting" },
        { topic: "KLA", full: "knowledge of Language" },
        { topic: "CSE", full: "Conventions of Standard English" },

        { topic: "IES", full: "Intergrating Essential Skills" },
        { topic: "S", full: "Statistics and Probability" },
        { topic: "A", full: "Algebra" },
        { topic: "G", full: "Geometry" },
        { topic: "F", full: "Functions" },
        { topic: "N", full: "Number and Quantity" },
        { topic: "T", full: "Trigonometry" },

        { topic: "CS", full: "Craft and Structure" },
        { topic: "KID", full: "Key Ideas and Details" },
        { topic: "IKI", full: "Integration of Knowledge and Ideas" },

        { topic: "IOD", full: "Interpretation of Data" },
        { topic: "SIN", full: "Scientific Investigation" },
        { topic: "EMI", full: "Evaluation Models, Inferences, and Exprimental Results" }
    ]);

    const location = useLocation();
    const data = location.state?.data;

    const subjects = ['English', 'Math', 'Reading', 'Science'];
    const getChartData = (score) => ({
        labels: ['Score', 'Remaining'],
        datasets: [
            {
                data: [score, 36 - score],
                backgroundColor: ['#4F46E5', '#2D3748'], // Adjust colors to match dark background
                borderWidth: 0, // Remove border to blend better with the background
            },
        ],
    });

    const params = useParams();

    useEffect(() => {
        if (!data) {
            getTestData(params.id);
        } else {
            setTestData(data); // Use the data from location.state if available
        }
    }, [params.id, data, user, navigate]);

    const expandTopicNames = (groupedTopics, topics) => {
        const fullNames = {};

        // Create a mapping from the abbreviation to full name for easy lookup
        const topicMapping = topics.reduce((acc, topic) => {
            acc[topic.topic] = topic.full;
            return acc;
        }, {});

        // Iterate over each subject in groupedTopics to replace abbreviations
        Object.keys(groupedTopics).forEach(subject => {
            fullNames[subject] = groupedTopics[subject].map(abbr => {
                // Remove potential quotes and parse if it's wrapped in an array as string
                const cleanAbbr = abbr.replace(/[\[\]"]+/g, '');
                return topicMapping[cleanAbbr] || cleanAbbr;  // Use the full name if available, else use the abbreviation itself
            });
        });

        return fullNames;
    };


    async function getTestData(id) {
        try {
            let res = await axios.get(BASELINE + "tests/" + id);
            setTestData(res.data);

            const parsedScores = JSON.parse(res.data.eng_score);
            setScores(parsedScores);

            const wrongquestions = JSON.parse(res.data.Incorrect_questionIds);
            // Initialize an empty object to store the missing topics grouped by section
            const groupedTopics = {};

            // Iterate over each question in the array
            wrongquestions.forEach((question) => {
                const section = question.section;  // Get the section (e.g., 'english', 'math')
                const category = question.category;  // Get the category (e.g., 'Grammar', 'Punctuation')

                // Check if the section already exists in the missingTopics object
                if (!groupedTopics[section]) {
                    groupedTopics[section] = [];  // Initialize an empty array if it doesn't exist
                }

                // Add the category to the section array, but only if it's not already present (remove duplicates)
                if (!groupedTopics[section].includes(category)) {
                    groupedTopics[section].push(category);
                }
            });

            // Update the state with the processed topics
            console.log(groupedTopics);
            const expandedNames = expandTopicNames(groupedTopics, topics);
            setmissingTopics(expandedNames);

        } catch (err) {
            setError(err.message)
        }
    }

    return (
        <div className="flex mt-2 justify-center items-center flex-col w-full h-full">
            <div className="pl-6 pr-6 w-full max-w-[1000px] 2xl:max-w-[1300px] bg-white p-8 rounded-2xl shadow-md">

                <h1 className="text-[24px] sm:text-[32px] md:text-[40px] font-bold text-black">
                    {user && (<>
                        <span className="gradient-text">{user.first_name}</span>'s ACT Analytics Report is Here!
                    </>)}
                </h1>
                <h2 className="text-[20px] sm:text-[24px] md:text-[28px] font-bold text-black">
                    Uncover Your Insights Through MMTPrep.
                </h2>
                <p className="text-gray-700 mt-[5px]">
                    Our AI-powered test accurately predicts your score and provides a detailed analysis of your strengths and weaknesses based on the questions you’ve answered.
                </p>
                <div className="mt-8">
                    <h3 className="text-[18px] sm:text-[22px] md:text-[26px] font-bold text-black">
                        Score Analysis
                    </h3>
                    <div className="mt-2 bg-gray-900 text-white p-6 rounded-lg shadow-md flex flex-col">
                        <div className="flex justify-between mb-8">
                            {subjects.map((subject, index) => (
                                <div key={index} className="text-center mt-2">
                                    <Doughnut
                                        data={getChartData(scores[subject.toLowerCase()])}
                                        options={{
                                            cutout: '80%', // Controls the size of the inner cutout (makes it look like a circle graph)
                                            plugins: {
                                                tooltip: { enabled: false },
                                                legend: { display: false },
                                            },
                                        }}
                                        className="w-24 h-24 mx-auto"
                                    />
                                    <div className="mt-2 text-lg">
                                        <p className="gradient-text inline-block">{subject}:</p>
                                        <p className="inline-block text-white font-bold ml-1">
                                            {scores[subject.toLowerCase()]} /36
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* Topic Boxes */}
                <div className="w-[100%] mt-4 flex flex-col sm:flex-row justify-around grid grid-cols-2 gap-4">
                    <GradientBorderBox
                        title="English"
                        score={scores.english}
                        range="0 - 36"
                        description="Missing Topics"
                        icon="🗣️"
                        topics={missingTopics.english}
                    />

                    <GradientBorderBox
                        title="Math"
                        score={scores.math}
                        range="0 - 36"
                        description="Missing Topics"
                        icon="😃"
                        topics={missingTopics.math}
                    />

                    <GradientBorderBox
                        title="Reading"
                        score={scores.reading}
                        range="0 - 36"
                        description="Missing Topics"
                        icon="✨"
                        topics={missingTopics.reading}
                    />

                    <GradientBorderBox
                        title="Science"
                        score={scores.science}
                        range="0 - 36"
                        description="Missing Topics"
                        icon="🚀"
                        topics={missingTopics.science}
                    />
                </div>

                <motion.button
                    whileTap={{ scale: 0.97 }}
                    className="mt-6 bg-gray-500 px-4 py-2 outline-none rounded-md text-white hover:bg-gray-600 w-full glow-outline"
                    onClick={() => navigate('/act')}
                >
                    Retake the ACT
                </motion.button>
            </div>
        </div>
    );
};

const GradientBorderBox = ({ title, score, range, description, icon, topics = [] }) => {
    return (
        <div className="relative bg-white p-6 rounded-xl shadow-md w-full md:mx-2">
            <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
                <div className="h-full w-full bg-white rounded-xl"></div>
            </div>
            <div className="relative">
                <span className="inline-block bg-purple-200 text-purple-700 text-xs font-semibold px-2 py-1 rounded-full">ACT Section Score</span>
                <h2 className="text-xl font-bold text-gray-900 mt-2">{title}</h2>
                <div className="flex items-center mt-4">
                    {icon && <span className="mr-2">{icon}</span>}
                    <span className="text-4xl font-bold text-gray-800">{score}</span>
                    <span className="text-xl text-gray-500 ml-2">{range}</span>
                </div>
                <span className="text-gray-500 text-sm">ACT Score</span>
                <p className="text-gray-600 font-bold">{description}</p>

                {/* Missing Topics List */}
                <div className="mt-4 max-h-32 overflow-y-auto">
                    <ul className="list-disc pl-5 text-gray-600">
                        {topics.map((topic, index) => (
                            <li key={index}>{topic}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};



export default ACTScoreboard;
