import { useEffect, useState, useContext } from "react";
import Study from "../home/Study";
import Hero from "./Hero";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASELINE } from "../../util";
import { UserContext } from "../../context/UserContext";
import { toast } from "react-hot-toast";

const PresatResult = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [testData, setTestData] = useState({});
  const [error, setError] = useState("");
  const location = useLocation();
  const data = location.state?.data;
  const free = location.state?.free;

  const params = useParams();

  useEffect(() => {
    if (!data) {
      getTestData(params.id);
    } else {
      setTestData(data); // Use the data from location.state if available
    }
  }, [params.id, data, user, navigate]);

  async function getTestData(id) {
    try {
      let res = await axios.get(BASELINE + "tests/" + id);
      setTestData(res.data);
    } catch (err) {
      setError(err.message);
    }
  }

  return (
    <>
      <Hero testData={testData} free={free} />
    </>
  );
};

export default PresatResult;
