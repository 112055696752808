import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import Draggable from "react-draggable";
import { motion } from "framer-motion";

const ReferenceSheet = ({ onClose }) => {
  const [position, setPosition] = useState({ x: -600, y: 100 });

  const handleDrag = (e, data) => {
    const {x, y} = data;
    const outOfBoundsX = x < 0 || x > window.innerWidth - 200; // 200 is the width of the component
    const outOfBoundsY = y < 0 || y > window.innerHeight - 200; // 200 is the height of the component

    if (outOfBoundsX || outOfBoundsY) {
        setPosition({x: 0, y: 0}); // Reset position to top left corner
    } else {
        setPosition({x, y});
    }
};

  return (
    <Draggable handle=".modal-header" position={position} onStop={handleDrag}>
      <div className="absolute w-[50%] h-full top-14 z-50 cursor-pointer">
        <div className="bg-white border-2 border-lightgray">
          <div className="modal-header flex flex-row items-center bg-black text-white p-1">
            <h6 className="flex-grow">Reference Sheet</h6>
            <motion.button whileTap={{ scale: 0.97 }}>
              <IoMdClose size="1.3rem" onClick={onClose} className="cursor-pointer hover:text-gray-200" />
            </motion.button>
          </div>
          <div className="modal-body p-1">
          <img src="/sat_math.png" alt="Reference" />
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default ReferenceSheet;
