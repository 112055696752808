import { Link } from "react-router-dom";
import Select from 'react-select'
import { useEffect, useState, useContext } from "react";
import { BASELINE } from "../../util";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";

const data = [
  {
    topic: "Algebra",
    subtopics: [
      { name: "Simple Algebra", id: "A1" },
      { name: "Literal Equation", id: "A2" },
      { name: "Complex Fraction & Complex Algebra", id: "A3" }
    ]
  },
  {
    topic: "Exponents & Radical",
    subtopics: [
      { name: "Simplifying", id: "B1" },
      { name: "Solving", id: "B2" },
      { name: "Advanced", id: "B3" }
    ]
  },
  {
    topic: "Linear Equation",
    subtopics: [
      { name: "Evaluating", id: "C1" },
      { name: "Key Points & Features", id: "C2" },
      { name: "Parallel, Perpendicular & Equivalent Expressions", id: "C3" },
      { name: "Solving", id: "C4" },
      { name: "Graphs", id: "C5" },
      { name: "Constructing", id: "C6" },
      { name: "Interpreting", id: "C7" }
    ]
  },
  {
    topic: "Quadratics",
    subtopics: [
      { name: "Evaluating", id: "D1" },
      { name: "Key Points", id: "D2" },
      { name: "Equivalent Expressions", id: "D3" },
      { name: "Solving", id: "D4" },
      { name: "Graphs", id: "D5" }
    ]
  },
  {
    topic: "Functions",
    subtopics: [
      { name: "Evaluating", id: "R1" },
      { name: "Function Notation", id: "R2" },
      { name: "Transformation", id: "R3" },
      { name: "Features & Interpreting", id: "R4" }
    ]
  },
  {
    topic: "Unit Conversion, Rates, and Proportion",
    subtopics: [
      { name: "Unit Conversion", id: "E1" },
      { name: "Variable Rates", id: "E2" },
      { name: "Ratios", id: "E3" },
      { name: "Proportions", id: "E4" }
    ]
  },
  {
    topic: "Percents",
    subtopics: [
      { name: "Basics", id: "F1" },
      { name: "Percent Change", id: "F2" },
      { name: "Tables", id: "F3" },
      { name: "Expressions", id: "F4" }
    ]
  },
  {
    topic: "Probability",
    subtopics: [
      { name: "Simple Probability", id: "G1" },
      { name: "Tables", id: "G2" },
      { name: "Compound", id: "G3" },
      { name: "Conditional", id: "G4" }
    ]
  },
  {
    topic: "Systems of Equation",
    subtopics: [
      { name: "Linear System", id: "H1" },
      { name: "Complex System", id: "H2" },
      { name: "Number of Solutions", id: "H3" },
      { name: "Graphs", id: "H4" },
      { name: "Interpreting", id: "H5" }
    ]
  },
  {
    topic: "Inequalities & Absolutes",
    subtopics: [
      { name: "Linear Inequalities", id: "I1" },
      { name: "Non-Linear", id: "I2" },
      { name: "Systems of Inequalities", id: "I3" },
      { name: "Solving Absolute & Absolute Inequality", id: "I4" },
      { name: "Graphs of Absolute Function", id: "I5" },
      { name: "Graphs of Inequality", id: "I6" }
    ]
  },
  {
    topic: "Exponential Equation",
    subtopics: [
      { name: "Constructing", id: "Q1" },
      { name: "Evaluating", id: "Q2" },
      { name: "Graphs of Exponential Equations", id: "Q3" },
      { name: "Interpreting Exponential Equations", id: "Q4" }
    ]
  },
  {
    topic: "Polynomial & Rational functions",
    subtopics: [
      { name: "Synthetic/Long Division & Remainder Theorem", id: "P1" },
      { name: "Simplifying Rational Expressions", id: "P2" },
      { name: "Graphs", id: "P3" }
    ]
  },
  {
    topic: "Geometry",
    subtopics: [
      { name: "Angle", id: "J1" },
      { name: "Circle", id: "J2" },
      { name: "Polygon", id: "J3" },
      { name: "Triangle", id: "J4" },
      { name: "Volume", id: "J5" },
      { name: "Area & Perimeter", id: "J6" },
      { name: "1D 2D 3D Ratio", id: "J7" }
    ]
  },
  {
    topic: "Interpreting Data",
    subtopics: [
      { name: "Reading Graphs", id: "K1" },
      { name: "Reading Tables", id: "K2" }
    ]
  },
  {
    topic: "Statistics",
    subtopics: [
      { name: "MMRM", id: "L1" },
      { name: "Interpret Data", id: "L2" },
      { name: "Compare Data Sets", id: "L3" },
      { name: "Inference", id: "L4" },
      { name: "Evaluating statistical claims: Observational studies and experiments", id: "L5" }
    ]
  },
  {
    topic: "Trigonometry",
    subtopics: [
      { name: "Basic Trigonometric Ratios & Special Right Triangle", id: "M1" },
      { name: "Problem Solving with Trigonometry", id: "M2" },
      { name: "Cofunction & Unit Circle", id: "M3" }
    ]
  },
  {
    topic: "Word Problems",
    subtopics: [
      { name: "Constructing Equation (Word Translation)", id: "O1" },
      { name: "Interpreting Equation", id: "O2" },
      { name: "Systems of Equation & Systems of Inequalities", id: "O3" },
      { name: "Unit Conversion, Rates, Proportion & Percents", id: "O4" },
      { name: "Validating Equation", id: "O5" },
      { name: "Validating Statements", id: "O6" },
      { name: "Simple Equation", id: "O7" },
      { name: "Linear Equation", id: "O8" },
      { name: "Inequality", id: "O9" },
      { name: "Exponential", id: "O10" },
      { name: "Function", id: "O11" },
      { name: "Quadratics", id: "O12" }
    ]
  }
];

const Topic = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [sections, setSections] = useState(["English", "Math"]);

  const [error, setError] = useState("");
  const [modules, setModules] = useState([{ mmtmode: false, mmtdifficultys: [], topics: [], selectedSection: "", selectedSubject: "", subjects: [], selectedTopics: [], difficulty: "", numberOfQuestions: 10, selectedTopicTotal: 0 }]);

  const copyModule = (index) => {
    const moduleToCopy = { ...modules[index] };
    setModules([...modules, moduleToCopy]);
  };

  const updateTotalQuestions = (idx, topics, difficulty) => {
    let total = 0;
    if (modules[idx].mmtmode) {
      if (difficulty) {
        total = modules[idx].mmtdifficultys.find(d => d.difficultys === difficulty)?.total || 0;
      }
    } else {
      if (Array.isArray(topics.difficulties) && difficulty) {  // Check if topics is an array
        const difficultyItem = topics.difficulties.find(item => item.difficulty === difficulty);
        total = difficultyItem ? difficultyItem.total : 0;
      }
    }
    updateModule(idx, 'selectedTopicTotal', total);
  };

  const removeModule = (index) => {
    setModules(modules.filter((_, idx) => idx !== index));
  };

  const addModule = () => {
    setModules([...modules, { mmtmode: false, mmtdifficultys: [], topics: [], selectedSection: "", selectedSubject: "", selectedTopics: [], difficulty: "", numberOfQuestions: 10, selectedTopicTotal: 0 }]);
  };

  function updateModule(idx, key, value) {
    setModules(prevModules => {
      const newModules = [...prevModules];
      newModules[idx] = { ...newModules[idx], [key]: value || [] }; // Ensure value is never null or undefined
      return newModules;
    });
  }

  async function handleStartQuiz() {
    if (!user) {
      toast.error("Please login before starting the test");
      navigate("/login"); // Redirect user to the /login page
      return;
    }

    if (user.u_id) {
      let hasError = false;

      for (let i = 0; i < modules.length; i++) {
        const module = modules[i];

        if (module.selectedSection.length < 1) {
          toast.error("Subject Not Section");
          hasError = true;
        }

        if (module.selectedSubject.length < 1) {
          toast.error("Subject Not Selected");
          hasError = true;
        }

        if (module.selectedTopics.length < 1) {
          toast.error("Topic Not Selected");
          hasError = true;
        }

        if (module.numberOfQuestions < 2 || module.numberOfQuestions > module.selectedTopicTotal) {
          console.log(module.numberOfQuestions, module.selectedTopicTotal);
          toast.error("Please choose more than 2 or smaller than total questions");
          hasError = true;
        }
      }

      if (hasError) {
        return;
      }

      let modulesData = modules.map(module => ({
        test: "SAT",
        mmtmode: module.mmtmode,
        subject: module.selectedSubject,
        topics: module.selectedTopics.value,
        difficulty: module.difficulty,
        questionLimit: module.numberOfQuestions,
        selectedSection: module.selectedSection
      }));

      navigate("/sat/quiz", {
        state: {
          modules: modulesData
        },
      });
    } else {
      toast.error("User not logged-in");
    }
  }


  function groupBy(array, key) {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  }

  async function getMMTtotal(idx, topic) {
    let res = await axios.get(BASELINE + "sat/mmt/difficultys", { params: { topic: topic.value } });

    // Use updateModule function to update mmtdifficultys
    updateModule(idx, 'mmtdifficultys', res.data);
  }

  async function getTopics(subject, idx) {
    try {
      let mappedValues = [];

      if (modules[idx].mmtmode) {
        let selectedSubject = data.find((item) => item.topic === subject);
        mappedValues = selectedSubject ? selectedSubject.subtopics.map((subtopic) => {
          return {
            value: subtopic.id,
            label: subtopic.name
          };
        }) : [];
      } else {
        let res = await axios.get(BASELINE + "sat/topic", { params: { subject } });
        let groupedData = groupBy(res.data, 'skill_descs');
        mappedValues = Object.entries(groupedData).map(([skill_descs, data]) => {
          let difficultiesWithTotals = data.map(item => ({
            difficulty: item.difficultys,
            total: item.total
          }));
          return {
            value: skill_descs,
            label: skill_descs,
            difficulties: difficultiesWithTotals
          };
        });
      }

      updateModule(idx, 'topics', mappedValues);
      updateTotalQuestions(idx, mappedValues, modules[idx].difficulty); // Ensure topics is an array
    } catch (err) {
      setError(err.message);
      updateModule(idx, 'topics', []);
    }
  }

  async function mmtenable(idx) {
    let mappedValues;
    if (!modules[idx].mmtmode) {
      mappedValues = data.flatMap((subject) => {
        return {
          value: subject.topic,
          label: subject.topic
        }
      });

      updateModule(idx, 'subjects', mappedValues);
    } else {
      getSubjects("Math", idx);
    }
  }

  async function getSubjects(section, idx) {
    try {
      let mappedValues;

      let res = await axios.get(BASELINE + "sat/subject", { params: { section } });
      mappedValues = res.data.map((subject) => {
        return {
          value: subject.primary_class_cd,
          label: subject.primary_class_cd_descs
        }
      });

      updateModule(idx, 'subjects', mappedValues);
    } catch (err) {
      setError(err.message);
      updateModule(idx, 'subjects', []); // Set subjects to an empty array on error
    }
  }

  return (
    <section className="wrapper mt-[120px]">
      <div className="w-[90%] max-w-[1000px] 2xl:max-w-[1300px] flex justify-start items-center flex-col gap-5 pt-8 px-5 bg-white rounded-2xl">
        <h2 className="text-black text-[28px] sm:text-[40px] md:text-[48px] font-bold">
          Choose Your Assessment for SAT
        </h2>
        {modules.map((module, idx) => (
          <div key={idx} className="mb-6 p-2 rounded-xl shadow-xl md:w-[98%] w-[98%]">
            <div className="flex flex-col md:flex-row md:flex-wrap justify-center md:grid md:grid-cols-3 gap-2">

              <div className="flex flex-col gap-1 p-2 w-full lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Section</h4>
                <select
                  className="outline-none border-[1px] border-gray-300 p-1 rounded-md focus-within:border-blue-500 focus-within:border-[2px]"
                  value={module.selectedSection}
                  onChange={(e) => {
                    updateModule(idx, 'selectedSection', e.target.value);
                    getSubjects(e.target.value, idx);
                  }}
                >
                  <option>Select a section</option>
                  {sections.map((section, idx) => {
                    return <option key={'section-' + idx} value={section}>{section}</option>
                  })}
                </select>

                {module.selectedSection === "Math" && (
                  <div className="flex flex-wrap gap-2 mt-2">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        checked={module.mmtmode}
                        onChange={(e) => {
                          setModules((prev) => {
                            let newModules = [...prev];
                            newModules[idx].mmtmode = e.target.checked;
                            return newModules;
                          });
                          mmtenable(idx);
                        }}
                      />
                      <span className="ml-2">Pick MMT Math Quiz</span>
                    </label>
                    {/* Add more checkboxes as needed */}
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-1 p-2 w-full lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Select Subject</h4>
                <select
                  className="outline-none border-[1px] border-gray-300 p-1 rounded-md focus-within:border-blue-500 focus-within:border-[2px]"
                  value={module.selectedSubject}
                  onChange={(e) => {
                    updateModule(idx, 'selectedSubject', e.target.value);
                    getTopics(e.target.value, idx);
                  }}
                >
                  <option>Select a subject</option>
                  {module.subjects && module.subjects.map((subject, idx) => {
                    return <option key={'subject-' + idx} value={subject.value}>{subject.label}</option>
                  })}
                </select>
              </div>

              <div className="flex flex-col p-2 w-full lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Select Topics</h4>
                <div>
                  <Select
                    value={module.selectedTopics}
                    onChange={(selectedTopics) => {
                      updateModule(idx, 'selectedTopics', selectedTopics || []); // Ensure selectedTopics is never null or undefined
                      getMMTtotal(idx, selectedTopics);
                      updateTotalQuestions(idx, selectedTopics || [], module.difficulty); // Ensure topics is an array
                    }}
                    name="topics"
                    options={module.topics}
                    className="basic-multi-select lg:min-w-[300px] lg:max-w-[300px]"
                    classNamePrefix="select"
                  />

                </div>
              </div>

              <div className="flex flex-col gap-1 p-2 lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Select Difficulty</h4>
                <select
                  onChange={(e) => {
                    updateModule(idx, 'difficulty', e.target.value);
                    updateTotalQuestions(idx, module.selectedTopics || [], e.target.value); // Ensure topics is an array
                  }}
                  value={module.difficulty}
                  className="outline-none border-[1px] border-gray-300 p-1 rounded-md focus-within:border-blue-500 focus-within:border-[2px]"
                  aria-label="Select Difficulty"
                  disabled={!module.selectedSubject || !module.selectedTopics || module.selectedTopics.length === 0}
                >

                  <option value="">Select one</option>
                  <option value="E">Easy</option>
                  <option value="M">Medium</option>
                  <option value="H">Hard</option>
                </select>
              </div>

              <div className="flex flex-col gap-1 p-2 lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Select Number Of Questions</h4>
                <div>
                  <input
                    className="border-[1px] border-gray-300 outline-none p-1 rounded-md focus-within:border-blue-500 focus-within:border-[2px]"
                    type="number"
                    value={module.numberOfQuestions}
                    onChange={(e) => updateModule(idx, 'numberOfQuestions', e.target.value)}
                    min={2}
                  />
                  {module.mmtmode ? (
                    module.difficulty.length > 0 && (
                      <>
                        / {module.mmtdifficultys.find(d => d.difficultys === module.difficulty)?.total}
                      </>
                    )
                  ) : (
                    module.selectedTopics && module.difficulty && (
                      <> / {module.selectedTopics.difficulties.find(d => d.difficulty === module.difficulty).total} </>
                    )
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-1 p-2 lg:min-w-[300px] lg:max-w-[300px]">
                <h4 className="font-semibold">Copy Module</h4>
                <button
                  className="bg-slate-900 hover:bg-slate-800 text-white px-2 py-1 rounded-md"
                  onClick={() => copyModule(idx)}
                >
                  Copy
                </button>
              </div>

              {idx !== 0 && (
                <div className="flex flex-col gap-1 p-2 w-full lg:min-w-[300px] lg:max-w-[300px]">
                  <button
                    className="hover:bg-red-500 hover:text-white font-semibold text-black px-2 py-1 rounded-md"
                    onClick={() => removeModule(idx)}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>



          </div>))}
        <div className="flex flex-col gap-1 p-2 lg:min-w-[300px] lg:max-w-[300px]">
          <h4 className="font-semibold">Add Module</h4>
          <button onClick={addModule} className="bg-gray-500 px-4 py-2 outline-none rounded-md text-white hover:bg-gray-600">+</button>
        </div>
        <div className="w-full flex flex-row justify-center py-4">
          <motion.button whileTap={{ scale: 0.97 }} onClick={() => handleStartQuiz()} className="bg-gray-500 px-4 py-2 outline-none rounded-md text-white hover:bg-gray-600 w-full glow-outline"
          >Start Quiz</motion.button>
        </div>
      </div>
    </section>
  );
};

export default Topic;
