//Swiper Component
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import TestimonialCard from "../../Components/TestimonialCard/TestimonialCard";
import { Autoplay, Navigation } from "swiper/modules";
import { motion } from "framer-motion";
import { fadeInFromLeft } from "../../util/FadeFromLeftAnimation";

const Testinomials = () => {
  return (
    <div className="max-w-[1150px] w-10/12 mx-auto mt-14">
      <motion.h1
        {...fadeInFromLeft(0.2)}
        className="sm:text-4xl text-3xl font-archivo font-semibold  text-center my-8"
      >
        Our Client Say
      </motion.h1>
      <div className="">
        <Swiper
          spaceBetween={50}
          //   slidesPerView={"auto"}
          speed={1100}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          rewind={true}
          modules={[Autoplay, Navigation]}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            600: {
              slidesPerView: 2,
            },
            1000: {
              slidesPerView: 3,
            },
            // 1410: {
            //   slidesPerView: 4,
            // },
          }}
          className="w-full h-full py-10 "
        >
          <SwiperSlide className="text-center min-h-[400px] w-[300px] flex justify-center items-center">
            <TestimonialCard img={"Testimonial_img1.png"} name={"Junsu Lee"} role={"MMT Student"} desc={"This digital SAT/ACT web app is incredibly convenient! I’ve been using it all the time because I can study anywhere, anytime!"}/>
          </SwiperSlide>
          <SwiperSlide className="text-center min-h-[400px] w-[300px] flex justify-center items-center">
            <TestimonialCard img={"Testimonial_img2.png"} name={"Jaegeun Oh"} role={"MMT Student"} desc={"The app makes studying for the SAT and ACT so easy and accessible. I love how I can review and practice no matter where I am!"}/>
          </SwiperSlide>
          <SwiperSlide className="text-center min-h-[400px] w-[300px] flex justify-center items-center">
            <TestimonialCard img={"Testimonial_img3.png"} name={"Alex Kim"} role={"MMT Student"} desc={"I found this app extremely useful! The ability to study anytime, anywhere has really helped me stay consistent with my SAT/ACT prep."}/>
          </SwiperSlide>
          <SwiperSlide className="text-center min-h-[400px] w-[300px] flex justify-center items-center">
            <TestimonialCard img={"Testimonial_img4.png"} name={"Nathan Hu"} role={"MMT Teacher"} desc={"I found it really convenient for teaching, as the app allows students to create classmate groups, and the services provided for teachers are well-designed!"}/>
          </SwiperSlide>
          <SwiperSlide className="text-center min-h-[400px] w-[300px] flex justify-center items-center">
            <TestimonialCard img={"Testimonial_img1.png"} name={"Ryan Jocoy"} role={"MMT Teacher"} desc={"The platform makes teaching so much easier! Students can create their own classmate groups, and the tools for teachers are incredibly helpful and well-organized."}/>
          </SwiperSlide>
          <SwiperSlide className="text-center min-h-[400px] w-[300px] flex justify-center items-center">
            <TestimonialCard img={"Testimonial_img2.png"} name={"Nathan Kirsch"} role={"MMT Student"} desc={"Such a convenient tool for studying! I’ve been using it regularly since it allows me to practice for the SAT/ACT wherever I go!"}/>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="h-7 sm:hidden"></div>
    </div>
  );
};

export default Testinomials;
