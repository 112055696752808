import { Chart as ChartJS, ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const CircleGraph = ({ correctQuestions, wrongQuestions }) => {
    const data = {
        labels: ['Correct', 'Wrong'],
        datasets: [
            {
                data: [correctQuestions, wrongQuestions],
                backgroundColor: ['#36A2EB', '#FF6384'],
                hoverBackgroundColor: ['#36A2EB', '#FF6384'],
            },
        ],
    };

    const options = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = context.label || '';
                        const value = context.raw || 0;
                        const backgroundColor = context.dataset.backgroundColor[context.dataIndex];
                        return `${label}: ${value} (${backgroundColor === '#36A2EB' ? 'Blue' : 'Red'})`;
                    },
                },
            },
            legend: {
                display: true,
                position: 'bottom',
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <div className="relative" style={{ width: '200px', height: '200px' }}>
            <Doughnut data={data} options={options} />
        </div>
    );
};

export default CircleGraph;
