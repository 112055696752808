import { motion } from "framer-motion";
import { fadeInFromLeft } from "../../util/FadeFromLeftAnimation";

const Choose = () => {
  return (
    <div className=" sm:mb-10 mt-10 sm:mt-24 lg:mb-[100px] lg:mt-[150px]">
      <div className="max-w-[1150px] w-10/12 mx-auto flex xl:flex-row flex-col justify-between items-center gap-10">
        <div className="flex justify-start sm:items-start items-center flex-col gap-4">
          <motion.h2
            className="title !text-black sm:text-4xl text-3xl text-center"
          >
            Choose Your Plan
          </motion.h2>
          <motion.p
            className="text-black sm:text-left text-center mt-5"
          >
            Leveraging AI analytics, we target and refine service gaps, minimizing the effort to identify errors and enhancing comprehension of test results. Our arsenal includes 10,000+ curated practice problems, strategically designed to fortify weak points, demonstrating our commitment to excellence through technology and expertise.
          </motion.p>
        </div>
        <div className="bg-white py-12 flex-col sm:min-w-[400px]  w-full max-w-[400px] rounded-2xl flex justify-center items-center">
          <motion.button
            {...fadeInFromLeft(0.6)}
            className="w-full py-5  rounded-md mb-6 hover:bg-black hover:text-white bg-white border-2 transition-all duration-300 text-center font-archivo text-2xl font-semibold border-b-4 border-b-black"
          >
            SAT-English
          </motion.button>
          <motion.button
            {...fadeInFromLeft(0.8)}
            className="w-full py-5  rounded-md mb-6 hover:bg-black hover:text-white bg-white border-2 transition-all duration-300 text-center font-archivo text-2xl font-semibold border-b-4 border-b-black"
          >
            SAT-Math
          </motion.button>
          <motion.button
            {...fadeInFromLeft(1)}
            className="w-full py-5  rounded-md mb-6 hover:bg-black hover:text-white bg-white border-2 transition-all duration-300 text-center font-archivo text-2xl font-semibold border-b-4 border-b-black"
          >
            SAT-ALL
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default Choose;
