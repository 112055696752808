import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { BASELINE, AFTER_LOGIN } from '../../util';
import { toast } from "react-hot-toast";
import { UserContext } from '../../context/UserContext';
import Dashboard from "./Index";
const Pdashboard = () => {
  const { logoutUser, user } = useContext(UserContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage menu visibility
  const [section, setSection] = useState(0); // State for managing sections
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [isModalOpen2, setIsModalOpen2] = useState(false); // State to manage modal visibility

  const [studentId, setStudentId] = useState(''); // State to track student ID input
  const [childData, setChildData] = useState([]); // State to manage the child data
  const [password, setPassword] = useState('');

  const [clicksid, setclicksid] = useState(''); // State to track student ID input


  useEffect(() => {
    // Check if user is defined and user.child is available
    if (user && user.child) {
      try {
        // Parse the child data (assuming it's stored as a JSON string)
        const parsedChildData = JSON.parse(user.child);

        // Set the parsed child data to the state
        setChildData(parsedChildData);
      } catch (error) {
        console.error('Error parsing child data:', error);
      }
    }
  }, [user]); // This useEffect runs whenever the 'user' changes

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu on mobile
  };

  function logout() {
    logoutUser();
    axios.post(BASELINE + 'user/logout', {})
      .then(function (response) {
        alert(response.data.message);
        window.location.replace(AFTER_LOGIN);
      })
      .catch(function (error) {
        alert(error);
      });
  }
  const openModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const openModal2 = () => {
    setIsModalOpen2(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setStudentId(''); // Reset student ID input
  };

  const closeModal2 = () => {
    setIsModalOpen2(false); // Close the modal
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleAddStudent = async () => {
    try {
      if (!studentId) {
        toast.error('Please enter a valid Student ID');
        return;
      }

      console.log('Checking Student ID:', studentId);

      // Make API call to check if the user exists
      const response = await axios.get(BASELINE + `check/user/id`, {
        params: { u_id: studentId }
      });

      // Assuming the response contains the user's data
      const { u_id, first_name, last_name } = response.data;

      // Check if the user already has the child
      const childData = user.child ? JSON.parse(user.child) : []; // Assuming child data is stored as a JSON string
      const isDuplicate = childData.some(child => child.u_id === u_id);

      if (isDuplicate) {
        toast.error('Student is already added');
        return;
      }

      // Add the new student to the child array
      const newChild = {
        u_id: u_id,
        name: `${first_name} ${last_name}`,
        status: 'pending'
      };

      setChildData(prevChildData => [...prevChildData, newChild]);

      // Send updated child data to the API
      await axios.post(BASELINE + `update/child`, {
        u_id: user.u_id, // Assuming the logged-in user's ID is available
        child: JSON.stringify([...childData, newChild]) // Send updated child array
      });

      // Success message
      toast.success('Student added successfully!');

      // Close the modal
      closeModal();
    } catch (error) {
      // Handle errors (404, 500, etc.)
      if (error.response) {
        if (error.response.status === 404) {
          toast.error('Student not found');
        } else if (error.response.status === 500) {
          toast.error('Server error, please try again later');
        } else {
          toast.error('An error occurred');
        }
      } else {
        toast.error('Network error, please try again');
      }
    }
  };

  const handleView = (u_id) => {
    setSection(1);
    setclicksid(u_id);
  };

  const handleDrop = async (u_id) => {
    // Ask for confirmation
    const confirmDrop = window.confirm("Are you sure you want to drop this student?");

    // If the user confirms, proceed with the drop action
    if (confirmDrop) {
      try {
        // Filter out the student with the provided u_id from the childData state
        const updatedChildData = childData.filter(child => child.u_id !== u_id);

        // Update the childData state
        setChildData(updatedChildData);

        // Send the updated childData to the backend
        await axios.post(BASELINE + `update/child`, {
          u_id: user.u_id, // Assuming the logged-in user's ID is available
          child: JSON.stringify(updatedChildData) // Send updated child array as a JSON string
        });

        // Show success message
        toast.success('Student dropped successfully!');
      } catch (error) {
        // Handle any errors
        console.error('Error dropping student:', error);
        toast.error('Failed to drop the student, please try again.');
      }
    } else {
      // If user cancels the action
      toast.error('Drop action cancelled');
    }
  };

  const handleSubmit = () => {
    if (password.length < 6) {
      alert("Password must be at least 6 characters long.");
      return;
    }

    axios.post(BASELINE + 'user/setpassword', [user.u_id, password])
      .then(function (response) {
        setPassword('');
        alert("Password Reset Successfully!");
      })
      .catch(function (error) {
        alert("Something Went Wrong.");
      });

    setIsModalOpen2(false);
  };

  return (
    <>
      {/* Wrapper to ensure the sidebar (top bar on mobile) and content stay in the same row */}
      <div className="flex flex-col lg:flex-row h-screen">

        {/* Top Bar for Mobile and Sidebar for Desktop */}
        <div className="w-full lg:w-64 bg-white shadow-md">
          <div className="p-4 text-xl font-bold border-b flex justify-between lg:justify-start">
            Parents Dashboard
            <button onClick={toggleMenu} className="lg:hidden">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>

          {/* Links (visible on mobile if menu is open, always visible on desktop) */}
          <ul className={`flex flex-col lg:block lg:flex-col p-2 ${isMenuOpen ? 'block' : 'hidden'} lg:block`}>
            <li className="p-2 font-bold text-xl md:text-xl">My Home</li>
            <li>
              <button
                onClick={() => setSection(0)}
                className="w-full text-left block p-4 hover:bg-gray-100"
              >
                My Child
              </button>
            </li>
            <li>
              <button
                onClick={() => openModal2()}
                className="w-full text-left block p-4 hover:bg-gray-100"
              >
                Change Password
              </button>
            </li>
            <li>
              <button
                onClick={() => logout()}
                className="w-full text-red-900 font-bold text-left p-4 hover:bg-gray-100"
              >
                Logout
              </button>
            </li>
          </ul>
        </div>

        {/* Main Content */}
        <div className="flex-1 p-4">
          {section === 0 && (
            <div>
              {/* Breadcrumb Navigation */}
              <div className="bg-white pl-6 pt-6 text-gray-500 text-sm">
                <span className="hover:underline cursor-pointer">My Home</span> &gt;
                <span className="font-semibold text-black">My Child</span>
              </div>

              {/* Title and Add Student Button */}
              <div className="pl-2 pr-2 mt-4">
                <h1 className="text-2xl font-bold">My Child Dashboard</h1>

                {/* Add Student Button */}
                <div className="mt-4 lg:mt-0 flex lg:justify-end">
                  <button
                    className="bg-slate-700 text-white font-bold py-2 px-4 w-full lg:w-auto rounded hover:bg-slate-800 block mx-auto lg:mx-0"
                    onClick={() => openModal()}
                  >
                    Add Student
                  </button>
                </div>

                {/* Table */}
                <div className="mt-6 overflow-x-auto">
                  <table className="min-w-full table-auto">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="py-2 px-4 text-left">UID</th>
                        <th className="py-2 px-4 text-left">Name</th>
                        <th className="py-2 px-4 text-left">Status</th>
                        <th className="py-2 px-4 text-left">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {childData.map((child, index) => (
                        <tr
                          key={index}
                          className={`
        ${child.status === 'pending' ? 'bg-orange-100' : ''} 
        ${child.status === 'approved' ? 'bg-green-100' : ''} 
        ${child.status === 'denied' ? 'bg-red-100' : ''}
      `}
                        >
                          <td className="py-2 px-4">{child.u_id}</td>
                          <td className="py-2 px-4">{child.name}</td>
                          <td
                            className={`
          py-2 px-4 font-bold 
          ${child.status === 'pending' ? 'text-orange-500' : ''} 
          ${child.status === 'approved' ? 'text-green-500' : ''} 
          ${child.status === 'denied' ? 'text-red-500' : ''}
        `}
                          >
                            {child.status}
                          </td>
                          <td className="py-2 px-4">
                            {child.status === 'pending' || child.status === 'denied' ? (
                              <button
                                onClick={() => handleDrop(child.u_id)} // Call handleDrop for pending/denied status
                                className={`
              ${child.status === 'pending' ? 'bg-orange-500' : ''} 
              ${child.status === 'denied' ? 'bg-red-500' : ''} 
              text-white py-1 px-3 rounded 
              ${child.status === 'pending' ? 'hover:bg-orange-600' : ''} 
              ${child.status === 'denied' ? 'hover:bg-red-600' : ''}
            `}
                              >
                                Drop
                              </button>
                            ) : (
                              <button
                                onClick={() => handleView(child.u_id)} // Call handleView for approved status
                                className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600"
                              >
                                View
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          )}
          {section === 1 && (
            <div>
              <Dashboard s_uid={clicksid} smallmode={true} parents={true} />
            </div>
          )}
        </div>
      </div>

      {/* Modal for Adding Student */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md p-6 rounded-lg shadow-lg relative">
            <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700" onClick={closeModal}>
              &times;
            </button>
            <h2 className="text-2xl font-bold mb-4">Add Student</h2>
            <input
              type="text"
              placeholder="Enter Student ID"
              value={studentId}
              onChange={(e) => setStudentId(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <div className="flex justify-end">
              <button
                onClick={() => handleAddStudent()}
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600"
              >
                Add Student
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Adding Student */}
      {isModalOpen2 && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md p-6 rounded-lg shadow-lg relative">
            <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700" onClick={closeModal2}>
              &times;
            </button>
            <h2 className="text-2xl font-bold mb-4">Change Password</h2>
            <input
              type="password"
              placeholder="Enter New Password"
              value={password}
              onChange={handlePasswordChange}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <div className="flex justify-end">
              <button
                onClick={() => handleSubmit()}
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600"
              >
                Change
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Pdashboard;
