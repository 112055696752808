import React, { useState, useEffect, useRef } from "react";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { ImSpellCheck } from "react-icons/im";
import { motion } from "framer-motion";
import axios from "axios";
import { BASELINE } from "../../util/index";
import 'katex/dist/katex.min.css';
import Latex from "react-latex-next";
import { IoExpand } from "react-icons/io5";



const DevQuestion = ({ ans, sat, format, q_id, id, handleAnswerValueChange, handleCrossOutOptions, handleSelectOption, handleBookMark, currentQuestion }) => {
    const boxItemLeftRef = useRef();
    const [currentQuestionss, setCurrentQuestion] = useState({ stem: '' });

    const [showCrossOutMenu, setShowCrossOutMenu] = useState(false);
    const [crossedOutOptions, setCrossedOutOptions] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);

    const [isEditing, setIsEditing] = useState(false);
    const [editedStimulus, setEditedStimulus] = useState(currentQuestion.stimulus);
    const [editedStem, setEditedStem] = useState(currentQuestion.stem);
    const [editrationale, seteditrationale] = useState(currentQuestion.rationale);

    const [options, setOptions] = useState(currentQuestion.options || []);
    const [isKatexRendered, setIsKatexRendered] = useState(false);
    const [anss, setAns] = useState(ans || "");

    const handleAnsChange = (event) => {
        setAns(event.target.value);
    };

    const handleInputChange = (event) => {
        setEditedStem(event.target.value);
    };

    
    const handleInputChangesetEditedStimulus = (event) => {
        setEditedStimulus(event.target.value);
    };

    useEffect(() => {

        const script2 = document.createElement('script');
        script2.src = "https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js";
        script2.integrity = "sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05";
        script2.crossOrigin = "anonymous";
        script2.defer = true;

        // Handle script load
        script2.onload = () => {
            if (boxItemLeftRef.current) {
                if (window.renderMathInElement) {
                    const boxItemLeft = document.getElementById("box-item-left");
                    window.renderMathInElement(boxItemLeft, {
                        delimiters: [
                            { left: '$$', right: '$$', display: false },
                            { left: "$", right: "$", display: false },
                            { left: '\\(', right: '\\)', display: false },
                            { left: '\\[', right: '\\]', display: true },
                            { left: '\\begin{array}', right: '\\end{array}', display: true }
                        ],
                        throwOnError: false,
                    });
                }
                setIsKatexRendered(true);
            }
        };
        document.body.appendChild(script2);

        // Cleanup: remove the script when component unmounts
        return () => {
            document.body.removeChild(script2);
        };
    }, [currentQuestion, isEditing]);

    const handleEdit = () => {
        setIsEditing(!isEditing);
    };

    async function handleSave() {
        currentQuestion.stimulus = editedStimulus;
        currentQuestion.stem = editedStem;
        currentQuestion.rationale = editrationale;
        setIsEditing(false);

        var data = {
            stimulus: currentQuestion.stimulus,
            stem: currentQuestion.stem,
            a: currentQuestion.a,
            b: currentQuestion.b,
            c: currentQuestion.c,
            d: currentQuestion.d,
            questionId: q_id,
            rationale: currentQuestion.rationale,
            correct_answer: anss
        }

        try {
            let res = await axios.put(BASELINE + "questions/psatupdate", [data]);
            window.location.reload();
        } catch (err) {
        //    console.log(err);
        }
    };

    async function handleOptionChange(index, newValue) {
        if (index === 0) {
            currentQuestion.a = newValue;
        } else if (index === 1) {
            currentQuestion.b = newValue;
        } else if (index === 2) {
            currentQuestion.c = newValue;
        } else if (index === 3) {
            currentQuestion.d = newValue;
        }

        setOptions(options.map((option, i) => i === index ? newValue : option));

        var data = {
            stimulus: currentQuestion.stimulus,
            stem: currentQuestion.stem,
            a: currentQuestion.a,
            b: currentQuestion.b,
            c: currentQuestion.c,
            d: currentQuestion.d,
            questionId: q_id
        }

        try {
            let res = await axios.put(BASELINE + "questions/psatupdate", [data]);
        } catch (err) {
        //    console.log(err);
        }
    };

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            {sat == "Math" && (<>
                <div className="max-h-[450px] mt-[-100px] min-w-[900px] max-w-[35rem] z-[10] overflow-auto">
                    <div className="flex flex-row justify-between bg-gray-100">
                        <div className="flex flex-row gap-2">
                            <div className=" flex flex-row items-center">
                                <p className="py-2 px-3 bg-black text-white">{currentQuestion.index + 1}</p>
                            </div>
                            <div className="flex flex-row items-center gap-1">
                                <motion.div whileTap={{ scale: 0.97 }} onClick={() => handleBookMark(currentQuestion.questionIds)} className="m-1 cursor-pointer">

                                    {currentQuestion.bookMarked ? <BsBookmarkFill color="red" size="1.2rem" /> : <BsBookmark size="1.2rem" />}
                                </motion.div>
                                <div>
                                    <p>Mark For Review</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center">
                            <motion.div whileTap={{ scale: 0.98 }} className={`p-[5px] border-black border-[1px] rounded-md cursor-pointer ${showCrossOutMenu && "bg-blue-700 text-white"}`} onClick={() => setShowCrossOutMenu(pre => !pre)}>
                                <ImSpellCheck />
                            </motion.div>
                        </div>
                    </div>

                    <div id="box-item-left" >
                        <div className=" max-h-[250px] overflow-auto">

                        {
                        currentQuestion && (
                            <div className="my-2 min-w-[100px] max-w-[600px] max-h-[480px] overflow-y-auto">
                                {
                                    currentQuestion.stimulus && (
                                        isEditing ? (
                                            <>
                                                <textarea
                                                    className="ml-[20px] min-w-[500px] min-h-[400px] editing"
                                                    value={editedStimulus}
                                                    onChange={handleInputChangesetEditedStimulus}
                                                />
                                                <button onClick={handleSave} className="bg-gray-700 text-white p-1 rounded">
                                                    Save
                                                </button>
                                            </>
                                        ) : (
                                            <div className="ml-[20px]" dangerouslySetInnerHTML={{ __html: currentQuestion.stimulus }} />
                                        )
                                    )
                                }

                                {
                                    currentQuestion.stem && (
                                        isEditing ? (
                                            <>
                                                <textarea
                                                    className="ml-[20px] min-w-[500px] min-h-[400px] editing"
                                                    value={editedStem}
                                                    onChange={handleInputChange}
                                                />
                                                <button onClick={handleSave} className="bg-gray-700 text-white p-1 rounded">
                                                    Save
                                                </button>
                                            </>
                                        ) : (
                                            <div className="ml-[20px]" dangerouslySetInnerHTML={{ __html: currentQuestion.stem }} />
                                        )
                                    )
                                }
                            </div>
                        )}

                        </div>
                        <div>
                            {
                                isEditing ? (<>
                                    <input
                                        className="mt-[10px] border-[1px] border-gray-300 rounded"
                                        value={anss}
                                        onChange={handleAnsChange}
                                    />
                                    <button onClick={handleSave} className="bg-gray-700 text-white p-1 rounded">
                                        Save
                                    </button>
                                </>) : (
                                    <div className="mt-[10px]">Ans: {Array.isArray(ans) ? ans.join(', ') : anss}</div>
                                )
                            }
                            <div className="my-2" ref={boxItemLeftRef}>
                                {
                                    isEditing ? (<>
                                        <Option2 handleOptionChange={handleOptionChange} handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={0} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.a} isEditing={isEditing} />
                                        <Option2 handleOptionChange={handleOptionChange} handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={1} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.b} isEditing={isEditing} />
                                        <Option2 handleOptionChange={handleOptionChange} handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={2} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.c} isEditing={isEditing} />
                                        <Option2 handleOptionChange={handleOptionChange} handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={3} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.d} isEditing={isEditing} />
                                    </>) : (
                                        currentQuestion.a != null ?
                                            <>
                                                <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={0} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.a} />
                                                <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={1} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.b} />
                                                <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={2} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.c} />
                                                <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={3} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.d} />
                                            </> :
                                            <input className="w-[90%] border-[1px] py-1 px-2 border-gray-300 outline-none rounded-md" type="text" value={currentQuestion.selectedOption || ""} onChange={(e) => handleAnswerValueChange(currentQuestion.item_id, e.target.value)} />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div >

            </>)}

            {sat == "English" ? (
                <div className=" mt-[-20px] mr-[50px] ml-[50px]">

                    <div className="flex flex-row justify-between bg-gray-100">
                        <div className="flex flex-row gap-2">
                            <div className=" flex flex-row items-center">
                                <p className="py-2 px-3 bg-black text-white">{currentQuestion.index + 1}</p>
                            </div>

                        </div>
                        <div className="flex flex-row items-center">
                            <motion.div whileTap={{ scale: 0.98 }} className={`p-[5px] border-black border-[1px] rounded-md cursor-pointer ${showCrossOutMenu && "bg-blue-700 text-white"}`} onClick={() => setShowCrossOutMenu(pre => !pre)}>
                                <ImSpellCheck />
                            </motion.div>
                        </div>
                    </div>

                    {
                        isEditing ? (<>
                            <input
                                className="mt-[10px] border-[1px] border-gray-300 rounded"
                                value={anss}
                                onChange={handleAnsChange}
                            />
                            <button onClick={handleSave} className="bg-gray-700 text-white p-1 rounded">
                                Save
                            </button>
                        </>) : (
                            <div className="mt-[10px]">Ans: {Array.isArray(ans) ? ans.join(', ') : anss}</div>
                        )
                    }

                    {
                        currentQuestion && (
                            <div className="my-2 min-w-[100px] max-w-[600px] max-h-[480px] overflow-y-auto">
                                {
                                    currentQuestion.stimulus && (
                                        isEditing ? (
                                            <>
                                                <textarea
                                                    className="ml-[20px] min-w-[500px] min-h-[400px] editing"
                                                    value={editedStimulus}
                                                    onChange={handleInputChangesetEditedStimulus}
                                                />
                                                <button onClick={handleSave} className="bg-gray-700 text-white p-1 rounded">
                                                    Save
                                                </button>
                                            </>
                                        ) : (
                                            <div className="ml-[20px]" dangerouslySetInnerHTML={{ __html: currentQuestion.stimulus }} />
                                        )
                                    )
                                }

                                {
                                    currentQuestion.stem && (
                                        isEditing ? (
                                            <>
                                                <textarea
                                                    className="ml-[20px] min-w-[500px] min-h-[400px] editing"
                                                    value={editedStem}
                                                    onChange={handleInputChange}
                                                />
                                                <button onClick={handleSave} className="bg-gray-700 text-white p-1 rounded">
                                                    Save
                                                </button>
                                            </>
                                        ) : (
                                            <div className="ml-[20px]" dangerouslySetInnerHTML={{ __html: currentQuestion.stem }} />
                                        )
                                    )
                                }
                                <div className="my-2">
                                    {
                                        isEditing ? (<>
                                            <Option2 handleOptionChange={handleOptionChange} handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={0} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.a} isEditing={isEditing} />
                                            <Option2 handleOptionChange={handleOptionChange} handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={1} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.b} isEditing={isEditing} />
                                            <Option2 handleOptionChange={handleOptionChange} handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={2} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.c} isEditing={isEditing} />
                                            <Option2 handleOptionChange={handleOptionChange} handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={3} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.d} isEditing={isEditing} />
                                        </>) : (<>
                                            <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={0} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.a} />
                                            <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={1} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.b} />
                                            <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={2} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.c} />
                                            <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={3} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.d} />
                                        </>)
                                    }
                                </div>
                            </div>
                        )}

                </div>
            ) : null
            }

            <div className="ml-auto fixed right-0 top-[100px] bg-white z-[1000]">
                <button onClick={handleEdit} className="mt-[10px] bg-gray-700 p-2 text-white rounded">Edit</button>
            </div>


            <div className="ml-auto fixed right-0 top-[160px] bg-white z-[1000]">
                <button className="mt-[10px] bg-gray-700 p-2 text-white rounded" onClick={handleSave}>Save to the DB</button>
            </div>

        </>
    )
}
export default DevQuestion;


const Option = ({ handleCrossOutOptions, currentQuestion, idx, handleSelectOption, showCrossOutMenu, crossedOutOptions, data }) => {
    return (
        <div className="flex flex-row gap-1">
            <motion.div whileTap={{ scale: 0.98 }} className={`flex flex-row w-full ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-[2px]" : "border-[1px]"} rounded-md ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-blue-700 bg-blue-100" : "border-black"} gap-2 p-1 m-3 cursor-pointer relative ${currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) && "before:absolute before:bg-black before:w-[103%] before:h-[2px] before:top-[50%] before:-left-[6px]"}`} onClick={() => handleSelectOption(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>
                <div className="flex flex-row items-center">
                    <p className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
                        {getOptionLetter(idx)}
                    </p>
                </div>
                <div className="flex flex-row items-center p-[2px]" dangerouslySetInnerHTML={{ __html: data }}>
                </div>
            </motion.div>
            {showCrossOutMenu &&
                <motion.div className="flex flex-row items-center" whileTap={{ scale: 0.97 }}>
                    <div className="relative">
                        {currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) ?
                            (<p className="cursor-pointer underline" onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>Undo</p>) :

                            <>
                                <p onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())} className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full cursor-pointer">
                                    {getOptionLetter(idx)}
                                </p>
                                <div className="absolute w-6 h-[1.5px] bg-black top-3 cursor-pointer" onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>
                                </div>
                            </>

                        }


                    </div>

                </motion.div>
            }
        </div>
    )
}

const Option2 = ({ handleOptionChange, handleCrossOutOptions, currentQuestion, idx, handleSelectOption, showCrossOutMenu, crossedOutOptions, data, isEditing }) => {
    const [editedData, setEditedData] = useState(data);

    const handleChange = (e) => {
        setEditedData(e.target.value);
        handleOptionChange(idx, editedData);
    };

    const handleSave = () => {
        handleOptionChange(idx, editedData);
    };

    return (
        <div className="flex flex-row gap-1">
            <motion.div whileTap={{ scale: 0.98 }} className={`flex flex-row w-full ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-[2px]" : "border-[1px]"} rounded-md ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-blue-700 bg-blue-100" : "border-black"} gap-2 p-1 m-3 cursor-pointer relative ${currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) && "before:absolute before:bg-black before:w-[103%] before:h-[2px] before:top-[50%] before:-left-[6px]"}`} onClick={() => handleSelectOption(currentQuestion.item_id, getOptionLetter(idx).toLowerCase())}>
                <div className="flex flex-row items-center">
                    <p className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
                        {getOptionLetter(idx)}
                    </p>
                </div>
                {isEditing ? (<>
                    <textarea
                        rows={5}
                        value={editedData}
                        onChange={handleChange}
                        className="border-1 border-black min-w-[450px]"
                    />
                    <button onClick={handleSave} className="bg-gray-700 text-white p-1 rounded">Save</button>
                </>) : (
                    <div className="flex flex-row items-center p-[2px]" dangerouslySetInnerHTML={{ __html: data }} />
                )}
            </motion.div>
            {showCrossOutMenu &&
                <motion.div className="flex flex-row items-center" whileTap={{ scale: 0.97 }}>
                    <div className="relative">
                        {currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) ?
                            (<p className="cursor-pointer underline" onClick={() => handleCrossOutOptions(currentQuestion.item_id, getOptionLetter(idx).toLowerCase())}>Undo</p>) :

                            <>
                                <p onClick={() => handleCrossOutOptions(currentQuestion.item_id, getOptionLetter(idx).toLowerCase())} className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full cursor-pointer">
                                    {getOptionLetter(idx)}
                                </p>
                                <div className="absolute w-6 h-[1.5px] bg-black top-3 cursor-pointer" onClick={() => handleCrossOutOptions(currentQuestion.item_id, getOptionLetter(idx).toLowerCase())}>
                                </div>
                            </>
                        }
                    </div>
                </motion.div>
            }
        </div>
    )
}

function getOptionLetter(idx) {
    switch (idx) {
        case 0:
            return 'A'
            break;
        case 1:
            return 'B'
            break;
        case 2:
            return 'C'
            break;
        case 3:
            return 'D'
            break;
        default:
            return "A"
    }
}